import { actionMatches, appendKey } from '../utils/actionUtil';
import { Actions as UIActions } from '../actions/ui';
import { storeAPIerror } from '../utils/apiErrorFeedback';
import { Actions as AppActions } from '../actions/app';
import { LOCALSTORAGE_KEY } from '../constant';

const uiMiddleware = (store) => (next) => (action) => {
  const { type, key } = action;
  const matches = actionMatches(type);

  if (action.type === AppActions.INIT) {
    const alerts = window.localStorage.getItem(LOCALSTORAGE_KEY.alert);
    if (alerts) {
      store.dispatch({
        type: UIActions.UPDATE_ALERTS,
        alerts: JSON.parse(alerts),
      });
    }
  }

  if (!matches) return next(action);

  const [, requestPrefix, requestState] = matches;

  if (requestState === 'LOADING') {
    store.dispatch({
      type: UIActions.START_ACTION,
      name: appendKey(requestPrefix, key),
    });
  } else {
    store.dispatch({
      type: UIActions.STOP_ACTION,
      name: appendKey(requestPrefix, key),
    });
  }

  if (requestState === 'ERROR') {
    store.dispatch({
      type: UIActions.ERROR_COME,
      name: requestPrefix,
      error: storeAPIerror(action),
    });
  }

  next(action);
};

export default uiMiddleware;
