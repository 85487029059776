import { SensorType } from '@energybox/react-ui-library/dist/types';

export enum Actions {
  GET_SPACES_BY_SITE_ID_SUCCESS = '@spaces/GET_SPACES_BY_SITE_ID_SUCCESS',
  GET_SPACES_BY_SITE_ID_ERROR = '@spaces/GET_SPACES_BY_SITE_ID_ERROR',
  GET_SPACES_BY_SITE_ID_LOADING = '@spaces/GET_SPACES_BY_SITE_ID_LOADING'
}

export type GetSpacesParams = {
  ids?: string[] | number[];
  limit?: number;
  sensorTypes?: SensorType[];
  siteIds?: string[] | number[];
  skip?: number;
  withPath?: boolean;
  siteId?: number;
};

const createQueryString = (params: GetSpacesParams = {}) => {
  return Object.keys(params)
    .map(
      (key) =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const getSpacesBySiteId = (
  siteId: number | string,
  params: GetSpacesParams = {}
) => ({
  type: 'API_GET',
  path: `/api/v1/spaces?${createQueryString({
    ...params,
    siteIds: [siteId]
  } as GetSpacesParams)}`,
  success: { type: Actions.GET_SPACES_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_SPACES_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_SPACES_BY_SITE_ID_LOADING, siteId }
});
