export enum Routes {
  BASE = '/',
  TEMP_CHECK = '/temperature-check',
  SITE_SWITCH = '/site-switch',

  LOGIN = '/login',
  LOGOUT = '/logout',
  NEW_PASSWORD = '/new-password',
  FORGOT_PASSWORD = '/forgot-password'
}
