import { Sensor } from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSensorsBySiteId } from '../actions/sensors';
import { ApplicationState } from '../reducers';

const useGetSensorsBySiteId = (siteId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getSensorsBySiteId(siteId));
    }
  }, [dispatch, siteId]);

  const siteSensors = useSelector<ApplicationState, Sensor[] | undefined>(
    ({ sensors }) => {
      if (!siteId) return undefined;
      return sensors.sensorsBySiteId[siteId];
    },
    equals
  );

  return siteSensors;
};

export default useGetSensorsBySiteId;
