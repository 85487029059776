import {
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import translate from '../../../translations';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './ForgotPasswordSuccessModal.module.css';

interface Props {
  onClose: () => void;
}

const ForgotPasswordSuccessModal: React.FC<Props> = (props) => {
  const { onClose } = props;

  return (
    <Modal containerClassName={styles.root}>
      <ModalCloseButton onClose={onClose} />

      <ModalTitle />
      <ModalContent>
        <span className={styles.message}>
          {translate.forgotPassword.emailSent}
        </span>
      </ModalContent>
    </Modal>
  );
};

export default ForgotPasswordSuccessModal;
