import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { actionMatches, appendKey } from '../utils/actionUtil';

export const useLoading = (
  action: string,
  key?: string
): { isLoading: boolean } => {
  const matches = actionMatches(action);

  const uiActionLoadingList = useSelector<ApplicationState, string[]>(
    ({ ui }: ApplicationState) => {
      return ui.action.loading;
    }
  );

  const loadingString = appendKey(matches?.[1] || '', key);
  const isLoading = !!matches && !!uiActionLoadingList.includes(loadingString);

  return {
    isLoading,
  };
};
