export const TEMPERATURE_CHECK = 'Temp Check';
export const TEMPERATURE_CHECK_GO_BACK = 'Temp Check - Go Back';
export const SHOW_TOAST = 'Toast Show';
export const CLOSE_TOAST = 'Toast Close';
export const DISMISS_NOTIFICATION = 'Notification Dismiss';
export const LOGIN = 'Login';
export const LOGIN_MANUAL = 'Login Manual';
export const LOGIN_AUTO = 'Login Auto';
export const LOGIN_FAILED = 'Login Failure';
export const LOGOUT = 'Logout';
export const LOGOUT_MANUAL = 'Logout Manual';
export const LOGOUT_AUTO = 'Logout Auto';
export const TOKEN_REFRESH = 'Token Refresh';
export const TEMPERATURE_CHECK_SET_PAGE = 'Temp Check - Set Page';
export const HOME_PAGE_SET_PAGE = 'Home Page - Set Page';
export const TEMP_CHECK_LOGIN = 'Temperature Check Login';
export const TEMP_CHECK_LOGIN_FAILED = 'Temperature Check Login Failure';
export const FINISH_TEMP_CHECK = 'Finish Temperature Check'; // manually
export const AUTO_LOGOUT_TEMP_CHECK = 'Auto Logout Temperature Check'; // auto
export const LOGOUT_TEMP_CHECK = 'Logout Temperature Check'; // both manually and auto
export const BROWSER_REFRESH = 'Browser Refresh';
export const OPEN_COMMENTS_HISTORY = 'Open Comments History';
export const ADD_COMMENT = 'Add Comment';
export const ADD_COMMENT_RESPONSE = 'Add Comment Response';
export const INCIDENT_CARDS_APPEAR = 'Incident Cards Appeared';
