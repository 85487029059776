import { CurrentUser, Site } from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import pathOr from 'ramda/src/pathOr';

export const useCurrentSite = () => {
  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => {
      return app.currentUser;
    }
  );

  const kioskSiteId = useMemo(() => {
    return currentUser?.kiosk?.preferredSiteId;
  }, [currentUser]);

  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    if (!isDefined(kioskSiteId)) return undefined;
    return pathOr(undefined, [kioskSiteId], sites.sitesById);
  });

  return site;
};
