const translate = {
  forgotPassword: {
    emailSent: 'Reset password link has been sent. \nPlease check your email.',
  },
  signIn: {
    userSelection: 'User Selection',
    toContinue: 'Sign in to continue',
  },
  button: {
    next: 'Next',
    add: 'Add',
    cancel: 'Cancel',
    tempCheckDone: 'Check Done',
    tempCheck: 'Temp Check',
    commentHistory: 'Comment History',
    ok: 'OK',
  },
  commment: {
    newHeader: 'Add Comment',
  },
  tempCheck: {
    alert: {
      incomplete: 'Incomplete Temp Check Task',
      incompleteDetail:
        'Temp Check has not been performed for all equipment. Please go through the list and complete the check.',
      incompleteAlert: 'Temp Check is incomplete',
    },
  },
};

export default translate;
