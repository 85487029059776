import {
  CommentType,
  NewCommentFields,
} from '@energybox/react-ui-library/dist/types';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Actions, createComments } from '../actions/comments';
import { updateTempCheckStatus } from '../actions/reports';
import { ApplicationState } from '../reducers';
import { TempCheckEquipment } from '../reducers/reports';
import { ApiError } from '../utils/apiErrorFeedback';
import useAppLocale from './useAppLocale';
import { useCurrentSite } from './useCurrentSite';
import { useError } from './useError';
import { useLoading } from './useLoading';

const DEFAULT_COMMENT_TYPE: CommentType = CommentType.GENERAL;

const getDefaultComment = (commentType?: CommentType) => {
  switch (commentType) {
    case CommentType.DAILY_CHECK:
      return 'Checked';
    default:
      return '';
  }
};

export const useNewComment = (props: {
  resourceId: number;
  valueType: NewCommentFields['valueType'];
  value: NewCommentFields['value'];
  timestamp: number;
  uuid: string;
  commentType?: CommentType;
}): {
  fields: NewCommentFields;
  onChangeField: (
    field: keyof NewCommentFields,
    value: NewCommentFields[keyof NewCommentFields]
  ) => void;
  create: (callback?: (resp) => void) => void;
  isLoading: boolean;
  error: ApiError | null;
  clear: () => void;
} => {
  const { resourceId, valueType, value, timestamp, uuid, commentType } = props;

  const dispatch = useDispatch();
  useCurrentSite();
  useAppLocale();

  const { isLoading } = useLoading(Actions.POST_COMMENT_LOADING, uuid);
  const { error, clearError } = useError(Actions.POST_COMMENT_LOADING);

  const [fields, setFields] = useState<NewCommentFields>({
    to: new Date(timestamp * 1000).toISOString(),
    from: new Date(timestamp * 1000).toISOString(),
    value: value,
    valueType: valueType,
    commentType: commentType ?? DEFAULT_COMMENT_TYPE,
    comment: getDefaultComment(commentType),
    resourceId,
  });

  const clear = () => {
    clearError();
    setFields({
      ...fields,
      comment: getDefaultComment(commentType),
      commentType: DEFAULT_COMMENT_TYPE,
    });
  };

  useEffect(() => {
    return () => clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeField = (
    field: keyof NewCommentFields,
    value: NewCommentFields[keyof NewCommentFields]
  ) => {
    setFields({ ...fields, [field]: value });
  };

  const create = useCallback(
    (callback?: (resp) => void) => {
      clearError();
      dispatch(
        createComments(
          {
            ...fields,
            from: new Date(fields.from).toISOString(),
            to: new Date(fields.to).toISOString(),
          },
          uuid,
          callback
        )
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, fields]
  );

  return {
    fields,
    onChangeField,
    create,
    isLoading,
    error: error,
    clear,
  };
};

export const useTempCheck = (
  resourceId: number,
  valueType: NewCommentFields['valueType'],
  value: NewCommentFields['value'],
  timestamp: number,
  uuid: string
): {
  isDone: boolean;
  check: () => void;
  isLoading: boolean;
} => {
  const dispatch = useDispatch();
  const { create, isLoading } = useNewComment({
    resourceId,
    valueType,
    value,
    timestamp,
    uuid,
    commentType: CommentType.DAILY_CHECK,
  });

  const tempCheckEquipList = useSelector<
    ApplicationState,
    TempCheckEquipment[]
  >(({ reports }) => reports.tempCheckEquipmentList);

  const isDone = !!tempCheckEquipList.find((e) => e.uuid === uuid)
    ?.doneTempCheck;

  const tempCheck = useCallback(
    () =>
      create((resp: any) => {
        if (resp.ok) dispatch(updateTempCheckStatus(uuid, true));
      }),
    [uuid]
  );

  return {
    isDone,
    check: tempCheck,
    isLoading,
  };
};
