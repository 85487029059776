import {
  ResourceType,
  Sensor,
  SensorType
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
  values
} from '@energybox/react-ui-library/dist/utils';
import { isAfter } from 'date-fns';
import * as R from 'ramda';
import { Actions } from '../actions/sensors';
import { Actions as StreamActions } from '../actions/streamApi';
import { equipmentsFromApiResponse } from './equipment';
import { spacesFromApiResponse } from './spaces';

export interface Sensors {
  sensorsBySiteId: SensorsBySiteId;
  sensorsById: SensorsById;
  sensorReadingsById: SensorReadingsById;
  isSensorsLoading: boolean;
}

export type SensorsById = {
  [id: string]: Sensor;
};

export type SensorsBySiteId = {
  [siteId: string]: Sensor[];
};

export type SensorReadingsById = {
  [id: string]: SensorTypeToReading;
};

export interface SensorTypeToReading {
  [SensorType.TEMPERATURE]?: SensorReading;
  [SensorType.HUMIDITY]?: SensorReading;
  [SensorType.BINARY]?: SensorReading;
}

export interface SensorReadingFromAPI {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  state?: [boolean];
}

export interface SensorReading {
  processedAt: string;
  receivedAt: string;
  sensorId: string;
  sensor?: Sensor;
  timestamp: string;
  uuid: string;
  vendor: string;
  temperature?: number;
  humidity?: number;
  binary?: boolean;
}

const checkIfReadingIsMoreRecent = (
  newReading: SensorReading,
  oldReading?: SensorReading
) => {
  if (!oldReading) return true;
  return isAfter(
    new Date(newReading.timestamp),
    new Date(oldReading.timestamp)
  );
};

const normalizeSubscribedSensorReading = (rawData: SensorReadingFromAPI) => ({
  processedAt: rawData.processedAt,
  receivedAt: rawData.receivedAt,
  sensorId: rawData.sensorId,
  sensor: rawData.sensor,
  timestamp: rawData.timestamp,
  uuid: rawData.uuid,
  vendor: rawData.vendor,
  temperature: rawData.temperature,
  humidity: rawData.humidity,
  binary:
    rawData.state && Array.isArray(rawData.state) ? rawData.state[0] : undefined
});

const sensorFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description || '',
  modelId: data.modelId || undefined,
  resourceId: data.resourceId,
  uuid: data.uuid,
  vendor: data.vendor,
  productId: data.productId || undefined,
  sensorStatus: data.sensorStatus || undefined,
  sensorInfo: data.sensorInfo || undefined,
  firmwareVersion: data.firmwareVersion || undefined,
  hardwareVersion: data.hardwareVersion || undefined,
  sensorOffset: data.sensorOffset || undefined,
  resource:
    data.resource._entity === 'Space'
      ? spacesFromApiResponse(data.resource)
      : equipmentsFromApiResponse(data.resource),
  parentResourceType: data.resource
    ? ResourceType[(data.resource._entity as string).toUpperCase()]
    : undefined,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  types: data.types,
  gatewayId: data.gatewayId || undefined,
  resourceType: ResourceType[(data._entity as string).toUpperCase()]
  //Backend doesn't seem to return location
  // location: data.location,
});

const initialState = {
  sensorsById: {},
  sensorsBySiteId: {},
  sensorReadingsById: {},
  isSensorsLoading: false
};

export const sortedSensors = ({ sensorsById }: Sensors) =>
  values(sensorsById).sort((a, b) => a.title.localeCompare(b.title));

const sensors = (state: Sensors = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SENSORS_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(['sensorsBySiteId', action.siteId], action.data),
        R.assoc(
          'sensorsById',
          R.mergeRight(
            R.view(R.lensProp('sensorsById'), state),
            mapArrayToObject(mapValues(action.data, sensorFromApiResponse))
          )
        ),
        R.assoc('isSensorsLoading', false)
      )(state);

    case Actions.GET_SENSORS_BY_SITE_ID_LOADING:
      return R.assoc('isSensorsLoading', true, state);

    case Actions.GET_SENSORS_BY_SITE_ID_ERROR:
      return R.assoc('isSensorsLoading', false, state);

    case StreamActions.RECEIVED_DEVICE_READING:
      const normalizedPayload: SensorReading = normalizeSubscribedSensorReading(
        action.data
      );
      const { sensorId, temperature, humidity, binary } = normalizedPayload;
      let oldValue: SensorReading | undefined = undefined;
      let sensorType: SensorType | undefined = undefined;

      if (humidity || humidity === 0) {
        oldValue = R.pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.HUMIDITY],
          state
        );
        sensorType = SensorType.HUMIDITY;
      } else if (temperature || temperature === 0) {
        oldValue = R.pathOr(
          null,
          ['sensorReadingsById', sensorId, SensorType.TEMPERATURE],
          state
        );
        sensorType = SensorType.TEMPERATURE;
      } else if (binary || binary === false) {
        oldValue = R.pathOr(null, [sensorId, SensorType.BINARY], state);
        sensorType = SensorType.BINARY;
      }

      if (
        oldValue === undefined ||
        !checkIfReadingIsMoreRecent(action.data, oldValue)
      ) {
        return state;
      } else {
        return R.assocPath(
          ['sensorReadingsById', sensorId, sensorType],
          normalizedPayload,
          state
        );
      }

    default:
      return state;
  }
};

export default sensors;
