import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import rootReducer from './reducers';
import type ApplicationState from './reducers';
import history from './history';

import gaMiddleware from './middlewares/gaMiddleware';
import apiMiddleware from './middlewares/apiMiddleware';
import authMiddleware from './middlewares/authMiddleware';
import streamApiMiddleware from './middlewares/streamApiMiddleware';
import redirectMiddleware from './middlewares/redirectMiddleware';
import uiMiddleware from './middlewares/uiMiddleware';
import { PreloadedState } from '@reduxjs/toolkit';

// TODO: Add index signature
interface Window {
  [key: string]: any;
}

export interface Action {
  type: string;
  payload: any;
}

const middleware = [thunk];
let composeEnhancers = compose;

if (process.env.NODE_ENV === 'development') {
  const { logger } = require('redux-logger');
  const w: Window = window;
  composeEnhancers =
    typeof w !== 'undefined' && w['__REDUX_DEVTOOLS_EXTENSION__']
      ? w['__REDUX_DEVTOOLS_EXTENSION_COMPOSE__']({})
      : compose;

  middleware.push(logger);
}

export const configureStore = (
  preloadedState?: PreloadedState<typeof ApplicationState>
) =>
  createStore(
    rootReducer,
    preloadedState,
    composeEnhancers(
      applyMiddleware(
        ...middleware,
        routerMiddleware(history),
        uiMiddleware,
        apiMiddleware,
        authMiddleware,
        streamApiMiddleware,
        redirectMiddleware,
        gaMiddleware
      )
    )
  );

const store = configureStore();

export default store;
