import mixpanel from 'mixpanel-browser';
import {
  ADD_COMMENT,
  ADD_COMMENT_RESPONSE,
  LOGIN,
  LOGIN_AUTO,
  LOGIN_MANUAL,
  LOGOUT_AUTO,
} from '../mixpanelEvents';
import { NewCommentFields } from '@energybox/react-ui-library/dist/types';
import { getEmail, getExpiryDate } from './tokenUtil';
import {
  STORE_TEMP_CHECK_KEY,
  STORE_TOKEN_KEY,
} from '../middlewares/authMiddleware';

export const trackAutoLogout = () => {
  const email = getEmail(STORE_TOKEN_KEY);
  mixpanel.identify(email);
  mixpanel.track(LOGOUT_AUTO, {
    exp: String(getExpiryDate(STORE_TOKEN_KEY)),
    $email: email,
  });
};

export const trackLogin = (email: string, isManual: boolean) => {
  mixpanel.identify(email);
  mixpanel.people.set({ $email: email });
  mixpanel.track(isManual ? LOGIN_MANUAL : LOGIN_AUTO, { $email: email });
  mixpanel.track(LOGIN, { $email: email });
};

export const trackAddingComment = (
  payload: NewCommentFields,
  result?: 'SUCCESS' | 'FAILED'
) => {
  let detail = {};
  if (Object.prototype.toString.call(payload) !== '[object Object]') {
    detail['response'] = payload;
  } else {
    detail = payload;
  }
  if (result) detail['result'] = result;
  const email = getEmail(STORE_TEMP_CHECK_KEY);
  mixpanel.identify(email);
  mixpanel.track(result ? ADD_COMMENT_RESPONSE : ADD_COMMENT, detail);
};
