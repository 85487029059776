import { ForgotPasswordPage } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as AppActions,
  forgotPassword,
  updateForgotPasswordField
} from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import * as v from '../../validators';

interface Props {
  email: string;
  password: string;
  apiError: ApiError;
  isResetPasswordEmailSent: boolean;
  forgotPassword: (email: string) => void;
  onChange: (field: string, value: string) => void;
  isForgotPasswordLoading: boolean;
}

class ForgotPasswordPageContainer extends React.Component<Props> {
  validateForm = () => {
    const { email } = this.props;
    return email !== '' && v.isEmail(email);
  };

  handleSubmit = (e: any) => {
    const { forgotPassword, email } = this.props;
    e.preventDefault();
    forgotPassword(email);
  };

  render() {
    const {
      email,
      onChange,
      apiError,
      isResetPasswordEmailSent,
      isForgotPasswordLoading
    } = this.props;

    return (
      <ForgotPasswordPage
        email={email}
        apiError={renderAPIerror(apiError, AppActions.FORGOT_PASSWORD_ERROR)}
        isResetPasswordEmailSent={isResetPasswordEmailSent}
        validateForm={this.validateForm}
        handleSubmit={this.handleSubmit}
        handleChange={onChange}
        isForgotPasswordLoading={isForgotPasswordLoading}
        backgroundImage={`${process.env.PUBLIC_URL}/login_background.jpg`}
      />
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  email: app.forgotPasswordForm.email,
  apiError: app.apiError,
  isResetPasswordEmailSent: app.isResetPasswordEmailSent,
  isForgotPasswordLoading: app.isForgotPasswordLoading
});

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (field: string, value: string) =>
      dispatch(updateForgotPasswordField(field, value)),
    forgotPassword: (email: string) => dispatch(forgotPassword(email))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordPageContainer);
