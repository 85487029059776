import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { clearError } from '../actions/ui';
import { ApplicationState } from '../reducers';
import { actionMatches, getActionName } from '../utils/actionUtil';
import { ApiError } from '../utils/apiErrorFeedback';

export const useError = (
  action: string
): { error: ApiError | null; clearError: () => void } => {
  const dispatch = useDispatch();
  const matches = actionMatches(action);

  const error = useSelector<ApplicationState, ApiError | null>(
    ({ ui }: ApplicationState) => {
      return matches ? ui.action.errors[matches[1]] ?? null : null;
    }
  );

  const clear = useCallback(() => {
    dispatch(clearError(getActionName(action)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return {
    error,
    clearError: clear,
  };
};
