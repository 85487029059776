export enum Actions {
  PATH_LOADING = '@paths/PATH_LOADING',
  PATH_SUCCESS = '@paths/PATH_SUCCESS',
  PATH_ERROR = '@paths/PATH_ERROR',

  CLEAR_RESOURCE_PATH = '@paths/CLEAR_RESOURCE_PATHS'
}

export const getResourcePathById = (id: number) => ({
  type: 'API_GET',
  path: `/api/v1/resources/${id}/path-tiny`,
  loading: { type: Actions.PATH_LOADING, id },
  success: { type: Actions.PATH_SUCCESS, id },
  error: { type: Actions.PATH_ERROR, id }
});

export const validateResourcePath = (resourcePathsById, resource: any) => {
  const id =
    typeof resource === 'number' || typeof resource === 'string'
      ? resource
      : resource.id;
  const path = resourcePathsById[id];
  if (path && path.length) {
    return path;
  } else {
    return false;
  }
};

export const clearResourcePath = (id: number) => ({
  type: Actions.CLEAR_RESOURCE_PATH,
  id
});
