import { Alert } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { useAlert } from '../../hooks/useAlert';
import styles from './GlobalAlertContainer.module.css';

interface Props {
  noMargin?: boolean;
}

const GlobalAlertContainer: React.FC<Props> = (props) => {
  const { noMargin } = props;

  const { alerts, remove } = useAlert();

  return (
    <div className={styles.root}>
      {alerts.map((alert, index) => (
        <Alert
          key={`alert_${index}`}
          message={alert.message}
          type={alert.type}
          style={{
            margin: noMargin ? 0 : undefined,
          }}
          startAt={Date.now()}
          timeoutAt={alert.timeoutAt}
          onTimeout={() => remove(alert)}
        />
      ))}
    </div>
  );
};

export default GlobalAlertContainer;
