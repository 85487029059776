import {
  CurrentUser,
  EquipmentTypesById,
  Locale,
  Site,
  SopTypes,
  SubscribedIncident,
} from '@energybox/react-ui-library/dist/types';
import { Button } from '@energybox/react-ui-library/dist/components';
import { ChevronLeft } from '@energybox/react-ui-library/dist/icons';
import mixpanel from 'mixpanel-browser';
import equals from 'ramda/src/equals';

import React from 'react';
import { connect } from 'react-redux';
import { getEquipmentTypes } from '../../actions/app';
import { getSiteEquipmentRecentTempReport } from '../../actions/reports';
import EquipmentCheckTable from '../../components/EquipmentCheckTable';
import history from '../../history';
import { TEMPERATURE_CHECK_GO_BACK } from '../../mixpanelEvents';
import { ApplicationState } from '../../reducers';
import {
  EquipmentTemperatureReport,
  TempCheckEquipment,
} from '../../reducers/reports';
import styles from './EquipmentCheckContainer.module.css';
import { getSOP } from '../../actions/sops';
import { SOPComponentTypesByEquipmentTypeId } from '../../reducers/sops';
import GlobalAlertContainer from '../GlobalAlertContainer/GlobalAlertContainer';

type OwnProps = {
  site: Site | undefined;
};

interface Props extends OwnProps {
  locale: Locale;
  equipmentTypesById?: EquipmentTypesById;
  equipmentTemperatureReport: EquipmentTemperatureReport;
  tempCheckEquipmentList: TempCheckEquipment[];
  subscribedActiveIncidents: SubscribedIncident[];
  sops: SOPComponentTypesByEquipmentTypeId;
  currentTempCheckUser?: CurrentUser;
  getSiteEquipmentRecentTempReport: typeof getSiteEquipmentRecentTempReport;
  getEquipmentTypes: typeof getEquipmentTypes;
  getSOP: typeof getSOP;
}

class EquipmentCheckContainer extends React.Component<Props> {
  componentDidMount() {
    const {
      getSiteEquipmentRecentTempReport,
      getEquipmentTypes,
      getSOP,
      equipmentTypesById,
      site,
    } = this.props;

    if (!equipmentTypesById) {
      getEquipmentTypes();
    }
    if (site) {
      getSOP({
        organizationUnitIds: [site.id, site.organizationId],
        types: [SopTypes.TEMPERATURE_RANGE],
      });
      getSiteEquipmentRecentTempReport(site.id);
    }
  }

  componentDidUpdate(prevProps: Props) {
    const { site, getSiteEquipmentRecentTempReport, getSOP } = this.props;
    if (site && !equals(prevProps.site, site)) {
      getSiteEquipmentRecentTempReport(site.id);
      getSOP({
        organizationUnitIds: [site.id, site.organizationId],
        types: [SopTypes.TEMPERATURE_RANGE],
      });
    }
  }

  goBack = () => {
    history.push('/');
    mixpanel.track(TEMPERATURE_CHECK_GO_BACK);
  };

  render() {
    const {
      equipmentTemperatureReport,
      locale,
      site,
      equipmentTypesById,
      subscribedActiveIncidents,
      tempCheckEquipmentList,
      sops,
      currentTempCheckUser,
    } = this.props;

    return (
      <div className={styles.root}>
        <div className={styles.headerContainer}>
          <div className={styles.backButtonContainer}>
            <Button
              roundedCorners
              onClick={this.goBack}
              disabled={!!currentTempCheckUser}
            >
              <span className={styles.iconContainer}>
                <ChevronLeft size={16} />
              </span>
              Back to Kiosk
            </Button>
          </div>

          <div className={styles.titleContainer}>
            <span>Temp Check</span>
          </div>

          <GlobalAlertContainer noMargin />
        </div>

        {site && (
          <EquipmentCheckTable
            equipmentTypesById={equipmentTypesById}
            locale={locale}
            timezone={site.timeZone}
            isLoading={equipmentTemperatureReport.isLoading}
            data={tempCheckEquipmentList}
            subscribedActiveIncidents={subscribedActiveIncidents}
            sop={sops.data}
            fetchData={() =>
              this.props.getSiteEquipmentRecentTempReport(site.id)
            }
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = ({ reports, app, sops }: ApplicationState) => ({
  locale: app.locale,
  equipmentTypesById: app.equipmentTypesById,
  equipmentTemperatureReport: reports.equipmentTemperatureReport,
  tempCheckEquipmentList: reports.tempCheckEquipmentList,
  sops: sops.sopComponentTypesByEquipmentTypeId,
  currentTempCheckUser: app.currentTempCheckUser,
});

const mapDispatchToProps = {
  getSiteEquipmentRecentTempReport,
  getSOP,
  getEquipmentTypes,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentCheckContainer);
