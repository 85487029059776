import { TempCheck as TempCheckIcon } from '@energybox/react-ui-library/dist/icons';
import {
  Site,
  SubscribedIncident,
} from '@energybox/react-ui-library/dist/types';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import mixpanel from 'mixpanel-browser';

import React, { useState } from 'react';
import WidgetCard from '../../components/WidgetCard';
import history from '../../history';
import useCurrentUser from '../../hooks/useCurrentUser';
import {
  TEMPERATURE_CHECK,
  TEMPERATURE_CHECK_GO_BACK,
} from '../../mixpanelEvents';
import { EquipmentById } from '../../reducers/equipment';
import { SpacesById } from '../../reducers/spaces';
import { Routes } from '../../routes';
import GlobalAlertContainer from '../GlobalAlertContainer/GlobalAlertContainer';
import IncidentCardsContainer from '../IncidentCardsContainer';
import styles from './HomePage.module.css';

type Props = {
  equipmentById: EquipmentById;
  spacesById: SpacesById;
  subscribedActiveIncidents: SubscribedIncident[];
  site: Site | undefined;
};

const HomePage: React.FC<Props> = ({
  site,
  equipmentById,
  spacesById,
  subscribedActiveIncidents,
}) => {
  useInitializeMixpanelUser(site);

  return (
    <div className={styles.column}>
      <GlobalAlertContainer />
      <div className={styles.root}>
        <div className={styles.incidentCardsContainer}>
          <IncidentCardsContainer
            site={site}
            subscribedActiveIncidents={subscribedActiveIncidents}
            equipmentById={equipmentById}
            spacesById={spacesById}
          />
        </div>

        <div className={styles.widgetsContainer}>
          <WidgetCard
            title="Temp Check"
            icon={<TempCheckIcon size={46} />}
            onClick={() => {
              mixpanel.time_event(TEMPERATURE_CHECK_GO_BACK);
              mixpanel.track(TEMPERATURE_CHECK, {});
              history.push(Routes.TEMP_CHECK);
            }}
            className={styles.button}
          />
        </div>
      </div>
    </div>
  );
};

const useInitializeMixpanelUser = (site: Site | undefined) => {
  const [shouldInit, setShouldInit] = useState(true);
  const currentUser = useCurrentUser();
  if (isDefined(currentUser) && isDefined(site) && shouldInit) {
    const { id: userId, organizationId, email } = currentUser;
    mixpanel.identify(email);
    mixpanel.people.set({
      $email: email,
      // "Sign up date": USER_SIGNUP_DATE,    // Send dates in ISO timestamp format (e.g. "2020-01-02T21:07:03Z")
      USER_ID: userId,
      ORG_ID: organizationId,
      SITE_ID: site.id,
      SITE_TITLE: site.title,
    });
    setShouldInit(false);
  }
};

export default HomePage;
