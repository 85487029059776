import {
  Select,
  SelectItem,
  SelectSearch,
} from '@energybox/react-ui-library/dist/components';
import { User } from '@energybox/react-ui-library/dist/types';
import React, { useMemo, useRef, useState } from 'react';
import styles from './UserSelector.module.css';
import { mapObjIndexed } from 'ramda';
import { UsersById } from '../../../reducers/users';
import { useResizeObserver } from '../../../hooks/useResizeObserver';

export interface UserOption extends User {
  displayName: string;
}

interface Props {
  users: UsersById;
  selectedUserId?: number;
  setSelectedUserId: (id: number) => void;
  disabled?: boolean;
}

const UserSelector: React.FC<Props> = (props) => {
  const { users, selectedUserId, setSelectedUserId, disabled } = props;

  const ref = useRef<HTMLDivElement>(null);
  const { width } = useResizeObserver(ref);

  const [searchUser, setSearchUser] = useState<string>('');

  const userList: { [id: number]: UserOption } = useMemo(
    () =>
      mapObjIndexed(
        (val: User, key, obj) => ({
          ...val,
          displayName: `${val.firstName} ${val.lastName}`,
        }),
        users
      ),
    [users]
  );
  const filteredUserList: UserOption[] = useMemo(
    () =>
      Object.values(userList)
        .filter((u) => {
          return u.displayName.toLowerCase().includes(searchUser.toLowerCase());
        })
        .sort((a, b) => a.displayName.localeCompare(b.displayName)),
    [searchUser, userList]
  );

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Select
        title={selectedUserId ? userList[selectedUserId].displayName : 'Name'}
        disabled={disabled}
      >
        <SelectSearch
          onChange={(ev) => setSearchUser(ev.currentTarget.value)}
          value={searchUser}
          sticky
        />

        {filteredUserList.map((item) => (
          <SelectItem
            key={item.id}
            className={styles.selectItem}
            onSelect={() => setSelectedUserId(item.id)}
            isSelected={selectedUserId === item.id}
            style={{ width: width, boxSizing: 'border-box' }}
          >
            {item.displayName}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
};

export default UserSelector;
