import { CurrentUser } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getCurrentUser, getTempCheckCurrentUser } from '../actions/app';
import { ApplicationState } from '../reducers';

const useGetCurrentUser = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getCurrentUser());
  }, [dispatch]);

  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => {
      return app.currentUser;
    }
  );

  return currentUser;
};

export const useGetTempCheckCurrentUser = () => {
  const dispatch = useDispatch();

  const token = useSelector<ApplicationState, string | undefined>(({ app }) => {
    return app.tempCheckToken;
  });

  useEffect(() => {
    if (token) dispatch(getTempCheckCurrentUser(token));
  }, [dispatch, token]);

  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => {
      return app.currentUser;
    }
  );

  return currentUser;
};

export default useGetCurrentUser;
