import { WeatherData } from '@energybox/react-ui-library/dist/types';
import { TIME_IN_MILLISECONDS } from '@energybox/react-ui-library/dist/utils';
import { Dispatch, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getWeatherBySiteId } from '../actions/weather';
import { ApplicationState } from '../reducers';

export default function useGetWeather(siteId: number | undefined) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatchGetWeather(dispatch, siteId);

      const intervalId = setInterval(() => {
        dispatchGetWeather(dispatch, siteId);
      }, TIME_IN_MILLISECONDS.TWO_HOURS);

      return () => clearInterval(intervalId);
    }
  }, [dispatch, siteId]);

  const weather = useSelector<ApplicationState, WeatherData | undefined>(
    ({ weather }) => {
      if (siteId) {
        return weather.weatherBySiteId[siteId];
      }
      return undefined;
    }
  );

  return weather;
}

const dispatchGetWeather = (dispatch: Dispatch<any>, siteId: number) => {
  dispatch(getWeatherBySiteId(siteId));
};
