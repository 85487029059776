import { Actions as AppActions } from '../actions/app';

const trackPage = (page) => {
  gtag('set', 'page_view', {
    page_location: window.location.href,
    page_path: window.location.pathname
  });
};

let currentPage = '';

const googleAnalytics = (store) => (next) => (action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    const nextPage = `${action.payload.location.pathname}${action.payload.location.search}`;
    if (currentPage !== nextPage) {
      currentPage = nextPage;
      trackPage(nextPage);
    }
  }

  if (action.type === AppActions.CURRENT_USER_SUCCESS) {
    if (action.data) {
      gtag('config', process.env.REACT_APP_GA_TRACKING_ID as string, {
        user_id: action.data.id
      });

      gtag('set', 'user_properties', {
        user_type:
          String(action.data.organizationId) ===
          String(process.env.REACT_APP_ENERGYBOX_ORG)
            ? 'INTERNAL'
            : 'EXTERNAL'
      });
    }
  }

  return next(action);
};

export default googleAnalytics;
