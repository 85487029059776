import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getComments } from '../actions/comments';
import { ApplicationState } from '../reducers';
import { CommentStatus } from '../reducers/comments';
import {
  Comment,
  CommentApiFilter,
} from '@energybox/react-ui-library/dist/types';

const useGetCommentList = (
  resourceId: number,
  filter?: CommentApiFilter
): {
  isLoading: boolean;
  commentList: Comment[];
  fetchCommentList: () => void;
} => {
  const dispatch = useDispatch();
  const commentStatus = useSelector<
    ApplicationState,
    CommentStatus | undefined
  >(({ comments }) => {
    return comments.commentsByResourceId[resourceId];
  });

  const fetchCommentList = useCallback(() => {
    dispatch(getComments(resourceId, filter));
  }, [dispatch, resourceId, filter]);

  useEffect(() => {
    if (resourceId) fetchCommentList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading: commentStatus?.isLoading ?? false,
    commentList: commentStatus?.comments ?? [],
    fetchCommentList,
  };
};

export default useGetCommentList;
