import { Button } from '@energybox/react-ui-library/dist/components';
import {
  IconCheckboxOutlined,
  ListCheckIcon,
} from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import translate from '../../../translations';
import styles from './TempCheckButton.module.css';

interface Props {
  onClick: () => void;
  isDone?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

enum STATUS {
  'DONE' = 'done',
  'NOT_DONE' = 'not_done',
}

const TempCheckStatus = {
  [STATUS.DONE]: {
    icon: IconCheckboxOutlined,
    label: translate.button.tempCheckDone,
  },
  [STATUS.NOT_DONE]: {
    icon: ListCheckIcon,
    label: translate.button.tempCheck,
  },
};

const TempCheckButton: React.FC<Props> = (props) => {
  const { isDone, disabled, isLoading, onClick } = props;

  const status = isDone ? STATUS.DONE : STATUS.NOT_DONE;
  const Icon = TempCheckStatus[status].icon;

  return (
    <Button
      roundedCorners
      variant={status === STATUS.NOT_DONE ? 'outlined' : 'solid'}
      onClick={onClick}
      className={classNames(styles.button, styles[status])}
      disabled={disabled}
      isLoading={isLoading}
    >
      <Icon size={25} />
      {TempCheckStatus[status].label}
    </Button>
  );
};

export default TempCheckButton;
