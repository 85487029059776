import { Equipment } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export const useEquipment = (resourceId: number) => {
  const equipment = useSelector<ApplicationState, Equipment | undefined>(
    ({ equipment }) => {
      return equipment.equipmentById[String(resourceId)];
    }
  );

  return equipment;
};
