export enum Actions {
  CONNECTED = '@@streamApi/CONNECTED',
  ERROR = '@@streamApi/ERROR',
  CLOSED = '@@streamApi/CLOSED',
  SEND_MESSAGE = '@@streamApi/SEND_MESSAGE',
  RECEIVED_DEVICE_STATUS = '@@streamApi/RECEIVED_DEVICE_STATUS',
  RECEIVED_DEVICE_READING = '@@streamApi/RECEIVED_DEVICE_READING',
  RECEIVED_ENERGY_PRO_READING = '@@streamApi/RECEIVED_ENERGY_PRO_READING',
  RECEIVED_SUBSCRIBED_INCIDENT = '@@streamApi/RECEIVED_SUBSCRIBED_INCIDENT'
}

export enum CommandType {
  SUBSCRIBE = 'SUBSCRIBE',
  UNSUBSCRIBE = 'UNSUBSCRIBE'
}

export enum Channel {
  DEVICE_STATUS = 'DEVICE_STATUS',
  SENSOR_READINGS = 'SENSOR_READINGS',
  ENERGY_SENSOR_READING = 'ENERGY_SENSOR_READING',
  INCIDENT_INFO = 'INCIDENT_INFO',
  PING = 'PING'
}

export const subscribeToIncident = ({
  equipmentId,
  spaceId
}: {
  equipmentId?: number | string;
  spaceId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.INCIDENT_INFO,
    equipmentId,
    spaceId
  }
});

export const unsubscribeFromIncident = ({
  equipmentId,
  spaceId
}: {
  equipmentId?: number | string;
  spaceId?: number | string;
}) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.INCIDENT_INFO,
    equipmentId,
    spaceId
  }
});

export const subscribeToDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.SUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id
  }
});

export const unsubscribeFromDeviceStatus = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.DEVICE_STATUS,
    vendor,
    uuid,
    id
  }
});

export const subscribeToDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.SENSOR_READINGS,
      vendor,
      uuid,
      id
    }
  };
};

export const unsubscribeFromDeviceReadings = (
  vendor: string,
  uuid: string,
  id?: number | string
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.SENSOR_READINGS,
    vendor,
    uuid,
    id
  }
});

export const subscribeToEnergyProSensorReadings = (
  vendor: string,
  uuid: string,
  id: number
) => {
  return {
    type: Actions.SEND_MESSAGE,
    data: {
      type: CommandType.SUBSCRIBE,
      channel: Channel.ENERGY_SENSOR_READING,
      vendor,
      uuid,
      id
    }
  };
};

export const unsubscribeFromEnergyProSensorReadings = (
  vendor: string,
  uuid: string,
  id: number
) => ({
  type: Actions.SEND_MESSAGE,
  data: {
    type: CommandType.UNSUBSCRIBE,
    channel: Channel.ENERGY_SENSOR_READING,
    vendor,
    uuid,
    id
  }
});
