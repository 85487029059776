import {
  Card,
  IconEquipmentFactory,
  Loader,
  MediaElement,
} from '@energybox/react-ui-library/dist/components';
import Table, {
  Columns,
} from '@energybox/react-ui-library/dist/components/Table';
import {
  Locale,
  SubscribedIncident,
  EquipmentTypesById,
  TemperatureSop,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import {
  formatIanaTimeZone,
  global,
  createTemperatureString,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import mixpanel from 'mixpanel-browser';
import pathOr from 'ramda/src/pathOr';

import React, { useState } from 'react';
import TempCheckActionContainer from '../../containers/TempCheckActionContainer/TempCheckActionContainer';
import TempCheckContainer from '../../containers/TempCheckContainer/TempCheckContainer';
import useCurrentUser from '../../hooks/useCurrentUser';
import { useTempCheck } from '../../hooks/useTempCheck';
import { TEMPERATURE_CHECK_SET_PAGE } from '../../mixpanelEvents';
import { TempCheckEquipment } from '../../reducers/reports';
import { SOPCompsByEquipTypeId } from '../../reducers/sops';
import { RecentEquipmentTemperatureReport } from '../../types/reports';
import CommentHistoryButton from '../Button/CommentHistoryButton/CommentHistoryButton';
import SwipesWrapper from '../Swipes/SwipesWrapper';
import styles from './EquipmentCheckTable.module.css';

const ROW_HIGHLIGHT_KEY_FIELD = 'rowHighlightStatus';
const TABLE_ROW_LIMIT = 10;

interface ProcessedData extends RecentEquipmentTemperatureReport {
  sop?: TemperatureSop;
  [ROW_HIGHLIGHT_KEY_FIELD]?: string;
}

type Props = {
  isLoading: boolean;
  locale: Locale;
  timezone: string;
  equipmentTypesById?: EquipmentTypesById;
  data: TempCheckEquipment[];
  subscribedActiveIncidents: SubscribedIncident[];
  sop: SOPCompsByEquipTypeId;
  fetchData: () => void;
};

const EquipmentCheckTable: React.FC<Props> = ({
  isLoading,
  data,
  locale,
  timezone,
  equipmentTypesById,
  subscribedActiveIncidents,
  sop,
  fetchData,
}) => {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const currentUser = useCurrentUser();
  const { isAuthenticated } = useTempCheck();

  if (!currentUser) return null;
  const columns: Columns<ProcessedData>[] = [
    {
      width: '28rem',
      header: <span className={styles.header}>Equipment Name</span>,
      cellContent: (record) => {
        const equipmentTypeAlias = pathOr(
          undefined,
          [record?.equipment?.typeId, 'alias'],
          equipmentTypesById || {}
        );

        return (
          <span className={styles.baseFontColor}>
            <MediaElement
              className={styles.mediaElement}
              icon={<IconEquipmentFactory id={equipmentTypeAlias} size={40} />}
              title={record?.equipment?.title || global.NOT_AVAILABLE}
              description={record?.title}
            />
          </span>
        );
      },
    },
    {
      width: '8.4350rem',
      header: <span className={styles.header}>Space</span>,
      cellContent: (record) => {
        // TODO: Restrict number of characters for space title so it doesnt overflow the entire table
        return (
          <span className={styles.baseFontColor}>{record?.space?.title}</span>
        );
      },
    },
    // SOP Range
    {
      width: '5.75rem',
      header: <span className={styles.header}>SOP</span>,
      cellContent: (record) => {
        const rowStatus = record[ROW_HIGHLIGHT_KEY_FIELD];
        return (
          <span
            className={
              rowStatus ? styles[`font-${rowStatus}`] : styles.baseFontColor
            }
          >
            {`${
              !record.sop
                ? global.NOT_AVAILABLE
                : createTemperatureString(record.sop.min, currentUser)
            } - ${
              !record.sop
                ? global.NOT_AVAILABLE
                : createTemperatureString(record.sop.max, currentUser)
            }`}
          </span>
        );
      },
      cellStyle: {
        whiteSpace: 'nowrap',
      },
    },
    {
      width: '5.75rem',
      header: <span className={styles.header}>Temperature</span>,
      cellContent: (record) => {
        const rowStatus = record[ROW_HIGHLIGHT_KEY_FIELD];

        return (
          <span
            className={
              rowStatus ? styles[`font-${rowStatus}`] : styles.baseFontColor
            }
          >
            {!record?.temperature && record?.temperature !== 0
              ? global.NOT_AVAILABLE
              : createTemperatureString(record?.temperature, currentUser)}
          </span>
        );
      },
      cellStyle: {
        textAlign: 'end',
      },
    },
    {
      width: '6.9375rem',
      header: <span className={styles.header}>Time</span>,
      cellContent: (record) => (
        <span className={styles.baseFontColor}>
          {record?.timestamp
            ? formatIanaTimeZone(
                record?.timestamp * 1000,
                locale.fullTimeFormat,
                timezone
              )
            : global.NOT_AVAILABLE}{' '}
        </span>
      ),
    },
    {
      width: '2rem',
      header: '',
      cellContent: ({
        equipment,
        space,
        temperature,
        timestamp,
        uuid,
        sensorId,
      }) => {
        if (!equipment) return;
        return (
          <div className={styles.buttonGroup}>
            {isAuthenticated ? (
              <TempCheckContainer
                sensorId={sensorId}
                equipmentId={equipment.id}
                valueType={ValueType.TEMPERATURE}
                value={temperature}
                timestamp={timestamp}
                uuid={uuid}
              />
            ) : (
              <CommentHistoryButton
                equipmentId={equipment?.id}
                spaceId={space?.id}
              />
            )}
          </div>
        );
      },
      cellStyle: {
        textAlign: 'end',
      },
    },
  ];

  // Filter out sensors attached to spaces, sort by equipment title?
  const sortedAndFilteredData = data.map((d) => {
    let rowHighlightStatus: string | undefined;
    const incident = subscribedActiveIncidents.find(
      (i) => i.equipmentId && Number(i.equipmentId) === Number(d.equipment?.id)
    );

    if (incident && !incident.resolved) {
      rowHighlightStatus = incident.incidentPriority.toLowerCase();
    }

    // map sop
    const equipSOP = isDefined(d.equipment?.typeId)
      ? sop[d.equipment?.typeId ?? 0]?.TEMPERATURE_RANGE
      : null;

    return {
      ...d,
      sop: equipSOP,
      [ROW_HIGHLIGHT_KEY_FIELD]: rowHighlightStatus,
    };
  });

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <Loader size={24} />
      </div>
    );
  }

  return (
    <Card className={styles.cardStyle}>
      <div className={styles.buttonContainer}>
        <TempCheckActionContainer />
      </div>
      <SwipesWrapper
        currentPage={currentPage}
        setCurrentPage={(page) => {
          setCurrentPage((prevPage) => {
            mixpanel.track(TEMPERATURE_CHECK_SET_PAGE, {
              prevPage,
              page,
              eventType: 'swipe',
            });
            return page;
          });
        }}
        totalPage={Math.ceil(sortedAndFilteredData.length / TABLE_ROW_LIMIT)}
        // refreshData={fetchData}
        isScrollable
      >
        <Table
          listView
          renderLimit={TABLE_ROW_LIMIT}
          className={styles.table}
          pageSetEvent={(currentPage, newPage, eventType) => {
            setCurrentPage(newPage);
            mixpanel.track(TEMPERATURE_CHECK_SET_PAGE, {
              currentPage,
              newPage,
              eventType,
            });
          }}
          rowHighlightKeyField={ROW_HIGHLIGHT_KEY_FIELD}
          data={sortedAndFilteredData}
          columns={columns}
          pageNumber={currentPage}
          hideRowLimitControl
        />
      </SwipesWrapper>
    </Card>
  );
};

export default EquipmentCheckTable;
