import { useLayoutEffect, useState, useCallback, RefObject } from 'react';
import ResizeObserver from 'resize-observer-polyfill';

export const useResizeObserver = (
  ref: RefObject<HTMLElement>,
  callback?: (entry: Partial<DOMRectReadOnly>) => void
) => {
  const [width, setWidth] = useState<number | undefined>();
  const [height, setHeight] = useState<number | undefined>();

  const handleResize = useCallback(
    (entries: ResizeObserverEntry[]) => {
      if (!Array.isArray(entries)) {
        return;
      }

      const entry = entries[0];
      setWidth(entry.contentRect.width);
      setHeight(entry.contentRect.height);

      if (callback) {
        callback(entry.contentRect);
      }
    },
    [callback]
  );

  useLayoutEffect(() => {
    if (!ref.current) {
      return;
    }

    let RO: ResizeObserver | null = new ResizeObserver(handleResize);
    RO.observe(ref.current);

    return () => {
      if (RO) RO.disconnect();
      RO = null;
    };
  }, [handleResize, ref]);

  return { width, height };
};
