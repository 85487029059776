import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import useCountDown from './useCountDown';
import { setInitialPageLoading } from '../actions/subscribedIncidents';

const useInitialLoading = () => {
  //Arbitrary, but consider the first 3 seconds app start as the initial loading
  //probably need a better way of defining initial loading

  //we only need this b/c we don't want dismissed incident alerts
  //to show up in the initial loading of app
  const initialLoadingCountdown = useCountDown(3);

  const dispatch = useDispatch();
  useEffect(() => {
    if (initialLoadingCountdown === 0) {
      dispatch(setInitialPageLoading(false));
    }
  }, [dispatch, initialLoadingCountdown]);
};

export default useInitialLoading;
