import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { insertAlert, removeAlert } from '../actions/ui';
import { ApplicationState } from '../reducers';
import { Alert } from '../reducers/ui';

export const useAlert = (): {
  alerts: Alert[];
  saveAlert: (alert: Alert) => void;
  remove: (alert: Alert) => void;
} => {
  const dispatch = useDispatch();
  const alerts = useSelector<ApplicationState, Alert[]>(({ ui }) => ui.alert);

  const saveAlert = useCallback(
    (alert: Alert) => {
      dispatch(insertAlert(alert));
    },
    [dispatch]
  );

  const remove = useCallback(
    (alert: Alert) => {
      dispatch(removeAlert(alert));
    },
    [dispatch]
  );

  return {
    alerts,
    saveAlert,
    remove,
  };
};
