import { Sop } from '@energybox/react-ui-library/dist/types';
import { Actions } from '../actions/sops';
import * as R from 'ramda';
import { SopEnumValuesToComponentType } from '@energybox/react-ui-library/dist/types/Sop';

export type SOPComponentsByCompType = SopEnumValuesToComponentType;

export interface SOPCompsByEquipTypeId {
  [id: number]: SOPComponentsByCompType;
}

export interface SOPComponentTypesByEquipmentTypeId {
  isLoading: boolean;
  data: SOPCompsByEquipTypeId;
}

export type Sops = {
  sopComponentTypesByEquipmentTypeId: SOPComponentTypesByEquipmentTypeId;
};

const initialState = {
  sopComponentTypesByEquipmentTypeId: {
    isLoading: false,
    data: {},
  },
};

const sopListMappting = (sops: Sop[], prevData: SOPCompsByEquipTypeId) => {
  const sopCompByEquipTypeId: SOPCompsByEquipTypeId = { ...prevData };
  sops.forEach((sop) => {
    const componentsByType = sop.components.reduce(
      (allType, comp) => ({ ...allType, [comp.type]: comp }),
      {}
    );
    sop.equipmentTypeIds.forEach((id) => {
      sopCompByEquipTypeId[id] = R.mergeLeft(
        sopCompByEquipTypeId[id],
        componentsByType
      );
    });
  });
  return sopCompByEquipTypeId;
};

const sops = (state: Sops = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SOP_LIST_LOADING:
      return R.assocPath(
        ['sopComponentTypesByEquipmentTypeId', 'isLoading'],
        true,
        state
      );
    case Actions.GET_SOP_LIST_SUCCESS:
      return {
        ...state,
        sopComponentTypesByEquipmentTypeId: {
          isLoading: false,
          data: sopListMappting(
            action.data,
            state.sopComponentTypesByEquipmentTypeId.data
          ),
        },
      };
    case Actions.GET_SOP_LIST_ERROR:
      return R.assocPath(
        ['sopComponentTypesByEquipmentTypeId', 'isLoading'],
        false,
        state
      );

    default:
      return state;
  }
};

export default sops;
