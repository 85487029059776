import mixpanel from 'mixpanel-browser';
import { Actions as AppActions } from '../actions/app';
import history from '../history';
import { TOKEN_REFRESH, LOGOUT, LOGOUT_TEMP_CHECK } from '../mixpanelEvents';
import { ApplicationState } from '../reducers';
import { isDefined } from '@energybox/react-ui-library/dist/utils';
import { LOCALSTORAGE_KEY } from '../constant';
import jwt_decode from 'jwt-decode';

export const STORE_TOKEN_KEY = LOCALSTORAGE_KEY.token;
export const STORE_TEMP_CHECK_KEY = LOCALSTORAGE_KEY.tempCheckToken;

export const getToken = (key: string): string | null => {
  return window.localStorage.getItem(key);
};

const setToken = (key: string, authToken: string) => {
  window.localStorage.setItem(key, authToken);
};

export const removeToken = (key: string) => {
  window.localStorage.removeItem(key);
};

const updateToken = (store: any, accessToken: string) => {
  setToken(STORE_TOKEN_KEY, accessToken);
  store.dispatch({
    type: AppActions.ACCESS_TOKEN_UPDATED,
    accessToken: accessToken,
  });
};

const updateTempCheckToken = (store: any, token: string) => {
  setToken(STORE_TEMP_CHECK_KEY, token);
  store.dispatch({
    type: AppActions.TEMP_CHECK_TOKEN_UPDATED,
    token: token,
  });
};

// temporaroly workaround solution for refresh token not working sometime
const STORE_CREDENTIAL_KEY = 'credential';
export const saveCredential = (credential: {
  email: string;
  password: string;
}) => {
  window.localStorage.setItem(STORE_CREDENTIAL_KEY, JSON.stringify(credential));
};
export const getCredential = (): { email: string; password: string } | null => {
  const credentialString = window.localStorage.getItem(STORE_CREDENTIAL_KEY);
  return credentialString ? JSON.parse(credentialString) : null;
};
export const removeCredential = () => {
  window.localStorage.removeItem(STORE_CREDENTIAL_KEY);
};

const authMiddleware = (store) => (next) => (action) => {
  const state: ApplicationState = store.getState();
  const currentUser = isDefined(state.app?.currentUser)
    ? state.app.currentUser
    : null;

  switch (action.type) {
    case AppActions.INIT:
      const accessToken = getToken(STORE_TOKEN_KEY);
      const tempCheckToken = getToken(STORE_TEMP_CHECK_KEY);

      if (accessToken) {
        store.dispatch({
          type: AppActions.ACCESS_TOKEN_UPDATED,
          accessToken,
        });
      }

      if (tempCheckToken) {
        store.dispatch({
          type: AppActions.TEMP_CHECK_TOKEN_UPDATED,
          token: tempCheckToken,
        });
      }
      break;

    case AppActions.INIT_WITH_TOKEN:
    case AppActions.LOGIN_SUCCESS:
      updateToken(store, action.data.accessToken);
      history.push('/', {});
      break;

    case AppActions.REFRESH_TOKEN_SUCCESS:
      mixpanel.track(TOKEN_REFRESH, { $email: currentUser?.email });
      updateToken(store, action.data.accessToken);
      break;

    case AppActions.CHANGE_ORGANIZATION_SUCCESS:
      updateToken(store, action.data.accessToken);
      history.push('/');
      window.location.reload();
      break;

    case AppActions.LOGOUT:
      mixpanel.track(LOGOUT, { $email: currentUser?.email });
      removeToken(STORE_TOKEN_KEY);
      removeToken(STORE_TEMP_CHECK_KEY);
      break;

    case AppActions.LOGOUT_TEMP_CHECK:
      mixpanel.track(LOGOUT_TEMP_CHECK, { $email: currentUser?.email });
      removeToken(STORE_TEMP_CHECK_KEY);
      updateTempCheckToken(store, '');
      break;

    case AppActions.TEMP_CHECK_SIGN_IN_SUCCESS:
      const newTempCheckToken = action.data.accessToken;
      const authToken = getToken(STORE_TOKEN_KEY);
      const decoded = jwt_decode<any>(newTempCheckToken);
      const authTokenDecoded = jwt_decode<any>(authToken ?? '');
      if (decoded?.['uid'] === authTokenDecoded?.['uid'])
        updateToken(store, newTempCheckToken);
      updateTempCheckToken(store, newTempCheckToken);
      break;

    default:
      break;
  }

  next(action);
};

export default authMiddleware;
