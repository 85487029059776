import { mapValues } from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/reports';
import { RecentEquipmentTemperatureReport } from '../types/reports';

export type EquipmentTemperatureReport = {
  isLoading: boolean;
  equipment: RecentEquipmentTemperatureReport[];
};

export type TempCheckEquipment = {
  doneTempCheck?: boolean;
} & RecentEquipmentTemperatureReport;

export interface Reports {
  equipmentTemperatureReport: EquipmentTemperatureReport;
  tempCheckEquipmentList: TempCheckEquipment[];
}

const recentEquipmentTemperatureReportFromApi = (data: any) => ({
  title: data.title,
  uuid: data.uuid,
  sensorId: data.sensorId,
  space: data.space,
  equipment: data.equipment,
  ...(data.sample ? data.sample : {}),
});

export const initialState = {
  equipmentTemperatureReport: {
    equipment: [],
    isLoading: false,
  },
  tempCheckEquipmentList: [],
};

const sortEquipment = (a, b) => {
  return (a?.equipment?.title || '').localeCompare(b?.equipment?.title || '');
};

const getTempCheckEquipment = (data: RecentEquipmentTemperatureReport[]) => {
  return data
    .filter((d) => d.equipment)
    .sort(sortEquipment)
    .map((e) => ({ ...e, doneTempCheck: false }));
};

const reports = (state: Reports = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS:
      const equipments = mapValues(
        action.data.sensorRecords,
        recentEquipmentTemperatureReportFromApi
      );
      return R.pipe(
        R.assocPath(['equipmentTemperatureReport', 'equipment'], equipments),
        R.assocPath(
          ['tempCheckEquipmentList'],
          getTempCheckEquipment(equipments)
        ),
        R.assocPath(['equipmentTemperatureReport', 'isLoading'], false)
      )(state);

    case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING:
      return R.pipe(
        R.assocPath(['equipmentTemperatureReport', 'isLoading'], true),
        R.assocPath(['equipmentTemperatureReport', 'equipment'], [])
      )(state);

    case Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR:
      return R.pipe(
        R.assocPath(['equipmentTemperatureReport', 'isLoading'], false),
        R.assocPath(['equipmentTemperatureReport', 'equipment'], [])
      )(state);

    case Actions.UPDATE_TEMP_CHECK_STATUS:
      const targetEquip = state.tempCheckEquipmentList.find(
        (e) => e.uuid === action.uuid
      );
      const newTempCheckEquip: TempCheckEquipment[] = [
        ...R.reject(
          (e) => e.uuid === action.uuid,
          state.tempCheckEquipmentList
        ),
        { ...targetEquip, doneTempCheck: action.value },
      ].sort(sortEquipment);
      return {
        ...state,
        tempCheckEquipmentList: newTempCheckEquip,
      };

    default:
      return state;
  }
};

export default reports;
