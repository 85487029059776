import {
  EquipmentApiFilter,
  SensorType
} from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/equipment';

export enum Actions {
  GET_EQUIPMENT_BY_SITE_ID_SUCCESS = '@equipment/GET_EQUIPMENT_BY_SITE_ID_SUCCESS',
  GET_EQUIPMENT_BY_SITE_ID_ERROR = '@equipment/GET_EQUIPMENT_BY_SITE_ID_ERROR',
  GET_EQUIPMENT_BY_SITE_ID_LOADING = '@equipment/GET_EQUIPMENT_BY_SITE_ID_LOADING'
}

export type GetEquipmentParams = {
  ids?: string[] | number[];
  equipmentIds?: string[];
  limit?: number;
  sensorTypes?: SensorType[];
  siteIds?: string[];
  skip?: number;
  spaceIds?: string[];
  vendors?: string[];
  withPath?: boolean;
  textSearch?: string;
};

// Make this cleaner with mapping tomorrow
export const createQueryString = (params: GetEquipmentParams = {}) => {
  return Object.keys(params)
    .map(
      (key) =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const setEquipmentFilter = (filter?: EquipmentApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.equipmentTypeIds) {
    queryParams.set('equipmentTypeIds', filter.equipmentTypeIds.join(','));
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  queryParams.set('withPath', 'true');

  return `${apiBase}?${queryParams.toString()}`;
};

export const setGetEnergyEquipmentBySiteIdUrl = (siteId: string) => {
  return `${apiBase}/energy-monitored/site/${siteId}`;
};

export const getEquipmentsBySiteId = (
  siteId: number | string,
  sensorTypes: SensorType[] = []
) => ({
  type: 'API_GET',
  path: sensorTypes.length
    ? `${apiBase}/by-sensors/site/${siteId}?${createQueryString({
        sensorTypes
      })}`
    : `${apiBase}/site/${siteId}`,
  success: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING, siteId }
});
