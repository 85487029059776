//TODO: implement ramda for app reducer
import {
  DateFormat,
  defaultLocale,
  EquipmentGroup,
  EquipmentType,
  Locale,
  MapDateFormat,
  MapFullTimeFormat,
  MapTimeFormat,
  MeasurementSystem,
  TimeFormat,
  EquipmentTypesById,
  EquipmentGroupsById,
  CurrentUser,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  isDefined,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/app';
import { Actions as StreamApiActions } from '../actions/streamApi';
import { ApiError, storeAPIerror } from '../utils/apiErrorFeedback';
import mixpanel from 'mixpanel-browser';
import {
  BROWSER_REFRESH,
  LOGIN_FAILED,
  TEMP_CHECK_LOGIN_FAILED,
} from '../mixpanelEvents';

export interface LoginForm {
  email: string;
  password: string;
}

export interface TempCheckSignIn {
  form: TempCheckSignInForm;
}

export interface TempCheckSignInForm {
  userId?: number;
  password: string;
}

export interface ForgotPasswordForm {
  email: string;
}

export interface ResetPasswordForm {
  email: string;
  password: string;
  passwordVerify: string;
}

export interface App {
  isInitialized: boolean;
  accessToken?: string;
  tempCheckToken?: string;
  streamConnected: boolean;
  locale: Locale;
  currentOrganizationId?: number;
  currentUser?: CurrentUser;
  currentTempCheckUser?: CurrentUser;
  loginForm: LoginForm;
  tempCheckSignIn: TempCheckSignIn;
  forgotPasswordForm: ForgotPasswordForm;
  resetPasswordForm: ResetPasswordForm;
  isForgotPasswordLoading: boolean;
  isResetPasswordLoading: boolean;
  isResetPasswordEmailSent: boolean;
  isPasswordReset: boolean;
  equipmentTypes?: EquipmentType[];
  equipmentTypesById?: EquipmentTypesById;
  equipmentGroups?: EquipmentGroup[];
  equipmentGroupsById?: EquipmentGroupsById;
  apiError: ApiError;
  currentSiteId?: number;
}

const defaultLoginForm = {
  email: '',
  password: '',
};

const defaultForgotPasswordForm = {
  email: '',
};

const defaultResetPasswordForm = {
  email: '',
  password: '',
  passwordVerify: '',
};

const defaultTempCheckSignInForm = {
  userId: undefined,
  password: '',
};

const defaultTempCheckSignIn = {
  form: defaultTempCheckSignInForm,
};

export const initialState: App = {
  locale: defaultLocale,
  isInitialized: false,
  streamConnected: false,
  loginForm: defaultLoginForm,
  tempCheckSignIn: defaultTempCheckSignIn,
  forgotPasswordForm: defaultForgotPasswordForm,
  resetPasswordForm: defaultResetPasswordForm,
  isResetPasswordEmailSent: false,
  isPasswordReset: false,
  isForgotPasswordLoading: false,
  isResetPasswordLoading: false,
  apiError: {},
};

const currentUserFromApiResponse = (data: any): CurrentUser => ({
  id: data.id,
  firstName: data.firstName,
  lastName: data.lastName,
  role: data.role || undefined,
  measurementSystem: data.measurementSystem,
  temperature: data.temperature,
  area: data.area,
  organizationId: data.organization && data.organization.id,
  kiosk: data.kiosk,
  email: data.email,
  organizationTitle: '',
  dashboardProfiles: [],
  phone: data.phone,
});

const tokenMeta = (token: string) => {
  if (!token) return;

  const tokenData = JSON.parse(atob(token.split('.')[1]));

  return {
    currentOrganizationId: tokenData.org,
  };
};

const determineLocale = (
  measurementSystem: MeasurementSystem | undefined,
  dateFormat: DateFormat | undefined,
  timeFormat: TimeFormat | undefined
): Locale => {
  const {
    measurementSystem: defaultMeasurementSystem,
    dateFormat: defaultDateFormat,
    timeFormat: defaultTimeFormat,
    dateTimeFormat: defaultDateTimeFormat,
    fullTimeFormat: defaultFullTimeFormat,
    fullDateTimeFormat: defaultFullDateTimeFormat,
  } = defaultLocale;

  return {
    measurementSystem: measurementSystem || defaultMeasurementSystem,
    timeFormat: timeFormat ? MapTimeFormat[timeFormat] : defaultTimeFormat,
    dateFormat: dateFormat ? MapDateFormat[dateFormat] : defaultDateFormat,
    dateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapTimeFormat[timeFormat]}`
        : defaultDateTimeFormat,
    fullTimeFormat: timeFormat
      ? MapFullTimeFormat[timeFormat]
      : defaultFullTimeFormat,
    fullDateTimeFormat:
      dateFormat && timeFormat
        ? `${MapDateFormat[dateFormat]} ${MapFullTimeFormat[timeFormat]}`
        : defaultFullDateTimeFormat,
  };
};

const reducer = (state = initialState, action: any) => {
  const currentUser = isDefined(state?.currentUser) ? state.currentUser : null;

  switch (action.type) {
    case Actions.INIT:
    case Actions.INIT_WITH_TOKEN:
      mixpanel.track(BROWSER_REFRESH);
      return {
        ...state,
        isInitialized: true,
      };

    case Actions.ACCESS_TOKEN_UPDATED:
      return {
        ...state,
        accessToken: action.accessToken,
        ...tokenMeta(action.accessToken),
      };
    case Actions.TEMP_CHECK_TOKEN_UPDATED:
      return {
        ...state,
        tempCheckToken: action.token,
      };

    case Actions.LOGIN_SUCCESS:
      return R.pipe(
        R.assoc('loginForm', defaultLoginForm),
        R.assoc('apiError', {}),
        R.assoc('isResetPasswordEmailSent', false),
        R.assoc('isPasswordReset', false)
      )(state);

    case Actions.LOGIN_ERROR:
      mixpanel.track(LOGIN_FAILED, { $email: currentUser?.email });
      return {
        ...state,
        apiError: storeAPIerror(action),
      };

    case Actions.CURRENT_USER_SUCCESS:
      const { measurementSystem, timeFormat, dateFormat } = action.data;
      return R.pipe(
        R.assoc('currentUser', currentUserFromApiResponse(action.data)),
        R.assoc(
          'locale',
          determineLocale(measurementSystem, dateFormat, timeFormat)
        )
      )(state);

    case Actions.TEMP_CHECK_USER_SUCCESS:
      return R.pipe(
        R.assoc(
          'currentTempCheckUser',
          currentUserFromApiResponse(action.data)
        ),
        R.assoc(
          'locale',
          determineLocale(
            action.data.measurementSystem,
            action.data.dateFormat,
            action.data.timeFormat
          )
        )
      )(state);

    case Actions.FORGOT_PASSWORD_SUCCESS:
      return R.pipe(
        R.assoc('apiError', {}),
        R.assoc('isResetPasswordEmailSent', true),
        R.assoc('isForgotPasswordLoading', false)
      )(state);

    case Actions.FORGOT_PASSWORD_LOADING:
      return R.assoc('isForgotPasswordLoading', true, state);

    case Actions.FORGOT_PASSWORD_ERROR:
      return R.pipe(
        R.assoc('apiError', storeAPIerror(action)),
        R.assoc('isForgotPasswordLoading', false)
      )(state);

    case Actions.RESET_PASSWORD_SUCCESS:
      return R.pipe(
        R.assoc('apiError', {}),
        R.assoc('isPasswordReset', true),
        R.assoc('isResetPasswordLoading', false)
      )(state);

    case Actions.RESET_PASSWORD_LOADING:
      return R.assoc('isResetPasswordLoading', true, state);

    case Actions.RESET_PASSWORD_ERROR:
      return R.pipe(
        R.assoc('apiError', storeAPIerror(action)),
        R.assoc('isResetPasswordLoading', false)
      )(state);

    case Actions.GET_EQUIPMENT_TYPES_SUCCESS:
      return {
        ...state,
        equipmentTypes: action.data,
        equipmentTypesById: mapArrayToObject(action.data),
      };

    case Actions.GET_EQUIPMENT_GROUPS_SUCCESS:
      return {
        ...state,
        equipmentGroups: action.data,
        equipmentGroupsById: mapArrayToObject(action.data),
      };
    case Actions.UPDATE_LOGIN_FIELD:
      return {
        ...state,
        loginForm: {
          ...state.loginForm,
          [action.field]: action.value,
        },
      };

    case Actions.UPDATE_TEMP_CHECK_SIGN_IN_FIELD:
      return R.pipe(
        R.assocPath(['tempCheckSignIn', 'form', action.field], action.value)
      )(state);
    case Actions.TEMP_CHECK_SIGN_IN_LOADING:
      return R.pipe(R.assocPath(['tempCheckSignIn', 'error'], null))(state);
    case Actions.TEMP_CHECK_SIGN_IN_SUCCESS:
      return R.pipe(
        R.assoc(['tempCheckSignIn', 'form'], defaultTempCheckSignInForm)
      )(state);
    case Actions.TEMP_CHECK_SIGN_IN_ERROR:
      mixpanel.track(TEMP_CHECK_LOGIN_FAILED, { $email: currentUser?.email });
      return state;
    case Actions.UPDATE_FORGOT_PASSWORD_FIELD:
      return {
        ...state,
        forgotPasswordForm: {
          ...state.forgotPasswordForm,
          [action.field]: action.value,
        },
      };
    case Actions.UPDATE_RESET_PASSWORD_FIELD:
      return {
        ...state,
        resetPasswordForm: {
          ...state.resetPasswordForm,
          [action.field]: action.value,
        },
      };

    case StreamApiActions.CONNECTED:
      return {
        ...state,
        streamConnected: true,
      };

    case StreamApiActions.CLOSED:
      return {
        ...state,
        streamConnected: false,
      };

    case Actions.SWITCH_SITE:
      return R.assoc('currentSiteId', action.value, state);

    default:
      return state;
  }
};

export const isSignedIn = (state: App) => {
  return !!state.accessToken;
};

export default reducer;
