import { useCallback, useEffect, useState } from 'react';

const CHECK_EVENTS = ['click', 'scroll', 'keypress'];

export const useIdleTimer = (
  timeLimit: number
): {
  isTimeout: boolean;
  resetTimer: (event?: any) => void;
} => {
  const [startTimerAt, setStartTimerAt] = useState(Date.now());
  const [isTimeout, setIsTimeout] = useState(false);

  const resetTimer = useCallback((event?: any) => {
    setIsTimeout(false);
    setStartTimerAt(Date.now());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    CHECK_EVENTS.forEach((e) =>
      document.addEventListener(e, (event) => resetTimer(event))
    );

    return () =>
      CHECK_EVENTS.forEach((e) =>
        document.removeEventListener(e, (event) => resetTimer(event))
      );
  }, [resetTimer]);

  useEffect(() => {
    const id = setTimeout(() => {
      setIsTimeout(true);
    }, timeLimit);
    return clearTimeout.bind(null, id);
  }, [startTimerAt, timeLimit]);

  return {
    isTimeout,
    resetTimer,
  };
};
