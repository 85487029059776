import {
  Organization,
  ResourceType
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
  values
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/organizations';

export type OrganizationById = {
  [id: string]: Organization;
};

export type OrganizationResources = {
  organizationId: number;
  sites: number;
  spaces: number;
  equipment: number;
  gateways: number;
  sensors: number;
  users: number;
  groups: number;
};

export interface Organizations {
  organizationsById: OrganizationById;
  currentOrganization?: Organization;
  organizationResources?: OrganizationResources;
}

const organizationFromApiResponse = (data: any) => ({
  id: data.id,
  title: data.title,
  description: data.description,
  createdAt: data.createdAt,
  resourceType: ResourceType[(data._entity as string).toUpperCase()]
});

const organizationResourcesFromApiResponse = (data: any) => ({
  organizationId: data.organizationId,
  sites: data.sites,
  spaces: data.spaces,
  equipment: data.equipment,
  gateways: data.gateways,
  sensors: data.sensors,
  users: data.users,
  groups: data.groups
});

const initialState = {
  organizationsById: {}
};

export const organizations = (
  state: Organizations = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.GET_ORGANIZATIONS_SUCCESS:
      return R.assoc(
        'organizationsById',
        mapArrayToObject(mapValues(action.data, organizationFromApiResponse)),
        state
      );

    case Actions.GET_CURRENT_ORGANIZATION_SUCCESS:
      let organization = organizationFromApiResponse(action.data);
      return R.assoc('currentOrganization', organization, state);

    case Actions.GET_ORGANIZATION_RESOURCES_SUCCESS:
      let organizationResource = organizationResourcesFromApiResponse(
        action.data
      );
      return R.assoc('organizationResources', organizationResource, state);

    default:
      return state;
  }
};

export const sortedOrganizations = (organizationsById: OrganizationById) =>
  values(organizationsById).sort((a: Organization, b: Organization) =>
    a.title.localeCompare(b.title)
  );

export default organizations;
