const apiBase = '/api/v1/report';

export enum Actions {
  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS',
  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR',
  GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING = '@reports/GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING',

  UPDATE_TEMP_CHECK_STATUS = '@report/UPDATE_TEMP_CHECK_STATUS',
}

export const getSiteEquipmentRecentTempReport = (siteId: number | string) => ({
  type: 'API_GET',
  path: `${apiBase}/generate/recent-temp/site/${siteId}`,
  success: {
    type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_SUCCESS,
    siteId,
  },
  error: { type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_ERROR, siteId },
  loading: {
    type: Actions.GET_SITE_EQUIPMENT_RECENT_TEMP_REPORT_LOADING,
    siteId,
  },
});

export const updateTempCheckStatus = (uuid: string, isDone: boolean) => ({
  type: Actions.UPDATE_TEMP_CHECK_STATUS,
  uuid: uuid,
  value: isDone,
});
