import {
  NewCommentForm,
  Modal,
  ModalContent,
  NewCommentFormProps,
  Button,
} from '@energybox/react-ui-library/dist/components';
import React, { useEffect } from 'react';
import translate from '../../../translations';
import styles from './NewCommentModal.module.css';
import ModalFooter from '../ModalFooter/ModalFooter';
import ModalTitle from '../ModalTitle/ModalTitle';

interface Props extends NewCommentFormProps {
  onClose: () => void;
  onNext: () => void;
  onOpenCommentHistory: () => void;
  isLoading?: boolean;
  clear: () => void;
}

const NewCommentModal: React.FC<Props> = (props) => {
  const {
    onClose,
    onNext,
    onOpenCommentHistory,
    isLoading,
    clear,
    ...formProps
  } = props;

  useEffect(() => {
    return () => {
      clear();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal className={styles.modalRoot}>
      <ModalTitle className={styles.modalTitle}>
        {translate.commment.newHeader}
        <Button
          onClick={onOpenCommentHistory}
          disabled={isLoading}
          variant={'text'}
          className={styles.textButton}
        >
          {translate.button.commentHistory}
        </Button>
      </ModalTitle>

      <ModalContent className={styles.modalContent}>
        <NewCommentForm {...formProps} disabled={isLoading} />
      </ModalContent>

      <ModalFooter className={styles.modalFooter}>
        <Button onClick={onClose} disabled={isLoading} variant={'text'}>
          {translate.button.cancel}
        </Button>

        <Button onClick={onNext} disabled={isLoading} isLoading={isLoading}>
          {translate.button.add}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default NewCommentModal;
