import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getEquipmentsBySiteId } from '../actions/equipment';
import equals from 'ramda/src/equals';

import { ApplicationState } from '../reducers';
import { EquipmentById } from '../reducers/equipment';

const useGetEquipmentsBySiteId = (siteId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getEquipmentsBySiteId(siteId));
    }
  }, [dispatch, siteId]);

  const equipmentIds = useSelector<ApplicationState, number[] | undefined>(
    ({ equipment }) => {
      if (!siteId) return undefined;
      return equipment.equipmentIdsBySiteId[siteId];
    },
    equals
  );

  const equipmentById = useSelector<ApplicationState, EquipmentById>(
    ({ equipment }) => {
      return equipment.equipmentById;
    },
    equals
  );

  return { equipmentIds, equipmentById };
};

export default useGetEquipmentsBySiteId;
