import {
  Equipment,
  IdListMapping,
  ResourceType,
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues,
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/equipment';

export type EquipmentById = {
  [id: string]: Equipment;
};

export type EquipmentIdByParentId = {
  [id: string]: number[];
};

export type EquipmentByEquipmentTypeId = {
  [equipmentTypeId: string]: Equipment[];
};

export type EquipmentByResourceId = {
  [id: string]: Equipment[];
};

export interface Equipments {
  equipmentById: EquipmentById;
  equipmentIdsBySiteId: IdListMapping;
  isEquipmentLoading: boolean;
}

export const equipmentsFromApiResponse = (data: any): Equipment => ({
  id: data.id,
  title: data.title,
  spaceId: data.spaceId || undefined,
  space: data.space,
  description: data.description || '',
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  type: data.type,
  typeId: data.typeId,
  groupId: data.groupId,
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  model: data.model || '',
  vendor: data.vendor || '',
  activeControl: data.activeControl,
  manufactureDate: data.manufactureDate,
  serialNumber: data.serialNumber,
});

export const initialState = {
  equipmentById: {},
  equipmentIdsBySiteId: {},
  isEquipmentLoading: false,
};

const equipments = (state: Equipments = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_EQUIPMENT_BY_SITE_ID_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['equipmentIdsBySiteId', [action.siteId]],
          action.data.map(({ id }) => id)
        ),
        R.assoc(
          'equipmentById',
          R.mergeRight(
            R.view(R.lensProp('equipmentById'), state),
            mapArrayToObject(mapValues(action.data, equipmentsFromApiResponse))
          )
        ),
        R.assoc('isEquipmentLoading', false)
      )(state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_LOADING:
      return R.assoc('isEquipmentLoading', true, state);

    case Actions.GET_EQUIPMENT_BY_SITE_ID_ERROR:
      return R.assoc('isEquipmentLoading', false, state);

    default:
      return state;
  }
};

export default equipments;
