import { Alert } from './reducers/ui';
import translate from './translations';

export const TIMEOUTS = {
  IDLE_TIMEOUT: 5 * 60 * 1000, // two minutes
};

export const ALERT: {
  [key in string]: Alert;
} = {
  tempCheck: {
    message: translate.tempCheck.alert.incompleteAlert,
    type: 'warning',
  },
};

export const LOCALSTORAGE_KEY = {
  token: 'authToken',
  tempCheckToken: 'tempCheckToken',
  alert: 'alert',
};
