import {
  Equipment,
  NewCommentFields,
  ValueType,
} from '@energybox/react-ui-library/dist/types';
import React, { useState } from 'react';
import { AddCommentButton } from '../../components/Button';
import useAppLocale from '../../hooks/useAppLocale';
import { useNewComment } from '../../hooks/useComment';
import { useCurrentTempCheckUser } from '../../hooks/useCurrentUser';
import NewCommentModal from '../../components/Modal/NewCommentModal/NewCommentModal';
import CommentHistoryModal from '../../components/Modal/CommentHistoryModal/CommentHistoryModal';

interface Props {
  sensorId: number;
  equipment?: Equipment;
  valueType: ValueType;
  value?: NewCommentFields['value'];
  timestamp?: number;
  disabled?: boolean;
  uuid: string;
}

const NewCommentContainer: React.FC<Props> = (props) => {
  const {
    equipment,
    sensorId,
    valueType,
    value,
    timestamp,
    disabled,
    uuid,
  } = props;

  const [isModalOpen, setIsOpen] = useState(false);
  const [isCommentHistoryModalOpen, setIsCommentHistoryModalOpen] = useState(
    false
  );

  const locale = useAppLocale();
  const {
    fields,
    onChangeField,
    isLoading,
    create,
    error,
    clear,
  } = useNewComment({
    resourceId: sensorId,
    valueType,
    value: value || '',
    timestamp: timestamp || 0,
    uuid,
  });

  const currentUser = useCurrentTempCheckUser();

  // const onOpenCommentHistory = () => {
  //   setIsCommentHistoryModalOpen(true);
  //   setIsOpen(false);
  // }

  const createComment = () => {
    create((resp: any) => {
      if (!resp.error) setIsOpen(false);
    });
  };

  return (
    <>
      <AddCommentButton onClick={() => setIsOpen(true)} disabled={disabled} />

      {isModalOpen && equipment && currentUser && (
        <NewCommentModal
          onClose={() => setIsOpen(false)}
          clear={clear}
          onNext={createComment}
          onOpenCommentHistory={() => setIsCommentHistoryModalOpen(true)}
          isLoading={isLoading}
          form={fields}
          onChange={onChangeField}
          locale={locale}
          resourceTitle={equipment.title}
          resourceType={valueType}
          currentUser={currentUser}
          error={error}
        />
      )}

      {isCommentHistoryModalOpen && (
        <CommentHistoryModal
          equipmentId={equipment?.id}
          spaceId={equipment?.spaceId}
          onClose={() => setIsCommentHistoryModalOpen(false)}
        />
      )}
    </>
  );
};

export default NewCommentContainer;
