import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';
import { TempCheckEquipment } from '../reducers/reports';
import { pluck, includes } from 'ramda';
import { useCallback } from 'react';
import { logoutTempCheck } from '../actions/app';
import { isDefined } from '@energybox/react-ui-library/dist/utils';

export const useTempCheck = (): {
  isAuthenticated: boolean;
  logout: () => void;
  isComplete: () => boolean;
} => {
  const dispatch = useDispatch();
  const tempCheckToken = useSelector<ApplicationState, string | undefined>(
    ({ app }: ApplicationState) => {
      return app.tempCheckToken;
    }
  );

  const logout = useCallback(() => {
    dispatch(logoutTempCheck());
  }, [dispatch]);

  const tempCheckEquipList = useSelector<
    ApplicationState,
    TempCheckEquipment[]
  >(({ reports }) => reports.tempCheckEquipmentList);

  const isComplete = useCallback(() => {
    const filtered = tempCheckEquipList.filter(
      (e) =>
        isDefined(e.equipment) &&
        isDefined(e.timestamp) &&
        isDefined(e.humidity || isDefined(e.temperature))
    );
    const isDoneList = pluck('doneTempCheck', filtered);
    return !includes(false, isDoneList);
  }, [tempCheckEquipList]);

  return {
    isAuthenticated: !!tempCheckToken,
    logout,
    isComplete,
  };
};
