import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPassword } from '../actions/app';
import { ApplicationState } from '../reducers';

export const useForgotPwd = (): {
  isLoading: boolean;
  isEmailSent: boolean;
  forgotPwd: (email: string) => void;
} => {
  const dispatch = useDispatch();
  const isLoading = useSelector<ApplicationState, boolean>(
    ({ app }) => app.isForgotPasswordLoading
  );

  const isEmailSent = useSelector<ApplicationState, boolean>(
    ({ app }) => app.isResetPasswordEmailSent
  );

  const forgotPwd = useCallback(
    (email: string) => {
      dispatch(forgotPassword(email));
    },
    [dispatch]
  );

  return {
    isLoading,
    isEmailSent,
    forgotPwd,
  };
};
