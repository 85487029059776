export const actionMatches = (action: string): string[] | null => {
  return /(.*)_(SUCCESS|LOADING|ERROR)/.exec(action);
};

export const appendKey = (actionName: string, key?: string) => {
  return `${actionName}${key ? `_${key}` : ''}`;
};

export const getActionName = (actionType: string, key?: string) => {
  const matches = actionMatches(actionType);
  if (!matches) return actionType;
  const [, requestPrefix] = matches;
  return appendKey(requestPrefix, key);
};
