import { hasKeys } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import { Actions as AppActions } from '../actions/app';

export type ApiError =
  | {}
  | {
      type: string;
      status: number;
      message: string;
    };

type Action = {
  type: string;
  data: ActionData;
};

type ActionData = {
  status: number;
  message: string;
  errors?: error[];
};

type error = {
  field: string;
  message: string;
};

type AllActions = AppActions;

export const storeAPIerror = (action: Action) => ({
  type: action.type,
  status: action.data.status,
  //TODO: will likely need to refactor below: backend is currently sending two forms of error messages: DATA.ERRORS (which is an array of object or sometimes returns null) and DATA.MESSAGE (string).
  //For DATA.ERRORS, even when there are multiple api errors (i.e. creating a user with an email that's already taken AND a password less than 6 characters) only one error is sent back...
  message:
    Array.isArray(action.data.errors) && action.data.errors.length > 0
      ? action.data.errors[0].message
      : action.data.message
});

export const renderAPIerror = (
  apiError: ApiError,
  ...errorTypes: AllActions[]
) => {
  if (hasKeys(apiError) && errorTypes.includes(apiError['type'])) {
    return (
      <div
        style={{
          color: 'var(--pink-base)',
          textAlign: 'center',
          fontSize: '0.75rem',
          whiteSpace: 'pre'
        }}
      >
        {apiError['message']}
      </div>
    );
  }
};
