import {
  IncidentPriority,
  SubscribedIncident
} from '@energybox/react-ui-library/dist/types';
import {
  isDefined,
  mapArrayToObject
} from '@energybox/react-ui-library/dist/utils';
import { isAfter, parseISO } from 'date-fns';
import * as R from 'ramda';

import { Actions as AppActions } from '../actions/app';
import { Actions as IncidentActions } from '../actions/incidents';
import {
  Actions as StreamActions,
  Channel,
  CommandType
} from '../actions/streamApi';
import { Actions } from '../actions/subscribedIncidents';
import { SubscriptionStatus } from '../types/subscription';
import { IncidentPriorityRating } from '../utils/subscribedIncidents';

const initialState = {
  byResourceId: {},
  alertIdentifiers: [],
  //featureResourceId is updated when a user clicks on the "show" button on a toast
  //which functionally directs the user to the relevant incident card
  featureResourceId: -1,
  status: SubscriptionStatus.INIT,
  isInitialLoading: true
};

export type SubscribedIncidents = {
  byResourceId: SubscribedIncidentsByResourceId;
  status: SubscriptionStatus;
  alertIdentifiers: AlertIdentifier[];
  featureResourceId: number;
  isInitialLoading: boolean;
};

export type AlertIdentifier = {
  resourceId: number | string;
  sentinelId: string;
  lastNotificationAt: string; //ISO date string
  incidentPriority: IncidentPriority;
};

export type SubscribedIncidentsByResourceId = {
  [resourceId: string]: SubscribedIncidentsBySentinelId;
};

export type SubscribedIncidentsBySentinelId = {
  [sentinelId: string]: SubscribedIncident;
};

const normalizeSubscribedIncident = (rawData): SubscribedIncident => ({
  createdAt: rawData.createdAt,
  equipmentId: rawData.equipmentId,
  spaceId: rawData.spaceId,
  incidentId: rawData.incidentId,
  incidentNotification: rawData.incidentNotification,
  incidentPriority: rawData.incidentPriority,
  notificationCount: rawData.notificationCount,
  recoveredAt: rawData.recoveredAt,
  resolved: rawData.resolved,
  sentinelId: rawData.sentinelId,
  dismissedAt: rawData.dismissedAt,

  //normalized values
  siteId: rawData.resourcePath?.sites?.[0]?.siteId,
  sensorId: rawData.resourcePath?.sensors?.[0]?.sensorId
});

const normalizeExistingIncident = (rawData): SubscribedIncident => ({
  createdAt: rawData.createdAt,
  equipmentId: rawData.equipmentId,
  incidentId: rawData.incidentId,
  incidentNotification: rawData.notifications,
  incidentPriority: rawData.incidentPriority,
  notificationCount: rawData.notificationCount,
  recoveredAt: rawData.recoveredAt,
  resolved: rawData.resolved,
  sentinelId: rawData.sentinelId,
  dismissedAt: rawData.dismissedAt,

  //normalized values
  siteId: rawData?.siteIds?.[0],
  spaceId: rawData?.spaceIds?.[0],
  sensorId: rawData.notifications?.sensorParams?.[0]?.sensorId
});

const getLatestNotificationAt = (incident: SubscribedIncident): string => {
  if (incident.incidentNotification.length === 0) return '';

  return incident.incidentNotification[incident.incidentNotification.length - 1]
    .at;
};

const subscribedIncidents = (
  state: SubscribedIncidents = initialState,
  action: any
) => {
  switch (action.type) {
    case Actions.DISMISS_INCIDENT_ALERT: {
      const updatedAlertIdentifiers = state.alertIdentifiers.filter(
        (alertIdentifier) =>
          +alertIdentifier.resourceId !== +action.resourceId &&
          +alertIdentifier.sentinelId !== +action.sentinelId
      );

      return R.assoc('alertIdentifiers', updatedAlertIdentifiers, state);
    }

    case Actions.SET_FEATURE_RESOURCE_ID: {
      return R.assoc('featureResourceId', action.resourceId, state);
    }

    case Actions.RESET_FEATURE_RESOURCE_ID: {
      return R.assoc('featureResourceId', -1, state);
    }

    case StreamActions.RECEIVED_SUBSCRIBED_INCIDENT: {
      const existingIncidentsByResourceId = { ...state.byResourceId };
      const normalizedIncident = normalizeSubscribedIncident(action.data);
      const {
        possibleAlerts,
        incidentsByResourceId
      } = processNormalizedIncidents(
        [normalizedIncident],
        existingIncidentsByResourceId,
        state.isInitialLoading
      );

      return R.pipe(
        R.assoc('status', SubscriptionStatus.SUCCESS),
        R.assocPath(['byResourceId'], incidentsByResourceId),
        R.assoc(
          'alertIdentifiers',
          updateAlertIdentifiers(state.alertIdentifiers, possibleAlerts)
        )
      )(state);
    }

    case IncidentActions.GET_INCIDENTS_SUCCESS: {
      const existingIncidentsByResourceId = { ...state.byResourceId };
      const normalizedIncidents: SubscribedIncident[] = action.data.map(
        (incident) => normalizeExistingIncident(incident)
      );
      const {
        possibleAlerts,
        incidentsByResourceId
      } = processNormalizedIncidents(
        normalizedIncidents,
        existingIncidentsByResourceId,
        state.isInitialLoading
      );

      return R.pipe(
        R.assoc('status', SubscriptionStatus.SUCCESS),
        R.assocPath(['byResourceId'], incidentsByResourceId),
        R.assoc(
          'alertIdentifiers',
          updateAlertIdentifiers(state.alertIdentifiers, possibleAlerts)
        )
      )(state);
    }

    case Actions.SET_INITIAL_PAGE_LOADING: {
      return R.assoc('isInitialLoading', action.value, state);
    }

    case StreamActions.SEND_MESSAGE: {
      if (
        action.data.channel === Channel.INCIDENT_INFO &&
        action.data.type === CommandType.SUBSCRIBE
      ) {
        return R.assoc('status', SubscriptionStatus.LOADING, state);
      }
      return state;
    }

    case AppActions.SWITCH_SITE: {
      return R.pipe(
        R.assoc('byResourceId', {}),
        R.assoc('alertIdentifiers', [])
      )(state);
    }

    default:
      return state;
  }
};

const getResourceId = (normalizedIncident: SubscribedIncident) =>
  normalizedIncident.equipmentId || normalizedIncident.spaceId || '';

const processNormalizedIncidents = (
  normalizedIncidents: SubscribedIncident[],
  existingIncidentsByResourceId: {
    [x: string]: SubscribedIncidentsBySentinelId;
  },
  isLoading: boolean
) => {
  const possibleAlerts: AlertIdentifier[] = [];
  const incidentsByResourceId = { ...existingIncidentsByResourceId };

  normalizedIncidents.forEach((normalizedIncident) => {
    const resourceId = getResourceId(normalizedIncident);
    const {
      sentinelId,
      dismissedAt,
      resolved,
      incidentNotification,
      incidentPriority
    } = normalizedIncident;

    const isIncidentNotificationsValid = incidentNotification.length > 0;
    const isIncidentPriorityValid = !!incidentPriority;
    const isIncidentValid =
      !!resourceId && isIncidentNotificationsValid && isIncidentPriorityValid;

    if (isIncidentValid) {
      if (incidentsByResourceId[resourceId] === undefined) {
        incidentsByResourceId[resourceId] = {};
      }
      incidentsByResourceId[resourceId][sentinelId] = normalizedIncident;

      // in the initial load of app,
      // do not add resolved or dismissed incidents in the alert ticker
      const shouldNotAddIncident = isLoading && (dismissedAt || resolved);
      const newAlertIdentifier: AlertIdentifier = {
        resourceId,
        sentinelId,
        lastNotificationAt: getLatestNotificationAt(normalizedIncident),
        incidentPriority: normalizedIncident.incidentPriority
      };
      if (!shouldNotAddIncident) {
        possibleAlerts.push(newAlertIdentifier);
      }
    }
  });

  return { possibleAlerts, incidentsByResourceId };
};

const updateAlertIdentifiers = (
  previousAlerts: AlertIdentifier[],
  possibleAlerts: AlertIdentifier[]
) => {
  if (possibleAlerts.length === 0) return previousAlerts;

  // How this key is constructed will determine how the toast alerts are shown.
  // I.e. with a key `${alert.resourceId}-${alert.sentinelId}` you will get
  // multiple toasts per equipment if there are multiple sentinels. Harsh
  // requested we only show one per resource - picking the latest if there are
  // multiple, hence the `${alert.resourceId}`
  const constructAlertKey = (alert: AlertIdentifier) => `${alert.resourceId}`;

  const updatedAlertIdentifiers: {
    [key: string]: AlertIdentifier;
  } = mapArrayToObject(
    previousAlerts.map((i) => ({
      ...i,
      key: constructAlertKey(i)
    })),
    'key'
  );
  possibleAlerts.forEach((possibleAlert) => {
    const possibleAlertKey = constructAlertKey(possibleAlert);
    if (!isDefined(updatedAlertIdentifiers[possibleAlertKey])) {
      updatedAlertIdentifiers[possibleAlertKey] = possibleAlert;
    } else {
      const currentAlertPriority =
        updatedAlertIdentifiers[possibleAlertKey].incidentPriority;
      const possibleAlertPriority = possibleAlert.incidentPriority;
      const currentAlertTimestamp =
        updatedAlertIdentifiers[possibleAlertKey].lastNotificationAt;
      const possibleAlertTimestamp = possibleAlert.lastNotificationAt;
      // If multiple incidents for same key keep the one that is highest priority. If
      // same keep the newest
      if (
        IncidentPriorityRating[possibleAlertPriority] >
        IncidentPriorityRating[currentAlertPriority]
      ) {
        updatedAlertIdentifiers[possibleAlertKey] = possibleAlert;
      } else if (
        IncidentPriorityRating[possibleAlertPriority] ===
          IncidentPriorityRating[currentAlertPriority] &&
        isAfter(
          parseISO(possibleAlertTimestamp),
          parseISO(currentAlertTimestamp)
        )
      ) {
        updatedAlertIdentifiers[possibleAlertKey] = possibleAlert;
      }
    }
  });

  return Object.values(updatedAlertIdentifiers);
};

export default subscribedIncidents;
