export enum EnergyboxService {
  sentinels = 'sentinels',
  iam = 'iam',
  timeSeries = 'timeSeries',
  weather = 'weather',
  comments = 'comments',
}

export const getServiceUrl = (service?: EnergyboxService) => {
  switch (service) {
    case EnergyboxService.sentinels:
      return process.env.REACT_APP_SERVICE_SENTINEL_BASE_URL;
    case EnergyboxService.timeSeries:
      return process.env.REACT_APP_SERVICE_TSD_BASE_URL;
    case EnergyboxService.weather:
      return process.env.REACT_APP_SERVICE_WEATHER_BASE_URL;
    case EnergyboxService.comments:
      return process.env.REACT_APP_SERVICE_COMMENTS_ROOT;
    case EnergyboxService.iam:
    default:
      return process.env.REACT_APP_API_BASE_URL;
  }
};
