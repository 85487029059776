import {
  IncidentPriority,
  SubscribedIncident
} from '@energybox/react-ui-library/dist/types';
import { deriveDateValue } from '@energybox/react-ui-library/dist/utils';
import { EquipmentById } from '../reducers/equipment';
import { SpacesById } from '../reducers/spaces';

export const IncidentPriorityRating = {
  [IncidentPriority.NORMAL]: 1,
  [IncidentPriority.MEDIUM]: 2,
  [IncidentPriority.HIGH]: 3,
  [IncidentPriority.CRITICAL]: 4,
  [IncidentPriority.HIGHEST]: 4
};

const sortByPriority = (
  a: SubscribedIncident,
  b: SubscribedIncident
): number => {
  const aPrio = IncidentPriorityRating[a.incidentPriority] || 0;
  const bPrio = IncidentPriorityRating[b.incidentPriority] || 0;

  return bPrio - aPrio;
};

export const sortByDateDescending = (
  a: SubscribedIncident,
  b: SubscribedIncident
): number => {
  return deriveDateValue(a.createdAt) - deriveDateValue(b.createdAt);
};

export const sortByPriorityDateDescending = (
  a: SubscribedIncident,
  b: SubscribedIncident
): number => {
  return sortByPriority(a, b) === 0
    ? sortByDateDescending(a, b)
    : sortByPriority(a, b);
};

//sort dismissed incidents to be last
export const sortByDismissed = (
  a: SubscribedIncident,
  b: SubscribedIncident
) => {
  const isIncidentADimissed = !!a.dismissedAt;
  const isIncidentBDimissed = !!b.dismissedAt;

  if (isIncidentADimissed && !isIncidentBDimissed) return 1;
  if (!isIncidentADimissed && isIncidentBDimissed) return -1;
  return 0;
};

//sort resolved incidents to be last
export const sortByResolved = (
  a: SubscribedIncident,
  b: SubscribedIncident
) => {
  const isIncidentAResolved = !!a.resolved;
  const isIncidentBResolved = !!b.resolved;

  if (isIncidentAResolved && !isIncidentBResolved) return 1;
  if (!isIncidentAResolved && isIncidentBResolved) return -1;
  return 0;
};

export const determineResource = (
  incident: SubscribedIncident,
  equipmentById: EquipmentById,
  spacesById: SpacesById
) => {
  const spaceId = incident.spaceId;
  const equipmentId = incident.equipmentId;

  if (equipmentId) {
    return equipmentById[equipmentId];
  } else if (spaceId) {
    return spacesById[spaceId];
  } else return undefined;
};
