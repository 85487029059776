import { Actions as StreamActions, CommandType } from '../actions/streamApi';

type StoredSubData = {
  vendor: string;
  uuid: string;
  id?: number | string;
};

export type Subscriptions = {
  subscriptions: StoredSubData[];
};

const initialState = {
  subscriptions: []
};
const subscriptions = (state: Subscriptions = initialState, action: any) => {
  switch (action.type) {
    case StreamActions.SEND_MESSAGE: {
      if (action.data.type === CommandType.SUBSCRIBE) {
        const { vendor, uuid, id } = action.data;
        const storedData = {
          vendor,
          uuid,
          id
        };

        return {
          ...state,
          subscriptions: [...state.subscriptions, storedData]
        };
      } else if (action.data.type === CommandType.UNSUBSCRIBE) {
        const subIndex = state.subscriptions.findIndex((s: StoredSubData) => {
          return s.uuid === action.data.uuid;
        });

        if (subIndex === -1) return state;

        return {
          ...state,
          subscriptions: [
            ...state.subscriptions.slice(0, subIndex),
            ...state.subscriptions.slice(subIndex + 1)
          ]
        };
      }

      return state;
    }
    default:
      return state;
  }
};

export default subscriptions;
