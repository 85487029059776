import { KioskCard } from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';
// import { ChevronRight as ChevronRightIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import styles from './WidgetCard.module.css';

type Props = {
  title: string;
  icon: React.ReactNode;
  onClick: () => void;
  className?: string;
};

const WidgetCard: React.FC<Props> = (props) => {
  const { className, title, icon, onClick } = props;

  return (
    <KioskCard className={classNames(styles.color, className)}>
      <div onClick={onClick} className={styles.root}>
        <div className={styles.icon}>{icon}</div>
        <div className={styles.textContainer}>
          <div className={styles.textContainerTitle}>{title}</div>
          {/* <div className={styles.chevron}>
            <ChevronRightIcon size={24} />
          </div> */}
        </div>
      </div>
    </KioskCard>
  );
};

export default WidgetCard;
