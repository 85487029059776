import { combineReducers } from 'redux';
import { connectRouter, RouterState } from 'connected-react-router';
import store from '../store';

import history from '../history';
import { Actions as AppActions } from '../actions/app';

import app, { App } from './app';
import { Organizations, organizations } from './organizations';
import { users, Users } from './users';
import sites, { Sites } from './sites';
import spaces, { Spaces } from './spaces';
import equipments, { Equipments } from './equipment';
import comments, { Comments } from './comments';
import sensors, { Sensors } from './sensors';
import resourcePaths, { ResourcePaths } from './paths';
import subscriptions, { Subscriptions } from './subscriptions';
import subscribedIncidents, {
  SubscribedIncidents,
} from './subscribedIncidents';
import weather, { Weather } from './weather';
import reports, { Reports } from './reports';
import notifications, { Notifications } from './notifications';
import sops, { Sops } from './sops';
import ui, { UI } from './ui';

export type Dispatch = typeof store.dispatch;

export interface ApplicationState {
  router: RouterState;
  ui: UI;
  reports: Reports;
  app: App;
  organizations: Organizations;
  users: Users;
  sites: Sites;
  spaces: Spaces;
  subscriptions: Subscriptions;
  equipment: Equipments;
  resourcePaths: ResourcePaths;
  sensors: Sensors;
  subscribedIncidents: SubscribedIncidents;
  weather: Weather;
  notifications: Notifications;
  sops: Sops;
  comments: Comments;
}

const appReducer = combineReducers<ApplicationState>({
  router: connectRouter(history),
  ui,
  reports,
  app,
  organizations,
  users,
  sites,
  spaces,
  subscriptions,
  equipment: equipments,
  resourcePaths,
  sensors,
  subscribedIncidents,
  weather,
  notifications,
  sops,
  comments,
});

const rootReducer = (state, action) => {
  if (action.type === AppActions.LOGOUT) {
    state = undefined;
    return appReducer(state, { type: AppActions.INIT });
  }
  return appReducer(state, action);
};

export default rootReducer;
