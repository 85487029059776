import React from 'react';

const Temperature = (
  <svg
    width="18"
    height="25"
    viewBox="0 0 18 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_82_3737)">
      <path
        d="M14.6733 0.793147C15.0223 0.793147 15.3633 0.888325 15.6647 1.06282C16.6085 1.61009 16.9337 2.8236 16.3944 3.77538L7.76495 18.7103L7.5746 19.0355L7.7015 19.3845C8.07428 20.3839 7.98704 21.4943 7.44769 22.4143C6.81318 23.5168 5.62346 24.1989 4.35442 24.1989C3.73576 24.1989 3.11711 24.0324 2.57777 23.723C1.7529 23.2471 1.15804 22.4778 0.91216 21.5577C0.666285 20.6377 0.793188 19.678 1.26908 18.8531C1.80049 17.9251 2.7126 17.2985 3.76749 17.1161L4.13234 17.0527L4.32269 16.7275L12.9601 1.78458C13.317 1.17386 13.9753 0.793147 14.6812 0.793147H14.6733ZM14.6812 0C13.7215 0 12.7856 0.499683 12.278 1.38801L3.64059 16.3309C2.40328 16.5371 1.26115 17.2827 0.58697 18.4486C-0.618614 20.5346 0.0952187 23.1996 2.1812 24.4051C2.87123 24.8017 3.61679 24.9921 4.35442 24.9921C5.8614 24.9921 7.32872 24.2069 8.13773 22.8109C8.81191 21.6371 8.88329 20.2808 8.44706 19.1069L17.0765 4.16402C17.8459 2.83947 17.3858 1.14213 16.0613 0.372779C15.625 0.118972 15.1492 0 14.6733 0L14.6812 0Z"
        fill="#45555F"
      />
      <path
        d="M11.3579 7.73315L4.61612 19.4083L5.29823 19.8049L12.04 8.12973L11.3579 7.73315Z"
        fill="#45555F"
      />
      <path
        d="M4.36231 19.4479C4.56852 19.4479 4.77474 19.5035 4.95717 19.6066C5.52823 19.9318 5.71859 20.6614 5.3934 21.2325C5.17925 21.5974 4.78267 21.8274 4.36231 21.8274C4.15609 21.8274 3.94987 21.7719 3.76745 21.6687C3.48984 21.5101 3.29156 21.2563 3.21224 20.947C3.13293 20.6377 3.17259 20.3204 3.33121 20.0428C3.54536 19.6779 3.94194 19.4479 4.36231 19.4479ZM4.36231 18.6548C3.6802 18.6548 3.01396 19.0117 2.64118 19.6462C2.09391 20.598 2.4191 21.8036 3.37087 22.3588C3.6802 22.5412 4.02125 22.6285 4.36231 22.6285C5.04441 22.6285 5.71066 22.2715 6.08344 21.637C6.63071 20.6852 6.30552 19.4797 5.35374 18.9245C5.04441 18.742 4.70336 18.6548 4.36231 18.6548Z"
        fill="#45555F"
      />
      <path
        d="M6.23258 10.2654L4.85881 9.47229L4.46224 10.1592L5.83601 10.9523L6.23258 10.2654Z"
        fill="#45555F"
      />
      <path
        d="M4.64674 13.0123L5.04331 12.3254L3.66954 11.5323L3.27297 12.2192L4.64674 13.0123Z"
        fill="#45555F"
      />
      <path
        d="M7.41962 8.20251L4.67208 6.61621L4.27551 7.3031L7.02305 8.88939L7.41962 8.20251Z"
        fill="#45555F"
      />
      <path
        d="M9.80342 4.08184L8.42965 3.2887L8.03308 3.97558L9.40685 4.76873L9.80342 4.08184Z"
        fill="#45555F"
      />
      <path
        d="M8.213 6.83545L8.60957 6.14856L7.2358 5.35541L6.83923 6.0423L8.213 6.83545Z"
        fill="#45555F"
      />
      <path
        d="M10.9865 2.01989L8.23895 0.433594L7.84238 1.12048L10.5899 2.70677L10.9865 2.01989Z"
        fill="#45555F"
      />
      <path
        d="M10.9137 22.4144C10.8661 22.4144 10.8265 22.4144 10.7789 22.3906C10.5727 22.3192 10.4695 22.0892 10.5409 21.8829C10.652 21.5815 10.7075 21.2564 10.7075 20.9312C10.7075 20.606 10.652 20.2887 10.5409 19.9794C10.4695 19.7732 10.5727 19.5432 10.7789 19.4718C10.9851 19.4004 11.2151 19.5035 11.2865 19.7097C11.4293 20.0984 11.5006 20.5108 11.5006 20.9312C11.5006 21.3515 11.4293 21.756 11.2865 22.1526C11.231 22.3112 11.0723 22.4144 10.9137 22.4144V22.4144Z"
        fill="#45555F"
      />
      <path
        d="M13.1504 23.2312C13.1028 23.2312 13.0631 23.2312 13.0155 23.2074C12.8093 23.136 12.7062 22.906 12.7776 22.6998C12.9838 22.1287 13.0869 21.5418 13.0869 20.939C13.0869 20.3362 12.9838 19.7414 12.7776 19.1782C12.7062 18.972 12.8093 18.742 13.0155 18.6706C13.2218 18.5992 13.4518 18.7024 13.5232 18.9086C13.7611 19.5669 13.8801 20.249 13.8801 20.939C13.8801 21.6291 13.7611 22.3191 13.5232 22.9774C13.4676 23.136 13.309 23.2392 13.1504 23.2392V23.2312Z"
        fill="#45555F"
      />
    </g>
    <defs>
      <clipPath id="clip0_82_3737">
        <rect width="17.4492" height="25" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Temperature;
