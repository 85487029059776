import {
  Button,
  InputField,
} from '@energybox/react-ui-library/dist/components';
import { UsersById } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { TempCheckSignInForm } from '../../../reducers/app';
import UserSelector from '../UserSelector/UserSelector';
import styles from './SignInTempCheckForm.module.css';

export interface SignInFormProps {
  users: UsersById;
  form: TempCheckSignInForm;
  onChange: <K extends keyof TempCheckSignInForm>(
    field: K,
    value: TempCheckSignInForm[K]
  ) => void;
  onClickForgotPwd: () => void;
  apiError?: React.ReactNode;
  disabled?: boolean;
  isForgotPwdLoading?: boolean;
}

const SignInTempCheckForm: React.FC<SignInFormProps> = (props) => {
  const {
    users,
    form,
    onChange,
    onClickForgotPwd,
    apiError,
    disabled,
    isForgotPwdLoading,
  } = props;

  return (
    <div className={styles.formContainer}>
      <div>
        <label className={styles.label}>Select Name</label>
        <UserSelector
          users={users}
          selectedUserId={form.userId}
          setSelectedUserId={(id) => onChange('userId', id)}
          disabled={disabled}
        />
      </div>

      <div>
        <label className={styles.label}>Password</label>
        <InputField
          type={'text'}
          name="password"
          value={form.password}
          placeholder="Password"
          onChange={(e) => onChange('password', e.currentTarget.value)}
          disabled={disabled}
          canHide
        />
      </div>

      {!!apiError && apiError}

      <div className={styles.flexCenter}>
        <Button
          variant="text"
          onClick={onClickForgotPwd}
          disabled={!form.userId}
          isLoading={isForgotPwdLoading}
        >
          Forgot your password?
        </Button>
      </div>
    </div>
  );
};

export default SignInTempCheckForm;
