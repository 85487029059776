import React from 'react';
import { AddCommentIcon } from '@energybox/react-ui-library/dist/icons';
import {
  Button,
  ButtonProps,
} from '@energybox/react-ui-library/dist/components';
import styles from '../button.module.css';

interface Props extends ButtonProps {}

const AddCommentButton: React.FC<Props> = (props) => {
  return (
    <Button
      roundedCorners
      variant="outlined"
      className={styles.iconButton}
      {...props}
    >
      <AddCommentIcon size={25} />
    </Button>
  );
};

export default AddCommentButton;
