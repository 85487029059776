import { Button } from '@energybox/react-ui-library/dist/components';
import { ListCheckIcon } from '@energybox/react-ui-library/dist/icons';
import React from 'react';
import styles from './TempCheckActionButton.module.css';

export enum Action {
  'PERFORM',
  'FINISHED',
}

const tempCheckLabelMapper: { [key in Action]?: string } = {
  [Action.PERFORM]: 'Perform \nTemp Check',
  [Action.FINISHED]: 'Finished \nTemp Check',
};

interface Props {
  action: Action;
  onClick: () => void;
}

const TempCheckActionButton: React.FC<Props> = (props) => {
  const { action, onClick } = props;

  return (
    <>
      <Button
        roundedCorners
        onClick={onClick}
        className={styles.buttonContainer}
      >
        <ListCheckIcon size={25} />
        {tempCheckLabelMapper[action]}
      </Button>
    </>
  );
};

export default TempCheckActionButton;
