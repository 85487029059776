import { NewPasswordPage } from '@energybox/react-ui-library/dist/components';
import React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
  Actions as AppActions,
  resetPassword,
  updateResetPasswordField
} from '../../actions/app';
import { ApplicationState } from '../../reducers';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';

interface Props extends RouteComponentProps {
  email: string;
  password: string;
  passwordVerify: string;
  apiError: ApiError;
  resetPassword: typeof resetPassword;
  onChange: (field: string, value: string) => void;
}

class NewPasswordPageContainer extends React.Component<Props> {
  validateForm = () => {
    const { password, passwordVerify } = this.props;
    return password !== '' && passwordVerify !== '';
  };

  handleSubmit = (e: any) => {
    const {
      resetPassword,
      email,
      password,
      passwordVerify,
      location
    } = this.props;
    const token = location.search.replace('?token=', '');

    e.preventDefault();
    resetPassword(email, token, password, passwordVerify);
  };

  render() {
    const { email, password, passwordVerify, onChange, apiError } = this.props;

    return (
      <NewPasswordPage
        email={email}
        password={password}
        passwordVerify={passwordVerify}
        apiError={renderAPIerror(apiError, AppActions.RESET_PASSWORD_ERROR)}
        validateForm={this.validateForm}
        handleSubmit={this.handleSubmit}
        handleChange={onChange}
        backgroundImage={`${process.env.PUBLIC_URL}/login_background.jpg`}
      />
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  email: app.resetPasswordForm.email,
  password: app.resetPasswordForm.password,
  passwordVerify: app.resetPasswordForm.passwordVerify,
  apiError: app.apiError
});

const mapDispatchToProps = (dispatch) => {
  return {
    onChange: (field: string, value: string) =>
      dispatch(updateResetPasswordField(field, value)),
    resetPassword: (
      email: string,
      token: string,
      password: string,
      passwordVerify: string
    ) => dispatch(resetPassword(email, token, password, passwordVerify))
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NewPasswordPageContainer);
