import { ValueType } from '@energybox/react-ui-library/dist/types';
import React from 'react';
import { TempCheckButton } from '../../components/Button';
import { useTempCheck } from '../../hooks/useComment';
import { useEquipment } from '../../hooks/useEquipment';
import NewCommentContainer from '../NewCommentContainer/NewCommentContainer';
import styles from './TempCheckContainer.module.css';

interface Props {
  equipmentId: number;
  sensorId: number;
  valueType: ValueType;
  value?: number;
  timestamp?: number;
  uuid: string;
}

const TempCheckContainer: React.FC<Props> = (props) => {
  const { equipmentId, sensorId, valueType, value, timestamp, uuid } = props;

  const equipment = useEquipment(equipmentId);

  const { isDone, check, isLoading } = useTempCheck(
    sensorId,
    valueType,
    value ?? 0,
    timestamp ?? 0,
    uuid
  );

  const buttonDisabled = !equipment || !value || !timestamp || isLoading;

  const onClickTempCheck = () => {
    if (isDone) return;
    check();
  };

  return (
    <>
      <div className={styles.buttonGroup}>
        <NewCommentContainer
          sensorId={sensorId}
          equipment={equipment}
          valueType={ValueType.TEMPERATURE}
          value={value}
          timestamp={timestamp}
          disabled={buttonDisabled}
          uuid={uuid}
        />
        <TempCheckButton
          onClick={onClickTempCheck}
          disabled={buttonDisabled}
          isDone={isDone}
          isLoading={isLoading}
        />
      </div>
    </>
  );
};

export default TempCheckContainer;
