import { Space } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export const useSpace = (resourceId: number) => {
  const space = useSelector<ApplicationState, Space | undefined>(
    ({ spaces }) => {
      return spaces.spacesById[String(resourceId)];
    }
  );

  return space;
};
