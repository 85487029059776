import React from 'react';
// import { withVariants } from '../util';

const Door = (
  <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.4844 21.875H18.3594C18.1436 21.875 17.9688 22.0499 17.9688 22.2656V22.2734C17.9688 22.4892 18.1436 22.6641 18.3594 22.6641H21.4844C21.7001 22.6641 21.875 22.4892 21.875 22.2734V22.2656C21.875 22.0499 21.7001 21.875 21.4844 21.875Z" fill="#45555F" />
    <path d="M24.6094 21.875H23.0469C22.8311 21.875 22.6562 22.0499 22.6562 22.2656V22.2734C22.6562 22.4892 22.8311 22.6641 23.0469 22.6641H24.6094C24.8251 22.6641 25 22.4892 25 22.2734V22.2656C25 22.0499 24.8251 21.875 24.6094 21.875Z" fill="#45555F" />
    <path d="M5.85938 21.875H0.390625C0.174889 21.875 0 22.0499 0 22.2656C0 22.4814 0.174889 22.6562 0.390625 22.6562H5.85938C6.07511 22.6562 6.25 22.4814 6.25 22.2656C6.25 22.0499 6.07511 21.875 5.85938 21.875Z" fill="#45555F" />
    <path d="M18.75 3.125V21.875H16.4062V3.125H18.75ZM19.5312 2.34375H15.625V22.6562H19.5312V2.34375Z" fill="#45555F" />
    <path d="M15.625 0.96875V24.0312L6.25 22.0234V2.97656L15.625 0.96875ZM16.4062 0L5.46875 2.34375V22.6562L16.4062 25V0Z" fill="#45555F" />
    <path d="M13.6719 12.8906C13.8906 12.8906 14.0625 13.0625 14.0625 13.2812C14.0625 13.5 13.8906 13.6719 13.6719 13.6719C13.4531 13.6719 13.2812 13.5 13.2812 13.2812C13.2812 13.0625 13.4531 12.8906 13.6719 12.8906ZM13.6719 12.1094C13.0234 12.1094 12.5 12.6328 12.5 13.2812C12.5 13.9297 13.0234 14.4531 13.6719 14.4531C14.3203 14.4531 14.8438 13.9297 14.8438 13.2812C14.8438 12.6328 14.3203 12.1094 13.6719 12.1094Z" fill="#45555F" />
    <path d="M11.2969 14.836C11.1406 14.836 10.9844 14.7344 10.9297 14.5625C10.7891 14.1485 10.7188 13.7188 10.7188 13.2813C10.7188 12.8438 10.7891 12.4141 10.9297 12C11 11.7813 11.2266 11.6719 11.4297 11.75C11.6328 11.8281 11.7344 12.0703 11.6641 12.2813C11.5547 12.6016 11.5 12.9375 11.5 13.2735C11.5 13.6094 11.5547 13.9453 11.6641 14.2657C11.7344 14.4844 11.6328 14.7188 11.4297 14.7969C11.3828 14.8125 11.3438 14.8203 11.2969 14.8203V14.836Z" fill="#45555F" />
    <path d="M9.3125 15.625C9.15625 15.625 9 15.5234 8.94531 15.3594C8.71094 14.6875 8.59375 13.9922 8.59375 13.2812C8.59375 12.5703 8.71094 11.875 8.94531 11.2031C9.01562 10.9922 9.24219 10.8828 9.44531 10.9609C9.64844 11.039 9.75 11.2656 9.67969 11.4765C9.47656 12.0547 9.375 12.664 9.375 13.2734C9.375 13.8828 9.47656 14.4922 9.67969 15.0703C9.75 15.2812 9.64844 15.5156 9.44531 15.5859C9.39844 15.6015 9.35938 15.6094 9.3125 15.6094V15.625Z" fill="#45555F" />
  </svg>
);

// export const variants = {
//   medium: Medium,
//   default: Medium,
// };

export default Door;
