import { Sensor } from '@energybox/react-ui-library/dist/types';
import equals from 'ramda/src/equals';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToDeviceReadings,
  unsubscribeFromDeviceReadings
} from '../actions/streamApi';
import { ApplicationState } from '../reducers';
import {
  SensorReadingsById,
  SensorsById,
  SensorTypeToReading
} from '../reducers/sensors';

//subscribes to and returns all sensor readings of an equipment or space
const useSubscribeToSensorReadings = (
  //can be equipmentId or spaceId
  resourceId: number | undefined
): SensorTypeToReading[] => {
  const dispatch = useDispatch();

  //*** useSelector ***//
  const siteSensorsById = useSelector<ApplicationState, SensorsById>(
    ({ sensors }) => sensors.sensorsById,
    equals
  );

  const siteSensorReadingsById = useSelector<
    ApplicationState,
    SensorReadingsById
  >(({ sensors }) => sensors.sensorReadingsById, equals);

  //*** useMemo ***//
  const siteSensors = useMemo(() => {
    return Object.values(siteSensorsById);
  }, [siteSensorsById]);

  const resourceSensors = useMemo(() => {
    return siteSensors.filter((s: Sensor) => {
      return Number(s.resourceId) === Number(resourceId);
    });
  }, [siteSensors, resourceId]);

  //*** useEffect ***//
  useEffect(() => {
    resourceSensors.forEach((s: Sensor) => {
      subscribeToSensorReading(dispatch, s);
    });

    return () => {
      resourceSensors.forEach((s: Sensor) => {
        unsubscribeFromSensorReading(dispatch, s);
      });
    };
  }, [dispatch, resourceSensors]);

  const resourceSensorReadings = useMemo(() => {
    return Object.keys(siteSensorReadingsById)
      .filter((sensorId) =>
        resourceSensors.find((sensor) => Number(sensor.id) === Number(sensorId))
      )
      .map((sensorId) => siteSensorReadingsById[sensorId]);
  }, [resourceSensors, siteSensorReadingsById]);

  return resourceSensorReadings;
};

const subscribeToSensorReading = (dispatch, sensor: Sensor) => {
  dispatch(subscribeToDeviceReadings(sensor.vendor, sensor.uuid, sensor.id));
};

const unsubscribeFromSensorReading = (dispatch, sensor: Sensor) => {
  dispatch(
    unsubscribeFromDeviceReadings(sensor.vendor, sensor.uuid, sensor.id)
  );
};

export default useSubscribeToSensorReadings;
