import React, { useState } from 'react';
import { CommentTag } from '@energybox/react-ui-library/dist/icons';
import CommentHistoryModal from '../../Modal/CommentHistoryModal/CommentHistoryModal';
import { Button } from '@energybox/react-ui-library/dist/components';
import styles from '../button.module.css';
import mixpanel from 'mixpanel-browser';
import { OPEN_COMMENTS_HISTORY } from '../../../mixpanelEvents';

interface Props {
  equipmentId?: number;
  spaceId?: number;
}

const CommentHistoryButton: React.FC<Props> = (props) => {
  const { spaceId, equipmentId } = props;

  const [isOpen, setIsOpen] = useState(false);

  const onClickButton = () => {
    setIsOpen(true);
    mixpanel.track(OPEN_COMMENTS_HISTORY, { equipmentId });
  };

  return (
    <>
      <Button
        roundedCorners
        variant="outlined"
        className={styles.iconButton}
        onClick={onClickButton}
      >
        <CommentTag size={23} variant={'outline'} />
      </Button>
      {isOpen && (
        <CommentHistoryModal
          equipmentId={equipmentId}
          spaceId={spaceId}
          onClose={() => setIsOpen(false)}
        />
      )}
    </>
  );
};

export default CommentHistoryButton;
