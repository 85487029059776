import { SopApiFilter } from '@energybox/react-ui-library/dist/types';
import { formatAPIFilters } from '@energybox/react-ui-library/dist/utils/util';

const apiBase = '/api/v1/sop';

export enum Actions {
  GET_SOP_LIST_SUCCESS = '@sops/GET_SOP_LIST_SUCCESS',
  GET_SOP_LIST_ERROR = '@sops/GET_SOP_LIST_ERROR',
  GET_SOP_LIST_LOADING = '@sops/GET_SOP_LIST_LOADING',
}

export const getSOP = (filter?: SopApiFilter) => ({
  type: 'API_GET',
  path: `${apiBase}?${formatAPIFilters<SopApiFilter>(filter)}`,
  success: { type: Actions.GET_SOP_LIST_SUCCESS },
  error: { type: Actions.GET_SOP_LIST_ERROR },
  loading: { type: Actions.GET_SOP_LIST_LOADING },
});
