import { useState } from 'react';

const useIsBrowserOnline = () => {
  const [isBrowserOnline, setIsBrowserOnline] = useState(
    window.navigator.onLine
  );

  //if the app was offline and returns online
  //then refresh the app
  window.ononline = () => {
    setIsBrowserOnline(true);
    window.location.reload();
  };

  window.onoffline = () => {
    setIsBrowserOnline(false);
  };

  return isBrowserOnline;
};

export default useIsBrowserOnline;
