export enum Actions {
  GET_ORGANIZATIONS_SUCCESS = '@app/GET_ORGANIZATIONS_SUCCESS',
  GET_ORGANIZATIONS_ERROR = '@app/GET_ORGANIZATIONS_ERROR',
  GET_ORGANIZATIONS_LOADING = '@app/GET_ORGANIZATIONS_LOADING',
  GET_ORGANIZATION_RESOURCES_SUCCESS = '@app/GET_ORGANIZATION_RESOURCES_SUCCESS',
  GET_ORGANIZATION_RESOURCES_ERROR = '@app/GET_ORGANIZATION_RESOURCES_ERROR',
  GET_ORGANIZATION_RESOURCES_LOADING = '@app/GET_ORGANIZATION_RESOURCES_LOADING',
  GET_CURRENT_ORGANIZATION_SUCCESS = '@app/GET_CURRENT_ORGANIZATION_SUCCESS',
  GET_CURRENT_ORGANIZATION_ERROR = '@app/GET_CURRENT_ORGANIZATION_ERROR',
  GET_CURRENT_ORGANIZATION_LOADING = '@app/GET_CURRENT_ORGANIZATION_LOADING'
}

export const getOrganizations = () => ({
  type: 'API_GET',
  path: '/api/v1/organizations',
  success: Actions.GET_ORGANIZATIONS_SUCCESS,
  error: Actions.GET_ORGANIZATIONS_ERROR,
  loading: Actions.GET_ORGANIZATIONS_LOADING
});

export const getCurrentOrganization = () => ({
  type: 'API_GET',
  path: '/api/v1/organizations/current',
  success: Actions.GET_CURRENT_ORGANIZATION_SUCCESS,
  error: Actions.GET_CURRENT_ORGANIZATION_ERROR,
  loading: Actions.GET_CURRENT_ORGANIZATION_LOADING
});

export const getOrganizationResources = () => ({
  type: 'API_GET',
  path: '/api/v1/organizations/current/stats',
  success: Actions.GET_ORGANIZATION_RESOURCES_SUCCESS,
  error: Actions.GET_ORGANIZATION_RESOURCES_ERROR,
  loading: Actions.GET_ORGANIZATION_RESOURCES_LOADING
});
