import {
  SensorApiFilter,
  SensorType
} from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/sensors';

export enum Actions {
  GET_SENSORS_BY_SITE_ID_SUCCESS = '@sensors/GET_SENSORS_BY_SITE_ID_SUCCESS',
  GET_SENSORS_BY_SITE_ID_ERROR = '@sensors/GET_SENSORS_BY_SITE_ID_ERROR',
  GET_SENSORS_BY_SITE_ID_LOADING = '@sensors/GET_SENSORS_BY_SITE_ID_LOADING'
}

export type GetSensorsParams = {
  ids?: string[] | number[];
  equipmentIds?: string[];
  siteIds?: string[];
  spaceIds?: string[];
  limit?: number;
  sensorTypes?: SensorType[];
  vendors?: string[];
  skip?: number;
  withPath?: boolean;
};

const createQueryString = (params: GetSensorsParams = {}) => {
  return Object.keys(params)
    .map(
      (key) =>
        `${key}=${
          Array.isArray(params[key]) ? params[key].join(',') : params[key]
        }`
    )
    .join('&');
};

export const setSensorApiFilter = (filter?: SensorApiFilter) => {
  const queryParams = new URLSearchParams();

  if (filter && filter.limit) {
    queryParams.set('limit', filter.limit.toString());
  }

  if (filter && filter.siteIds && filter.siteIds.length > 0) {
    queryParams.set('siteIds', filter.siteIds.join(','));
  }

  if (filter && filter.sensorTypes && filter.sensorTypes.length > 0) {
    queryParams.set('sensorTypes', filter.sensorTypes.join(','));
  }

  if (filter && filter.withPath) {
    queryParams.set('withPath', 'true');
  }
  return `/api/v1/sensors?${queryParams.toString()}`;
};

export const getSensorsBySiteId = (
  siteId: number | string,
  params: GetSensorsParams = {}
) => ({
  type: 'API_GET',
  path: `${apiBase}?${createQueryString({
    ...params,
    siteIds: [siteId]
  } as GetSensorsParams)}`,
  success: { type: Actions.GET_SENSORS_BY_SITE_ID_SUCCESS, siteId },
  error: { type: Actions.GET_SENSORS_BY_SITE_ID_ERROR, siteId },
  loading: { type: Actions.GET_SENSORS_BY_SITE_ID_LOADING, siteId }
});
