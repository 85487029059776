import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  TempCheckActionButton,
  TempCheckAction,
} from '../../components/Button';
import {
  ForgotPasswordSuccessModal,
  TempCheckIncompleteAlertModal,
} from '../../components/Modal';
import TempCheckSignInModal from '../../components/Modal/TempCheckSignInModal/TempCheckSignInModal';
import { useForgotPwd } from '../../hooks/useForgotPwd';
import { useSignIn } from '../../hooks/useSignIn';
import { useGetUsers } from '../../hooks/useUsers';
import { renderAPIerror } from '../../utils/apiErrorFeedback';
import { Actions as AppActions } from '../../actions/app';
import { useCurrentOrgId, useCurrentSiteId } from '../../hooks/useCurrentUser';
import { useTempCheck } from '../../hooks/useTempCheck';
import { useGetTempCheckCurrentUser } from '../../hooks/useGetCurrentUser';
import { useAlert } from '../../hooks/useAlert';
import { ALERT } from '../../constant';
import { endOfDay } from 'date-fns';
import { UserOrgUnitRelationship } from '@energybox/react-ui-library/dist/types/User';
import mixpanel from 'mixpanel-browser';
import {
  AUTO_LOGOUT_TEMP_CHECK,
  FINISH_TEMP_CHECK,
} from '../../mixpanelEvents';

interface Props {}

const TempCheckActionContainer: React.FC<Props> = (props) => {
  const [isModalOpen, setIsOpen] = useState(false);
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [isForgotPasswordTriggered, setIsForgotPasswordTriggered] = useState(
    false
  );

  const {
    form,
    updateField,
    isValid,
    handleLogin,
    isSignInSuccess,
    error,
    isLoading,
    clearForm,
  } = useSignIn();

  const { isAuthenticated, logout, isComplete } = useTempCheck();
  const {
    forgotPwd,
    isEmailSent,
    isLoading: forgotPwdLoading,
  } = useForgotPwd();

  useGetTempCheckCurrentUser();

  const siteId = useCurrentSiteId();
  const orgId = useCurrentOrgId();

  const sitIds = useMemo(() => {
    const ids: number[] = [];
    if (siteId) ids.push(siteId);
    if (orgId) ids.push(orgId);
    return ids;
  }, [siteId, orgId]);

  const { usersById, fetchUsers } = useGetUsers({
    siteIds: sitIds,
    relationships: [UserOrgUnitRelationship.SCOPE],
  });

  const handleForgotPwd = () => {
    const email = usersById[form.userId ?? 0]?.email ?? '';
    if (!email) return;
    forgotPwd(email);
    setIsForgotPasswordTriggered(true);
  };

  useEffect(() => {
    if (isSignInSuccess) setIsOpen(false);
  }, [isSignInSuccess, logout]);

  const isCompleteRef = useRef<boolean>(false);

  // for clean up use
  useEffect(() => {
    isCompleteRef.current = isComplete();
  }, [isComplete]);

  useEffect(() => {
    return () => {
      if (isAuthenticated && !isCompleteRef.current) addInComopleteAlert();
      if (isAuthenticated) {
        mixpanel.track(AUTO_LOGOUT_TEMP_CHECK, {
          isComplete: isCompleteRef.current,
        });
        logout();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logoutAndRefresh = (isComplete?: boolean) => {
    mixpanel.track(FINISH_TEMP_CHECK, { isComplete });
    logout();
    window.location.reload();
  };

  const { saveAlert, remove } = useAlert();
  const addInComopleteAlert = () => {
    saveAlert({
      ...ALERT.tempCheck,
      startAt: Date.now(),
      timeoutAt: endOfDay(Date.now()).getTime(),
    });
  };
  const finishInCompleteTempCheck = () => {
    setIsAlertOpen(false);
    addInComopleteAlert();
    logoutAndRefresh(false);
  };

  const handleButtonAction = () => {
    if (!isAuthenticated) {
      fetchUsers();
      setIsOpen(true);
      return;
    }

    if (isComplete()) {
      remove(ALERT.tempCheck);
      logoutAndRefresh(true);
    } else {
      setIsAlertOpen(true);
    }
  };

  const onCloseSigningModal = () => {
    clearForm();
    setIsOpen(false);
  };

  return (
    <>
      <TempCheckActionButton
        action={
          isAuthenticated ? TempCheckAction.FINISHED : TempCheckAction.PERFORM
        }
        onClick={handleButtonAction}
      />
      {isModalOpen && (
        <TempCheckSignInModal
          onClose={onCloseSigningModal}
          users={usersById}
          form={form}
          onChange={updateField}
          onNext={isValid() ? handleLogin : undefined}
          onClickForgotPwd={handleForgotPwd}
          apiError={
            error
              ? renderAPIerror(error, AppActions.TEMP_CHECK_SIGN_IN_ERROR)
              : undefined
          }
          isLoading={isLoading}
          isForgotPwdLoading={forgotPwdLoading}
          disabled={isLoading || forgotPwdLoading}
        />
      )}
      {isForgotPasswordTriggered && isEmailSent && (
        <ForgotPasswordSuccessModal
          onClose={() => setIsForgotPasswordTriggered(false)}
        />
      )}
      {isAlertOpen && (
        <TempCheckIncompleteAlertModal
          onClose={() => setIsAlertOpen(false)}
          onNext={finishInCompleteTempCheck}
        />
      )}
    </>
  );
};

export default TempCheckActionContainer;
