import { Actions as AppActions } from '../actions/app';
import { Actions as UserActions } from '../actions/users';

import { Routes } from '../routes';
import history from '../history';

const redirectMiddleware = (store) => (next) => (action) => {
  switch (action.type) {
    case AppActions.REDIRECT: {
      return history.push(action.path, {});
    }

    case UserActions.USER_CHANGE_PASSWORD_SUCCESS: {
      history.push(`/`);
      return;
    }

    case AppActions.RESET_PASSWORD_SUCCESS: {
      history.push(Routes.LOGIN);
      return next(action);
    }

    default:
      return next(action);
  }
};

export default redirectMiddleware;
