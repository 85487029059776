import { ResourceType, Site } from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/sites';

export type SitesById = {
  [id: string]: Site;
};

export interface Sites {
  sitesById: SitesById;
  isSiteLoading: boolean;
}

const sitesFromApiResponse = (data: any) => ({
  id: data.id,
  legacyId: data.legacyId || undefined,
  externalId: data.externalId || undefined,
  organizationId: data.organizationId,
  title: data.title,
  description: data.description || undefined,
  areaTotal: data.areaTotal,
  country: data.country,
  postalCode: data.postalCode,
  city: data.city || '',
  state: data.state || '',
  street: data.street || '',
  street2: data.street2 || '',
  locale: data.locale || undefined,
  timeZone: data.timeZone,
  latitude: data.latitude || '',
  longitude: data.longitude || '',
  currency: data.currency || undefined,
  address: data.address,
  createdAt: data.createdAt,
  updatedAt: data.updatedAt || undefined,
  images: data.images || [],
  energyCalculationMode: data.energyCalculationMode || '',
  resourceType: ResourceType[(data._entity as string).toUpperCase()],
  newTsdb: data.newTsdb
});

export const initialState = {
  sitesById: {},
  isSiteLoading: false
};

export const createFullAddress = (site: Site) =>
  `${site.street || ''}, ${site.street2 ? `${site.street2},` : ''} ${
    site.city || ''
  }, ${site.state || ''} ${site.postalCode || ''}${
    site.country ? `, ${site.country}` : ''
  }`;

const sites = (state: Sites = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SITE_SUCCESS:
      let site = sitesFromApiResponse(action.data);

      return R.pipe(
        R.assocPath(['sitesById', action.data.id], site),
        R.assoc('isSiteLoading', false)
      )(state);

    case Actions.GET_SITE_LOADING:
      return R.assoc('isSiteLoading', true, state);

    case Actions.GET_SITE_ERROR:
      return R.assoc('isSiteLoading', false, state);

    default:
      return state;
  }
};

export default sites;
