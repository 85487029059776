import { TopBar } from '@energybox/react-ui-library/dist/components';
import {
  CompanyLogo,
  KioskLogo,
  Organization as SiteIcon,
  PartiallyCloudy as WeatherIcon,
} from '@energybox/react-ui-library/dist/icons';
import { CurrentUser, Site } from '@energybox/react-ui-library/dist/types';
import {
  createTemperatureString,
  global,
} from '@energybox/react-ui-library/dist/utils';
import isNil from 'ramda/src/isNil';

import React from 'react';
// import useAppLocale from '../../hooks/useAppLocale';
import useCurrentUser from '../../hooks/useCurrentUser';
import useGetWeather from '../../hooks/useGetWeather';
import styles from './TopBarContainer.module.css';

type Props = {
  site?: Site;
};

const TopBarContainer: React.FC<Props> = ({ site }) => {
  const currentUser = useCurrentUser();
  // const locale = useAppLocale();
  const { title, id } = site || {};

  const { temperature } = useGetWeather(id) || {};

  if (!currentUser) return null;
  return (
    <TopBar className={styles.topBarContainer}>
      <div className={styles.leftTopBar}>
        <CompanyLogo width="90" height="30" type="inverted" />
        <div className={styles.divider} />
        <KioskLogo size={35} color={'white'} />
      </div>
      <div className={styles.rightTopBar}>
        <div className={styles.iconContainer}>
          <span className={styles.icon}>
            <WeatherIcon size={16} color={'var(--accent-base)'} />
          </span>
          <span>{renderWeatherTemp(temperature, currentUser)}</span>
        </div>

        <div className={styles.divider} />

        <div className={styles.iconContainer}>
          <span className={styles.icon}>
            <SiteIcon size={14} color={'var(--accent-base)'} />
          </span>
          <span>{title || global.NOT_AVAILABLE}</span>
        </div>

        {/* <div className={styles.liveClockContainer}>
          <LiveClock
            timezone={timeZone}
            dateTimeFormat={locale.fullDateTimeFormat}
          />
        </div> */}
      </div>
    </TopBar>
  );
};

const renderWeatherTemp = (
  temp: number | undefined,
  currentUser: CurrentUser
) => {
  if (isNil(temp)) return global.NOT_AVAILABLE;
  return createTemperatureString(temp as number, currentUser, 0);
};

export default TopBarContainer;
