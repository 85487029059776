import {
  CommentApiFilter,
  NewCommentFields,
} from '@energybox/react-ui-library/dist/types';
import { formatAPIFilters } from '@energybox/react-ui-library/dist/utils/util';
import { EnergyboxService } from '../config';
import { getToken, STORE_TEMP_CHECK_KEY } from '../middlewares/authMiddleware';

const apiBase = '/api/v1/comments';

export enum Actions {
  GET_COMMENTS_SUCCESS = '@@comments/GET_COMMENTS_SUCCESS',
  GET_COMMENTS_LOADING = '@@comments/GET_COMMENTS_LOADING',
  GET_COMMENTS_ERROR = '@@comments/GET_COMMENTS_ERROR',

  POST_COMMENT_SUCCESS = '@@comments/POST_COMMENT_SUCCESS',
  POST_COMMENT_LOADING = '@@comments/POST_COMMENT_LOADING',
  POST_COMMENT_ERROR = '@@comments/POST_COMMENT_ERROR',
}

export const getComments = (
  resourceId: number | string,
  filter?: CommentApiFilter
) => ({
  type: 'API_GET',
  service: EnergyboxService.comments,
  path: `${apiBase}/resource/${resourceId}/downstream?${formatAPIFilters<CommentApiFilter>(
    filter
  )}`,
  loading: {
    type: Actions.GET_COMMENTS_LOADING,
    resourceId,
  },
  success: {
    type: Actions.GET_COMMENTS_SUCCESS,
    resourceId,
  },
  error: {
    type: Actions.GET_COMMENTS_ERROR,
    resourceId,
  },
});

export const createComments = (
  commentPayload: NewCommentFields,
  uuid: string,
  callback?: (resp: any) => void
) => {
  return {
    type: 'API_POST',
    service: EnergyboxService.comments,
    path: apiBase,
    payload: commentPayload,
    token: getToken(STORE_TEMP_CHECK_KEY),
    loading: {
      type: Actions.POST_COMMENT_LOADING,
      key: uuid,
    },
    success: {
      type: Actions.POST_COMMENT_SUCCESS,
      key: uuid,
    },
    error: {
      type: Actions.POST_COMMENT_ERROR,
      key: uuid,
    },
    callback,
  };
};
