import { Locale } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export default function useAppLocale() {
  const locale: Locale = useSelector<ApplicationState, Locale>(
    ({ app }) => app.locale
  );
  return locale;
}
