import { Actions } from '../actions/comments';
import {
  mapValues,
  normalizeCommentFromApiResponse,
} from '@energybox/react-ui-library/dist/utils';
import { assocPath } from 'ramda';
import { Comment } from '@energybox/react-ui-library/dist/types';
import { trackAddingComment } from '../utils/mixpanel';

export type CommentStatus = {
  isLoading: boolean;
  comments?: Comment[];
};

export type CommentsByResourceId = {
  [resourceId: string]: CommentStatus;
};

export type Comments = {
  commentsByResourceId: CommentsByResourceId;
};

const initialState = {
  commentsByResourceId: {},
};

const comments = (state: Comments = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_COMMENTS_SUCCESS:
      return assocPath(
        ['commentsByResourceId', action.resourceId],
        {
          isLoading: false,
          comments: mapValues(action.data, normalizeCommentFromApiResponse),
        },
        state
      );

    case Actions.GET_COMMENTS_LOADING:
      return assocPath(
        ['commentsByResourceId', action.resourceId, 'isLoading'],
        true,
        state
      );

    case Actions.GET_COMMENTS_ERROR:
      return assocPath(
        ['commentsByResourceId', action.resourceId, 'isLoading'],
        false,
        state
      );

    case Actions.POST_COMMENT_SUCCESS:
      trackAddingComment(action.data, 'SUCCESS');
      return state;
    case Actions.POST_COMMENT_LOADING:
      trackAddingComment(action.payload);
      return state;
    case Actions.POST_COMMENT_ERROR:
      trackAddingComment(action.data, 'FAILED');
      return state;

    default:
      return state;
  }
};

export default comments;
