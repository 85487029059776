import {
  Button,
  Modal,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import React from 'react';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import ModalFooter from '../ModalFooter/ModalFooter';
import styles from './TempCheckSignInModal.module.css';
import modalStyles from '../Modal.module.css';
import ModalTitle from '../ModalTitle/ModalTitle';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import { SignInFormProps, SignInTempCheckForm } from '../../Form';
import translate from '../../../translations';

interface Props extends SignInFormProps {
  onClose: () => void;
  onNext?: (e: any) => void;
  isLoading?: boolean;
}

const TempCheckSignInModal: React.FC<Props> = (props) => {
  const { onClose, onNext, isLoading, ...formProps } = props;

  return (
    <Modal containerClassName={styles.modalRoot}>
      <ModalTitle className={styles.modalTitle}>
        {translate.signIn.userSelection}
      </ModalTitle>
      <ModalCloseButton onClose={onClose} disabled={isLoading} />

      <ModalContent
        className={classNames(modalStyles.modalContent, styles.noPaddingBottom)}
      >
        <div className={styles.contentContainer}>
          <span className={styles.subTitle}>{translate.signIn.toContinue}</span>
          <SignInTempCheckForm {...formProps} />
        </div>
      </ModalContent>

      <ModalFooter>
        <Button
          className={styles.button}
          onClick={onNext}
          disabled={!onNext}
          isLoading={isLoading}
        >
          {translate.button.next}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TempCheckSignInModal;
