import { Actions as AppActions } from '../actions/app';
import { getServiceUrl } from '../config';
import { getActionName } from '../utils/actionUtil';
import { actionExceptionLogout, genHeaders } from '../utils/apiUtil';
import { trackAutoLogout } from '../utils/mixpanel';

const ALLOWED_METHODS = ['GET', 'POST', 'PUT', 'PATCH', 'UPDATE', 'DELETE'];

const matcher = new RegExp(`^API_(${ALLOWED_METHODS.join('|')})$`);

const dispatchAction = (store: any, action: any, data: any = {}) => {
  if (typeof action === 'string') {
    store.dispatch({ type: action, ...data });
  } else if (Array.isArray(action)) {
    action.forEach((a) => {
      dispatchAction(store, a, data);
    });
  } else {
    store.dispatch({ ...action, ...data });
  }
};

const requestCache = new Set<string>();

const apiMiddleware = (store) => (next) => (action) => {
  const matchedGroup = action.type.match(matcher);

  if (matchedGroup) {
    const { path, payload, service, callback, token } = action;
    const successAction = action.success || '@@API/UNHANDLED_SUCCESS_RESPONSE';
    const errorAction = action.error || '@@API/UNHANDLED_ERROR_RESPONSE';

    const serviceUrl = getServiceUrl(service);
    const urlKey = serviceUrl + path;
    const cacheKey = `${matchedGroup[1]}-${urlKey}`;

    if (action.loading || (action.type && action.type.loading)) {
      if (requestCache.has(cacheKey)) {
        return;
      }

      requestCache.add(cacheKey);
      dispatchAction(store, action.loading);
    }
    const headers = genHeaders(store, action, serviceUrl, token);
    return fetch(urlKey, {
      method: matchedGroup[1],
      headers,
      body:
        headers['Content-Type'] === 'application/json'
          ? JSON.stringify(payload)
          : payload,
    })
      .then((response) => {
        if (
          response.status === 401 &&
          !actionExceptionLogout.includes(getActionName(action.error))
        ) {
          trackAutoLogout();
          store.dispatch({ type: AppActions.LOGOUT });
        }

        if (callback) callback(response);

        const resp = { ok: response.ok };
        const responseContentType = response.headers.get('content-type');
        if (responseContentType) {
          if (
            responseContentType.startsWith('application/json') ||
            responseContentType.startsWith('application/vnd.api+json')
          ) {
            return response.json().then((json) => ({ ...resp, stream: json }));
          }
        }

        return response.text().then((text) => ({ ...resp, stream: text }));
      })
      .then((response) => {
        dispatchAction(store, !response.ok ? errorAction : successAction, {
          data: response.stream,
        });

        requestCache.delete(cacheKey);
      })
      .catch((error) => {
        dispatchAction(store, errorAction, { data: error });
        requestCache.delete(cacheKey);
      });
  } else {
    next(action);
  }
};

export default apiMiddleware;
