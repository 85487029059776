import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

const useIsIncidentCardsLoading = () => {
  const isSiteLoading = useSelector<ApplicationState, boolean>(
    ({ sites }) => sites.isSiteLoading
  );

  const isEquipmentLoading = useSelector<ApplicationState, boolean>(
    ({ equipment }) => equipment.isEquipmentLoading
  );

  const isSensorsLoading = useSelector<ApplicationState, boolean>(
    ({ sensors }) => sensors.isSensorsLoading
  );

  const isSpacesLoading = useSelector<ApplicationState, boolean>(
    ({ spaces }) => spaces.isSpacesBySiteIdLoading
  );

  const isInitialPageLoading = useSelector<ApplicationState, boolean>(
    ({ subscribedIncidents }) => subscribedIncidents.isInitialLoading
  );

  const isLoading =
    isSiteLoading ||
    isEquipmentLoading ||
    isSensorsLoading ||
    isSpacesLoading ||
    isInitialPageLoading;

  return isLoading;
};

export default useIsIncidentCardsLoading;
