import {
  Button,
  Modal,
  ModalContent,
} from '@energybox/react-ui-library/dist/components';
import { Alert } from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import translate from '../../../translations';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './TempCheckIncompleteAlertModal.module.css';
import modalStyles from '../Modal.module.css';
import ModalFooter from '../ModalFooter/ModalFooter';

interface Props {
  onClose: () => void;
  onNext: () => void;
}

const TempCheckIncompleteAlertModal: React.FC<Props> = (props) => {
  const { onClose, onNext } = props;

  return (
    <Modal>
      <ModalCloseButton onClose={onClose} />

      <ModalContent
        className={classNames(modalStyles.modalContent, styles.root)}
      >
        <Alert size={25} className={classNames(styles.alertIcon)} />
        <div className={classNames(styles.header)}>
          {translate.tempCheck.alert.incomplete}
        </div>

        <span className={styles.message}>
          {translate.tempCheck.alert.incompleteDetail}
        </span>
      </ModalContent>

      <ModalFooter>
        <Button className={styles.button} onClick={onNext} disabled={!onNext}>
          {translate.button.ok}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default TempCheckIncompleteAlertModal;
