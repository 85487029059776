import { toDate } from 'date-fns';
import jwt_decode from 'jwt-decode';
import { getToken } from '../middlewares/authMiddleware';

const getDecoded = (token: string) => {
  const decodedToken =
    getToken(token) && jwt_decode<any>(getToken(token) ?? '');
  return decodedToken;
};

export const getEmail = (token: string) => {
  const decodedToken = getDecoded(token);
  return decodedToken?.['sub'];
};

export const getExpiryDate = (token: string) => {
  const decodedToken = getDecoded(token);
  return toDate((decodedToken?.['exp'] ?? 0) * 1000);
};
