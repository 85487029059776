import {
  IncidentCard,
  MenuDropdownItem,
  TimeDistance,
} from '@energybox/react-ui-library/dist/components';
import {
  CurrentUser,
  Equipment,
  SensorType,
  Space,
  SubscribedIncident,
  BinaryReadingLabel,
} from '@energybox/react-ui-library/dist/types';
import {
  global,
  createTemperatureString,
} from '@energybox/react-ui-library/dist/utils';
import uniq from 'ramda/src/uniq';

import React, { useMemo, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import DismissNotificationModal from '../../components/DismissNotificationModal';
import PopupModal from '../../components/PopupModal';
import useCountDown from '../../hooks/useCountDown';
import useCurrentUser from '../../hooks/useCurrentUser';
import useSubscribeToSensorReadings from '../../hooks/useSubscribeToSensorReadings';
import { SensorReading, SensorTypeToReading } from '../../reducers/sensors';
import styles from './IncidentCardContainer.module.css';

type Props = {
  incident: SubscribedIncident;
  resource: Equipment | Space | undefined;
};

const IncidentCardContainer: React.FC<Props> = ({ resource, incident }) => {
  const currentUser = useCurrentUser();
  const [isDismissModalOpen, setIsDismissModalOpen] = useState(false);
  const [isPopModalOpen, setIsPopModalOpen] = useState(false);
  //initial counter when incident card appears is 5min, or 300s
  const pulseCountDown = useCountDown(300);

  //subscribes to and returns all sensor readings of an equipment or space
  const sensorReadings = useSubscribeToSensorReadings(resource?.id);

  const isDismissed = !!incident.dismissedAt;
  const isPulseActive = !isDismissed && pulseCountDown > 0;
  const resourceTitle = resource?.title;
  const notification =
    incident.incidentNotification[incident.incidentNotification.length - 1];
  const notificationName = notification?.name;
  const notificationMessage = notification?.message;
  let regex = /.*\. (.*?)/g;
  let notificationMessageText = notificationMessage.replace(regex, '$1');
  const incidentCreatedAtIsoString = new Date(incident.createdAt).toISOString();

  const temperatureReading = useMemo(() => {
    return renderIncidentCardReading(
      sensorReadings,
      SensorType.TEMPERATURE,
      currentUser
    );
  }, [sensorReadings, currentUser]);

  const humidityReading = useMemo(() => {
    return renderIncidentCardReading(sensorReadings, SensorType.HUMIDITY);
  }, [sensorReadings]);

  const doorReading = useMemo(() => {
    return renderIncidentCardReading(sensorReadings, SensorType.BINARY);
  }, [sensorReadings]);

  const MenuDropdownItems = [
    <MenuDropdownItem
      key="dismissDropdown"
      onSelect={() => setIsDismissModalOpen(true)}
    >
      Dismiss
    </MenuDropdownItem>,
  ];

  const PopupItems = [
    <div
      className={styles.clickAbleBox}
      onClick={() => setIsPopModalOpen(true)}
    ></div>,
  ];

  return (
    <>
      <IncidentCard
        className={styles.root}
        pulse={isPulseActive}
        status={
          incident.incidentPriority.toLowerCase() as
            | 'normal'
            | 'medium'
            | 'high'
            | 'highest'
            | 'critical'
        }
        titleText={resourceTitle || global.NOT_AVAILABLE}
        incidentTimeText={renderIncidentTimeText(incidentCreatedAtIsoString)}
        incidentText={notificationName || ''}
        incidentSubtext={notificationMessageText || ''}
        temperature={temperatureReading}
        humidity={humidityReading}
        door={doorReading}
        menuItems={MenuDropdownItems}
        dismissed={isDismissed}
        popupModal={PopupItems}
      />

      {isDismissModalOpen && (
        <DismissNotificationModal
          //incidentId refers to the incident's most recent notificationId
          notificationId={incident.incidentId}
          onClose={() => setIsDismissModalOpen(false)}
        />
      )}

      {isPopModalOpen && (
        <PopupModal
          status={
            incident.incidentPriority.toLowerCase() as
              | 'normal'
              | 'medium'
              | 'high'
              | 'highest'
              | 'critical'
          }
          titleText={resourceTitle || global.NOT_AVAILABLE}
          incidentTimeText={renderIncidentTimeText(incidentCreatedAtIsoString)}
          incidentText={notificationName || ''}
          incidentSubtext={notificationMessageText || ''}
          temperature={temperatureReading}
          humidity={humidityReading}
          door={doorReading}
          dismissed={isDismissed}
          onClose={() => setIsPopModalOpen(false)}
        />
      )}
    </>
  );
};

const renderIncidentCardReading = (
  sensorReadings: SensorTypeToReading[],
  sensorType: SensorType,
  currentUser?: CurrentUser
) => {
  let sensorTypeReadings: SensorReading[] = [];

  sensorReadings.forEach((sensorReading) => {
    if (sensorReading[sensorType] !== undefined) {
      sensorTypeReadings.push(sensorReading[sensorType] as SensorReading);
    }
  });

  const sensorValues: any[] = uniq(
    sensorTypeReadings
      .map((d) => d[sensorType.toLowerCase()])
      .filter((v) => v !== undefined)
  );

  if (sensorValues.length === 0) return;
  switch (sensorType) {
    case SensorType.TEMPERATURE: {
      if (!currentUser) return null;
      const minTemp = Math.min(...sensorValues);
      const maxTemp = Math.max(...sensorValues);

      if (minTemp === maxTemp) {
        return (
          <span className={styles.sensorValue}>
            {createTemperatureString(minTemp, currentUser)}
          </span>
        );
      } else {
        return (
          <span>
            <span className={styles.sensorValue}>
              {createTemperatureString(minTemp, currentUser)}
            </span>
            <span> / </span>
            <span className={styles.sensorValue}>
              {createTemperatureString(maxTemp, currentUser)}
            </span>
          </span>
        );
      }
    }

    case SensorType.HUMIDITY: {
      const minHumidity = Math.min(...sensorValues);
      const maxHumidity = Math.max(...sensorValues);

      if (minHumidity === maxHumidity) {
        return (
          <span className={styles.sensorValue}>{+minHumidity.toFixed(1)}%</span>
        );
      } else {
        return (
          <span>
            <span className={styles.sensorValue}>
              {+minHumidity.toFixed(1)}%
            </span>
            <span> / </span>
            <span className={styles.sensorValue}>
              {+maxHumidity.toFixed(1)}%
            </span>
          </span>
        );
      }
    }

    case SensorType.BINARY: {
      if (sensorValues.length === 1) {
        const binaryValue = sensorValues[0];

        return (
          <span className={styles.sensorValue}>
            {BinaryReadingLabel[binaryValue]}
          </span>
        );
      } else {
        return (
          <span>
            <span className={styles.sensorValue}>Open</span>
            <span> / </span>
            <span className={styles.sensorValue}>Closed</span>
          </span>
        );
      }
    }

    default:
      return undefined;
  }
};

const renderIncidentTimeText = (incidentCreatedAtIsoString: string) => {
  const timeDistance = ReactDOMServer.renderToString(
    <TimeDistance timestamp={incidentCreatedAtIsoString} />
  );
  return `Incident started ${timeDistance}`;
};

export default IncidentCardContainer;
