import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import useIsBrowserOnline from '../../hooks/useIsBrowserOnline';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';
import { getActiveIncidentsForSite } from '../../actions/incidents';
import EquipmentCheckContainer from '../../containers/EquipmentCheckContainer';
import HomePage from '../../containers/HomePage';
import SiteSwitchPage from '../../containers/SiteSwitchPage';
import TopBarContainer from '../../containers/TopBarContainer';
import useGetCurrentUser from '../../hooks/useGetCurrentUser';
import useGetEquipmentsBySiteId from '../../hooks/useGetEquipmentsBySiteId';
import useGetSensorsBySiteId from '../../hooks/useGetSensorsBySiteId';
import useGetSite from '../../hooks/useGetSite';
import useGetSpacesBySiteId from '../../hooks/useGetSpacesBySiteId';
import useIsInitialLoading from '../../hooks/useIsInitialLoading';
import useSubscribeToIncidents from '../../hooks/useSubscribeToIncidents';
import { SubscribedIncidentsBySentinelId } from '../../reducers/subscribedIncidents';
import { Routes } from '../../routes';
import {
  sortByDismissed,
  sortByPriorityDateDescending,
  sortByResolved,
} from '../../utils/subscribedIncidents';
// import IncidentAlerts from '../IncidentAlerts';
import styles from './PrimaryLayout.module.css';
import { useIdleTimer } from '../../hooks/useIdleTimer';
import { TIMEOUTS } from '../../constant';

const PrimaryLayout: React.FC = () => {
  useIsInitialLoading();
  useIsBrowserOnline();
  const currentUser = useGetCurrentUser();
  const kioskSiteId = useMemo(() => {
    return currentUser?.kiosk?.preferredSiteId;
  }, [currentUser]);
  const history = useHistory();
  const location = useLocation();

  useGetSensorsBySiteId(kioskSiteId);
  useGetInitialIncidents(kioskSiteId);

  const site = useGetSite(kioskSiteId);

  const { equipmentById, equipmentIds } = useGetEquipmentsBySiteId(kioskSiteId);

  const { spacesById, spaceIds } = useGetSpacesBySiteId(kioskSiteId);

  const subscribedIncidentsByResourceId = useSubscribeToIncidents(
    equipmentIds,
    spaceIds
  );

  const subscribedActiveIncidents = useMemo(() => {
    const incidentsOfResourceBySentinelId = Object.values(
      subscribedIncidentsByResourceId
    );
    const incidentToDisplayPerResource = determineIncidentToDisplayPerResource(
      incidentsOfResourceBySentinelId
    );
    const activeIncidentsToDisplay = incidentToDisplayPerResource.filter(
      (i) => {
        return i.resolved !== true;
      }
    );

    return activeIncidentsToDisplay
      .sort(sortByPriorityDateDescending)
      .sort(sortByDismissed);
  }, [subscribedIncidentsByResourceId]);

  // return to home page if idle in minutes
  const { isTimeout, resetTimer } = useIdleTimer(TIMEOUTS.IDLE_TIMEOUT);
  useEffect(() => {
    if (location.pathname !== Routes.BASE) resetTimer();
  }, [location, resetTimer]);
  useEffect(() => {
    if (isTimeout && location.pathname !== Routes.BASE) {
      history.push('/');
      resetTimer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isTimeout]);

  return (
    <div>
      <TopBarContainer site={site} />

      <div className={styles.contentContainer}>
        <Switch>
          <Route exact path={Routes.SITE_SWITCH} component={SiteSwitchPage} />

          <Route
            exact
            path={Routes.BASE}
            render={() => (
              <HomePage
                subscribedActiveIncidents={subscribedActiveIncidents}
                equipmentById={equipmentById}
                spacesById={spacesById}
                site={site}
              />
            )}
          />

          <Route
            exact
            path={Routes.TEMP_CHECK}
            render={() => (
              <EquipmentCheckContainer
                site={site}
                subscribedActiveIncidents={subscribedActiveIncidents}
              />
            )}
          />
        </Switch>

        {/* TODO: TOASTS/ALERTS have been archived for now, per Harsh/Ananya 
            Need to think through more carefully about how to alert new incidents without bombarding user 
        */}
        {/* <IncidentAlerts equipmentById={equipmentById} spacesById={spacesById} /> */}
      </div>
    </div>
  );
};

const useGetInitialIncidents = (kioskSiteId: number | undefined) => {
  const dispatch = useDispatch();
  const [fetchGuard, setFetchGuard] = useState(false);
  useEffect(() => {
    if (kioskSiteId !== undefined && fetchGuard === false) {
      setFetchGuard(true);
      dispatch(getActiveIncidentsForSite(kioskSiteId));
    }
  }, [dispatch, kioskSiteId, fetchGuard]);
};

const determineIncidentToDisplayPerResource = (
  incidentsOfResourceBySentinelId: SubscribedIncidentsBySentinelId[]
) => {
  return incidentsOfResourceBySentinelId.map((incidentsBySentinelId) => {
    const incidentsOfResource = Object.values(incidentsBySentinelId);
    const sortedIncidents = incidentsOfResource
      .sort(sortByPriorityDateDescending)
      .sort(sortByDismissed)
      .sort(sortByResolved);

    return sortedIncidents[0];
  });
};

export default PrimaryLayout;
