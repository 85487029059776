import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect, withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../reducers';
import { isSignedIn } from '../reducers/app';
import { Routes } from '../routes';

const PUBLIC_ROUTES = [
  Routes.LOGIN.valueOf(),
  Routes.FORGOT_PASSWORD.valueOf(),
  Routes.NEW_PASSWORD.valueOf()
];

interface Props extends RouteComponentProps {
  component: any;
  path: string;
  isSignedIn: boolean;
}

const SecureRoute = ({
  component: Component,
  path,
  isSignedIn,
  ...rest
}: Props) => {
  const render = (props: any) => {
    const canAccess = isSignedIn || PUBLIC_ROUTES.indexOf(path) !== -1;
    return canAccess ? (
      <Component {...props} />
    ) : (
      <Redirect
        to={{
          pathname: Routes.LOGIN,
          state: { from: props.location }
        }}
      />
    );
  };

  return <Route path={path} render={render} {...rest} />;
};

const mapStateToProps = ({ app }: ApplicationState) => ({
  isSignedIn: isSignedIn(app)
});

export default withRouter(connect(mapStateToProps)(SecureRoute));
