import {
  MediumSkeletonCell,
  ShortSkeletonCell,
  Table,
} from '@energybox/react-ui-library/dist/components';
import { Columns } from '@energybox/react-ui-library/dist/components/Table';
import React, { useMemo } from 'react';
import {
  Comment,
  CommentTypeLabel,
  OpacityIndex,
  SortDirection,
} from '@energybox/react-ui-library/dist/types';
import {
  getDateString as toDateString,
  determineTimeStringByTimezone,
  formattedCommentValueString,
  genericTableSort,
  SORT_IGNORED_VALUES,
} from '@energybox/react-ui-library/dist/utils';
import useAppLocale from '../../../hooks/useAppLocale';
import { useCurrentSite } from '../../../hooks/useCurrentSite';
import useCurrentUser from '../../../hooks/useCurrentUser';
import { isToday, isYesterday, parseISO } from 'date-fns';
import styles from './CommentHistoryTable.module.css';
import { countBy } from 'ramda';
import { usersById } from '../../../hooks/useUsers';

interface Props {
  commentList: Comment[];
  isLoading: boolean;
}

interface FormattedData extends Comment {
  displayDate: string;
}

const CommentHistoryTable: React.FC<Props> = (props) => {
  const { commentList, isLoading } = props;

  const locale = useAppLocale();
  const site = useCurrentSite();
  const currentUser = useCurrentUser();
  const users = usersById();

  const formattedDataList: FormattedData[] = useMemo(() => {
    const getDateString = (dateString: string): string => {
      if (isToday(new Date(dateString))) {
        return 'Today';
      } else if (isYesterday(new Date(dateString))) {
        return 'Yesterday';
      } else {
        return toDateString({
          value: dateString,
          isTimeFrom: false,
          ianaTimeZoneCode: site?.timeZone,
          format: locale.dateFormat,
        });
      }
    };

    return commentList.map((c) => ({
      displayDate: getDateString(c.from),
      ...c,
    }));
  }, [commentList, locale, site]);

  const dateCountMapper = useMemo(() => {
    return countBy((d) => d.displayDate)(formattedDataList);
  }, [formattedDataList]);

  const comparator = (key: string) => (
    a: Comment,
    b: Comment,
    sortDirection: SortDirection
  ) => {
    return genericTableSort(a, b, sortDirection, SORT_IGNORED_VALUES, [key]);
  };

  const skeletonCellContent = (size?: 'SHORT' | 'MEDIUM' | '') => (
    rowIndex: OpacityIndex
  ) => {
    switch (size) {
      case 'SHORT':
        return <ShortSkeletonCell opacityIndex={rowIndex} />;
      case 'MEDIUM':
        return <MediumSkeletonCell opacityIndex={rowIndex} />;
      default:
        return <ShortSkeletonCell opacityIndex={rowIndex} />;
    }
  };

  const columns: Columns<FormattedData>[] = [
    {
      header: '',
      width: '15%',
      cellContent: ({ displayDate }, rowIndex) => (
        <span className={styles.date}>{displayDate}</span>
      ),
      skeletonCellContent: skeletonCellContent(),
      comparator: comparator('filename'),
      getRowSpan: (rowIndex) =>
        formattedDataList[rowIndex]
          ? dateCountMapper[formattedDataList[rowIndex].displayDate]
          : 1,
      getIsHidden: (rowIdx) =>
        // hide column if date equal to pervious row
        rowIdx !== 0 &&
        !!formattedDataList[rowIdx] &&
        formattedDataList[rowIdx].displayDate ===
          formattedDataList[rowIdx - 1].displayDate,
      showSortIcons: false,
      highlightIsActive: false,
    },
    {
      header: 'Comment Type',
      width: '15%',
      cellContent: ({ commentType }) => (
        <span className={styles.commentType}>
          {CommentTypeLabel[commentType]}
        </span>
      ),
      skeletonCellContent: skeletonCellContent(),
      comparator: comparator('commentType'),
      showSortIcons: false,
    },

    {
      header: 'Details',
      width: '15%',
      cellContent: ({ value, valueType, from }) => (
        <div className={styles.flexColumn}>
          <span className={styles.tempText}>
            {currentUser && site
              ? formattedCommentValueString(
                  value,
                  valueType,
                  currentUser,
                  site?.country
                )
              : ''}
          </span>
          <span className={styles.italicText}>
            {determineTimeStringByTimezone(
              parseISO(from),
              locale.fullDateTimeFormat,
              site?.timeZone,
              true
            )}
          </span>
        </div>
      ),
      skeletonCellContent: skeletonCellContent(),
      comparator: comparator('commentType'),
      showSortIcons: false,
    },

    {
      header: 'Comment',
      width: '30%',
      cellContent: ({ comment, createdAt, userId }) => {
        const username = users[userId]
          ? `${users[userId].firstName} ${users[userId].lastName}`
          : 'UNKNOWN';
        return (
          <div className={styles.flexColumn}>
            <span className={styles.comment}>{comment}</span>
            <span
              className={styles.italicText}
            >{`Commented by ${username} at ${determineTimeStringByTimezone(
              parseISO(createdAt),
              locale.fullDateTimeFormat,
              site?.timeZone,
              true
            )}`}</span>
          </div>
        );
      },
      skeletonCellContent: skeletonCellContent(),
      comparator: comparator('commentType'),
      showSortIcons: false,
    },
  ];

  return (
    <Table
      className={styles.table}
      columns={columns}
      data={formattedDataList}
      dataIsLoading={isLoading}
      highlightAlternateRows={false}
    />
  );
};

export default CommentHistoryTable;
