import {
  Site,
  SubscribedIncident,
} from '@energybox/react-ui-library/dist/types';
import {
  Loader,
  PageSelectControl,
} from '@energybox/react-ui-library/dist/components';
import mixpanel from 'mixpanel-browser';

import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { resetFeatureResourceId } from '../../actions/subscribedIncidents';
import useIsIncidentCardsLoading from '../../hooks/useIsIncidentCardsLoading';
import {
  HOME_PAGE_SET_PAGE,
  INCIDENT_CARDS_APPEAR,
} from '../../mixpanelEvents';
import { ApplicationState } from '../../reducers';
import { EquipmentById } from '../../reducers/equipment';
import { SpacesById } from '../../reducers/spaces';
import { determineResource } from '../../utils/subscribedIncidents';
import IncidentCardContainer from '../IncidentCardContainer';
import styles from './IncidentCardsContainer.module.css';

const RENDER_LIMIT = 6;

type Props = {
  site: Site | undefined;
  equipmentById: EquipmentById;
  spacesById: SpacesById;
  subscribedActiveIncidents: SubscribedIncident[];
};

const IncidentCardsContainer: React.FC<Props> = ({
  site,
  equipmentById,
  spacesById,
  subscribedActiveIncidents,
}) => {
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const isPageLoading = useIsIncidentCardsLoading();

  const featureResourceId = useSelector<ApplicationState, number>(
    ({ subscribedIncidents }) => subscribedIncidents.featureResourceId
  );

  const featureIncidentIndex = useMemo(() => {
    if (featureResourceId === -1) return -1;

    return subscribedActiveIncidents.findIndex(
      (i) =>
        Number(i.equipmentId) === featureResourceId ||
        Number(i.spaceId) === featureResourceId
    );
  }, [featureResourceId, subscribedActiveIncidents]);

  useEffect(() => {
    if (featureResourceId !== -1 && featureIncidentIndex !== -1) {
      const pageThatIncludesFeatureIncident = Math.ceil(
        (featureIncidentIndex + 1) / RENDER_LIMIT
      );

      setCurrentPage(pageThatIncludesFeatureIncident);
    }
  }, [featureResourceId, featureIncidentIndex]);

  useEffect(() => {
    return () => {
      dispatch(resetFeatureResourceId());
    };
  }, [dispatch]);

  const renderedIncidents = useMemo(() => {
    const startIndex = (currentPage - 1) * RENDER_LIMIT;
    const endIndex = currentPage * RENDER_LIMIT;

    const result = subscribedActiveIncidents.slice(startIndex, endIndex);
    mixpanel.track(INCIDENT_CARDS_APPEAR, {
      total: result.length,
      siteId: site?.id,
    });
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscribedActiveIncidents, currentPage]);

  if (isPageLoading) {
    return (
      <div className={styles.noDataContainer}>
        <Loader size={24} />
      </div>
    );
  }

  if (!isPageLoading && !site) {
    return (
      <div className={styles.noSiteContainer}>
        Please select preferred site in Setup
      </div>
    );
  }

  const areThereActiveIncidents = subscribedActiveIncidents.length > 0;
  if (!isPageLoading && !areThereActiveIncidents) {
    return <div className={styles.noDataContainer}>No active incidents</div>;
  }

  const pageCount = Math.ceil(subscribedActiveIncidents.length / RENDER_LIMIT);
  return (
    <div className={styles.root}>
      <div className={styles.incidentCardsGrid}>
        {renderedIncidents.map((i: SubscribedIncident) => {
          const incidentResource = determineResource(
            i,
            equipmentById,
            spacesById
          );
          if (incidentResource === undefined) return <></>;
          return (
            <IncidentCardContainer
              key={i.incidentId}
              incident={i}
              resource={incidentResource}
            />
          );
        })}
      </div>

      <div className={styles.pageSelectControlContainer}>
        <PageSelectControl
          pageCount={pageCount}
          currentPage={currentPage}
          setCurrentPage={(newPage: number, eventType) => {
            setCurrentPage(newPage);
            mixpanel.track(HOME_PAGE_SET_PAGE, {
              newPage,
              currentPage,
              eventType,
              pageCount,
            });
          }}
        />
      </div>
    </div>
  );
};

export default IncidentCardsContainer;
