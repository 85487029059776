import { EnergyboxService } from '../config';

export enum Actions {
  DISMISS_NOTIFICATION_SUCCESS = '@@notifications/DISMISS_NOTIFICATION_SUCCESS',
  DISMISS_NOTIFICATION_LOADING = '@@notifications/DISMISS_NOTIFICATION_LOADING',
  DISMISS_NOTIFICATION_ERROR = '@@notifications/DISMISS_NOTIFICATION_ERROR',
}

export const dismissNotification = (id: string, comment: string) => ({
  type: 'API_PUT',
  path: `/api/v1/notifications/${id}/dismiss`,
  payload: {
    dismissedComment: comment,
  },
  service: EnergyboxService.sentinels,
  loading: { type: Actions.DISMISS_NOTIFICATION_LOADING },
  success: { type: Actions.DISMISS_NOTIFICATION_SUCCESS },
  error: { type: Actions.DISMISS_NOTIFICATION_ERROR },
});
