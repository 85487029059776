import {
  Button,
  ButtonProps,
} from '@energybox/react-ui-library/dist/components';
import { Delete } from '@energybox/react-ui-library/dist/icons';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import React from 'react';
import styles from './ModalCloseButton.module.css';

interface Props extends ButtonProps {
  onClose: () => void;
  disabled?: boolean;
}

const ModalCloseButton: React.FC<Props> = (props) => {
  const { onClose, disabled, className, ...buttonAttr } = props;

  return (
    <Button
      className={classNames(styles.crossBtn, className)}
      onClick={onClose}
      disabled={disabled}
      {...buttonAttr}
    >
      <Delete size={23} className={styles.crossIcon} />
    </Button>
  );
};

export default ModalCloseButton;
