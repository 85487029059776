import {
  IdListMapping,
  ResourceType,
  Space,
  SpaceFromApiResponse
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapValues
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/spaces';

export type SpacesById = {
  [id: string]: Space;
};

export interface Spaces {
  spaceIdsBySiteId: IdListMapping;
  spacesById: SpacesById;
  isSpacesBySiteIdLoading: boolean;
}

export const spacesFromApiResponse = (data: any) =>
  ({
    id: data.id,
    title: data.title,
    parentId: data.parentId,
    parent: data.parent,
    description: data.description || '',
    createdAt: data.createdAt,
    updatedAt: data.updatedAt || undefined,
    resourceType: ResourceType[(data._entity as string).toUpperCase()],
    area: data.area
  } as Space);

const initialState = {
  spacesById: {},
  spaceIdsBySiteId: {},
  isSpacesBySiteIdLoading: false
};

const spaces = (state: Spaces = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_SPACES_BY_SITE_ID_LOADING:
      return R.assoc('isSpacesBySiteIdLoading', true, state);

    case Actions.GET_SPACES_BY_SITE_ID_ERROR:
      return R.assoc('isSpacesBySiteIdLoading', false, state);

    case Actions.GET_SPACES_BY_SITE_ID_SUCCESS:
      const apiRes: SpaceFromApiResponse[] = action.data;
      const spaceIds = apiRes.map(({ id }) => id);
      return R.pipe(
        R.assocPath(['spaceIdsBySiteId', [action.siteId]], spaceIds),
        R.assoc(
          'spacesById',
          R.mergeRight(
            R.view(R.lensProp('spacesById'), state),
            mapArrayToObject(mapValues(action.data, spacesFromApiResponse))
          )
        ),
        R.assoc('isSpacesBySiteIdLoading', false)
      )(state);

    default:
      return state;
  }
};

export default spaces;
