import { isDefined } from '@energybox/react-ui-library/dist/utils';
import '@energybox/react-ui-library/dist/root.css';
import mixpanel from 'mixpanel-browser';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { initApp, initAppWithToken } from './actions/app';
import App from './containers/App';
import './index.css';
import * as serviceWorker from './serviceWorker';
import store from './store';

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);

const initMixpanel = () => {
  if (isDefined(process.env.REACT_APP_MIXPANEL_TRACKING_ID)) {
    mixpanel.init(process.env.REACT_APP_MIXPANEL_TRACKING_ID, {
      ignore_dnt: true,
    });
  }
};

try {
  const fragment = window.location.hash.substr(1);
  const params = new URLSearchParams(fragment);
  initMixpanel();
  if (params.has('accessToken')) {
    const accessToken = params.get('accessToken');
    store.dispatch(initAppWithToken({ accessToken }));
  } else {
    store.dispatch(initApp());
  }
} catch (err) {
  // access to window is blocked?
  initMixpanel();
  store.dispatch(initApp());
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
