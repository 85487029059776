import { Site } from '@energybox/react-ui-library/dist/types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getSite } from '../actions/sites';
import { ApplicationState } from '../reducers';

const useGetSite = (siteId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getSite(siteId));
    }
  }, [siteId, dispatch]);

  const site = useSelector<ApplicationState, Site | undefined>(({ sites }) => {
    if (!siteId) return undefined;
    return sites.sitesById[siteId];
  });

  return site;
};

export default useGetSite;
