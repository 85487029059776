import { subDays } from 'date-fns';
import { EnergyboxService } from '../config';
import { createQueryString } from './equipment';

export enum Actions {
  GET_INCIDENTS_SUCCESS = '@@incidents/GET_INCIDENTS_SUCCESS',
  GET_INCIDENTS_LOADING = '@@incidents/GET_INCIDENTS_LOADING',
  GET_INCIDENTS_ERROR = '@@incidents/GET_INCIDENTS_ERROR',
}

type GetIncidentsParams = {
  from: string;
  to: string;
  incidentStatus?: string;
  handlingType?: string;
  priority?: string;
  siteIds?: string[];
  status?: string;
};

export const getIncidents = (params: GetIncidentsParams) => ({
  type: 'API_GET',
  path: `/api/v1/incidents?${createQueryString(params)}`,
  service: EnergyboxService.sentinels,
  loading: { type: Actions.GET_INCIDENTS_LOADING },
  success: { type: Actions.GET_INCIDENTS_SUCCESS },
  error: { type: Actions.GET_INCIDENTS_ERROR },
});

export const getActiveIncidentsForSite = (siteId: number) => {
  const now = new Date();
  const from = subDays(now, 30).toISOString();
  return getIncidents({
    from,
    to: now.toISOString(),
    incidentStatus: 'ACTIVE',
    siteIds: [`${siteId}`],
  });
};
