import mixpanel from 'mixpanel-browser';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { tempCheckSignIn, updateTempCheckSignInField } from '../actions/app';
import { TEMP_CHECK_LOGIN } from '../mixpanelEvents';
import { ApplicationState } from '../reducers';
import { TempCheckSignInForm } from '../reducers/app';
import { ApiError } from '../utils/apiErrorFeedback';
import { useLoading } from './useLoading';
import { usersById } from './useUsers';
import { Actions } from '../actions/app';
import { useError } from './useError';

export const useSignIn = (): {
  form: TempCheckSignInForm;
  updateField: <K extends keyof TempCheckSignInForm>(
    field: K,
    value: TempCheckSignInForm[K]
  ) => void;
  isValid: () => boolean;
  handleLogin: (e: any) => void;
  isSignInSuccess: boolean;
  error: ApiError | null;
  isLoading: boolean;
  clearForm: () => void;
} => {
  const [isSignInSuccess, setIsSignInSuccess] = useState(false);

  const dispatch = useDispatch();
  const users = usersById();

  const { error, clearError } = useError(Actions.TEMP_CHECK_SIGN_IN_ERROR);

  const { isLoading } = useLoading(Actions.TEMP_CHECK_SIGN_IN_LOADING);

  const updateField = useCallback(
    <K extends keyof TempCheckSignInForm>(
      field: K,
      value: TempCheckSignInForm[K]
    ) => {
      dispatch(updateTempCheckSignInField(field, value));
    },
    [dispatch]
  );

  const clearForm = useCallback(() => {
    clearError();
    updateField('password', '');
    updateField('userId', undefined);
  }, [clearError, updateField]);

  useEffect(() => {
    clearForm();
  }, [clearForm]);

  const form = useSelector<ApplicationState, TempCheckSignInForm>(
    ({ app }) => app.tempCheckSignIn.form
  );

  const isValid = useCallback((): boolean => {
    return !!form.userId && form.password !== '';
  }, [form.password, form.userId]);

  const handleLogin = useCallback(
    (e: any) => {
      e.preventDefault();
      if (!isValid()) return;
      const email = users[form.userId ?? 0]?.email ?? '';
      mixpanel.identify(email);
      mixpanel.track(TEMP_CHECK_LOGIN, { $email: email });
      dispatch(
        tempCheckSignIn(email, form.password, (response) => {
          if (!response.error) setIsSignInSuccess(true);
        })
      );
    },
    [dispatch, form.password, form.userId, isValid, users]
  );

  return {
    form,
    updateField,
    isValid,
    handleLogin,
    isSignInSuccess,
    error,
    isLoading,
    clearForm,
  };
};
