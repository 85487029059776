import {
  Button,
  Card,
  InputField,
  Label
} from '@energybox/react-ui-library/dist/components';
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { switchSite } from '../../actions/app';
import styles from './SiteSwitchPage.module.css';

const SiteSwitchPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [siteIdInputField, setSiteIdInputField] = useState('');

  const onSiteIdChange = (e) => setSiteIdInputField(e.target.value);

  const onSubmit = () => {
    if (siteIdInputField && !isNaN(Number(siteIdInputField))) {
      dispatch(switchSite(Number(siteIdInputField)));
    }
  };

  const onHomePageClick = () => {
    history.push('/');
  };

  return (
    <div className={styles.root}>
      <Card className={styles.card}>
        <div className={styles.label}>
          <Label>Switch Site</Label>
        </div>
        <div>
          <InputField
            name="siteId"
            onChange={onSiteIdChange}
            value={siteIdInputField}
          />
        </div>
        <div className={styles.switchButton}>
          <Button onClick={onSubmit}>Switch</Button>
        </div>

        <div className={styles.homeButton}>
          <Button onClick={onHomePageClick}>
            <span>Home Page</span>
          </Button>
        </div>
      </Card>
    </div>
  );
};

export default SiteSwitchPage;
