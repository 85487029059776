import { Alert } from '../reducers/ui';

export enum Actions {
  START_ACTION = '@@ui/START_ACTION',
  STOP_ACTION = '@@ui/STOP_ACTION',

  ERROR_COME = '@@ui/ERROR_COME',
  ERROR_CLEAN = '@@ui/ERROR_CLEAN',

  INSERT_ALERT = '@@ui/INSERT_ALERT',
  REMOVE_ALERT = '@@ui/REMOVE_ALERT',
  UPDATE_ALERTS = '@@ui/UPDATE_ALERTS',
}

export const startAction = (name, payload) => ({
  type: Actions.START_ACTION,
  payload: { name, ...payload },
});

export const stopAction = (name, payload) => ({
  type: Actions.STOP_ACTION,
  payload: { name, ...payload },
});

export const addError = (name, error) => ({
  type: Actions.ERROR_COME,
  payload: { name, error },
});

export const clearError = (name) => ({
  type: Actions.ERROR_CLEAN,
  payload: { name },
});

export const insertAlert = (alert: Alert) => ({
  type: Actions.INSERT_ALERT,
  payload: alert,
});
export const removeAlert = (alert: Alert) => ({
  type: Actions.REMOVE_ALERT,
  payload: alert,
});
export const updateAlerts = (alerts: Alert[]) => ({
  type: Actions.UPDATE_ALERTS,
  payload: alert,
});
