import { trackAutoLogout } from './mixpanel';
import { Actions as AppActions } from '../actions/app';
import store from '../store';
import { EnergyboxService, getServiceUrl } from '../config';

const publicPaths = ['/api/v1/auth/login'];
export const actionExceptionLogout = ['@app/TEMP_CHECK_SIGN_IN'];

const requiresAuthorization = (path: string): boolean => {
  return publicPaths.indexOf(path) === -1;
};

export const genHeaders = (store, action, serviceUrl, token?: string) => {
  const { accessToken } = store.getState().app;
  const { path, ignoreContentType } = action;

  const headers = ignoreContentType
    ? {}
    : {
        'Content-Type': 'application/json',
      };

  if (
    requiresAuthorization(path) &&
    ((accessToken && accessToken !== '') || (token && token !== ''))
  ) {
    headers['authorization'] = `Bearer ${token || accessToken}`;
  }
  if (serviceUrl === getServiceUrl(EnergyboxService.iam)) {
    headers['client'] = 'kiosk';
  }

  if (action.headers) {
    Object.keys(action.headers).forEach((key) => {
      headers[key] = action.headers[key];
    });
  }

  return headers as Headers;
};

export async function fetchApi({
  endpoint = '',
  method = 'GET',
  payload = {},
  service,
}: {
  endpoint: string;
  method?: string;
  payload?: any;
  service?: EnergyboxService;
}): Promise<any> {
  const serviceUrl = getServiceUrl(service);
  const fullUrl = `${serviceUrl}${endpoint}`;
  const headers = genHeaders(store, '', serviceUrl);

  const body = Object.keys(payload).length
    ? JSON.stringify(payload)
    : undefined;

  try {
    const response = await fetch(fullUrl, { method, headers, body });
    if (response.status === 401) {
      trackAutoLogout();
      store.dispatch({ type: AppActions.LOGOUT });
      return Promise.reject(Error('Unauthorized'));
    }

    if (response.status === 403) {
      return Promise.reject(new Error('Forbidden'));
    }

    if (!response.ok) {
      return Promise.reject(new Error(`API request failed ${response.status}`));
    }

    if (response.status === 204) {
      return Promise.resolve();
    }

    const responseContentType = response.headers.get('content-type');

    if (responseContentType) {
      if (
        responseContentType.startsWith('application/json') ||
        responseContentType.startsWith('application/vnd.api+json')
      ) {
        return Promise.resolve(response.json());
      }
    }

    return Promise.resolve(response.text());
  } catch (err) {
    return Promise.reject(err);
  }
}
