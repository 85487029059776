import { UsersApiFilter } from '@energybox/react-ui-library/dist/types';
import { formatAPIFilters } from '@energybox/react-ui-library/dist/utils/util';

const apiBase = `/api/v1/users`;

export enum Actions {
  GET_USERS_SUCCESS = '@users/GET_USERS_SUCCESS',
  GET_USERS_ERROR = '@users/GET_USERS_ERROR',
  GET_USERS_LOADING = '@users/GET_USERS_LOADING',

  GET_USER_SUCCESS = '@users/GET_USER_SUCCESS',
  GET_USER_ERROR = '@users/GET_USER_ERROR',
  GET_USER_LOADING = '@users/GET_USER_LOADING',

  USER_CHANGE_PASSWORD_LOADING = '@users/USER_CHANGE_PASSWORD_LOADING',
  USER_CHANGE_PASSWORD_ERROR = '@users/USER_CHANGE_PASSWORD_ERROR',
  USER_CHANGE_PASSWORD_SUCCESS = '@users/USER_CHANGE_PASSWORD_SUCCESS',

  DISPLAY_FORM_ERRORS = '@users/DISPLAY_FORM_ERRORS',
}

export const getUsers = (filter?: UsersApiFilter) => {
  const queryParams = formatAPIFilters<UsersApiFilter>(filter);

  return {
    type: 'API_GET',
    path: `${apiBase}?${queryParams}`,
    success: Actions.GET_USERS_SUCCESS,
    error: Actions.GET_USERS_ERROR,
    loading: Actions.GET_USERS_LOADING,
  };
};

export const getUser = (id: string) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_USER_SUCCESS,
  error: Actions.GET_USER_ERROR,
  loading: Actions.GET_USER_LOADING,
});

export const updateUserPassword = (
  id: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_PATCH',
  path: `${apiBase}/${id}/password`,
  payload: {
    password,
    passwordVerify,
  },
  success: Actions.USER_CHANGE_PASSWORD_SUCCESS,
  error: Actions.USER_CHANGE_PASSWORD_ERROR,
  loading: Actions.USER_CHANGE_PASSWORD_LOADING,
});
