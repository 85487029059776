import React from 'react';
import classNames from '../../../utils/classNames';
import styles from '../Modal.module.css';

interface Props {
  className?: string;
  children?: React.ReactNode;
}

const ModalFooter: React.FC<Props> = (props) => {
  const { children, className } = props;

  return (
    <div className={classNames(styles.modalFooter, className)}>{children}</div>
  );
};

export default ModalFooter;
