import { EnergyboxService } from '../config';

export enum Actions {
  GET_WEATHER_BY_SITE_ID_SUCCESS = '@@weather/GET_WEATHER_BY_SITE_ID_SUCCESS',
  GET_WEATHER_BY_SITE_ID_LOADING = '@@weather/GET_WEATHER_BY_SITE_ID_LOADING',
  GET_WEATHER_BY_SITE_ID_ERROR = '@@weather/GET_WEATHER_BY_SITE_ID_ERROR',
}

export const getWeatherBySiteId = (siteId: number) => ({
  type: 'API_GET',
  service: EnergyboxService.weather,
  path: `/weather/${siteId}/latest`,
  success: { type: Actions.GET_WEATHER_BY_SITE_ID_SUCCESS, siteId },
  loading: { type: Actions.GET_WEATHER_BY_SITE_ID_LOADING, siteId },
  error: { type: Actions.GET_WEATHER_BY_SITE_ID_ERROR, siteId },
});
