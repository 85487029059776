import { useEffect, useState } from 'react';

const useCountDown = (initialCountdown: number) => {
  const [countdown, setCountdown] = useState(initialCountdown);

  useEffect(() => {
    const interval = setInterval(() => {
      if (countdown > 0) {
        setCountdown(countdown - 1);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [countdown]);

  return countdown;
};

export default useCountDown;
