import mixpanel from 'mixpanel-browser';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { logout } from '../../actions/app';
import useCurrentUser from '../../hooks/useCurrentUser';
import { LOGOUT_MANUAL } from '../../mixpanelEvents';
import { removeCredential } from '../../middlewares/authMiddleware';

const LogoutPageContainer = () => {
  const dispatch = useDispatch();
  const currentUser = useCurrentUser();

  useEffect(() => {
    mixpanel.track(LOGOUT_MANUAL, { $email: currentUser?.email });
    removeCredential();
    dispatch(logout());
  }, [currentUser, dispatch]);

  return <div />;
};

export default LogoutPageContainer;
