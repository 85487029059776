import React from 'react';
import CommentHistoryTable from '../../components/Table/CommentHistoryTable/CommentHistoryTable';
import useGetCommentList from '../../hooks/useGetCommentList';
import styles from './CommentHistoryContainer.module.css';
import { useCurrentSite } from '../../hooks/useCurrentSite';
import { DateTime } from 'luxon';
import { useGetUsers } from '../../hooks/useUsers';

interface Props {
  sensorId?: number;
  equipmentId?: number;
  spaceId?: number;
}

const CommentHistoryContainer: React.FC<Props> = (props) => {
  const { equipmentId } = props;

  const site = useCurrentSite();

  const nowInDateTime = DateTime.fromJSDate(new Date()).setZone(site?.timeZone);
  const sevenDaysAgo = nowInDateTime.minus({ days: 7 }).startOf('day');

  // fetch data
  const { commentList, isLoading } = useGetCommentList(equipmentId ?? 0, {
    from: new Date(sevenDaysAgo.valueOf()).toISOString(),
    to: new Date(nowInDateTime.valueOf()).toISOString(),
  });
  useGetUsers();

  return (
    <>
      {commentList.length > 0 || isLoading ? (
        <CommentHistoryTable commentList={commentList} isLoading={isLoading} />
      ) : (
        <div className={styles.noData}>No data</div>
      )}
    </>
  );
};

export default CommentHistoryContainer;
