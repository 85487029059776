import { CurrentUser } from '@energybox/react-ui-library/dist/types';
import { useSelector } from 'react-redux';
import { ApplicationState } from '../reducers';

export default function useCurrentUser() {
  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => app.currentUser
  );
  return currentUser;
}

export const useCurrentTempCheckUser = (): CurrentUser | undefined => {
  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => app.currentTempCheckUser
  );
  return currentUser;
};

export function useCurrentOrgId() {
  const currentOrgId = useSelector<ApplicationState, number | undefined>(
    ({ app }) => app.currentOrganizationId
  );
  return currentOrgId;
}

export function useCurrentSiteId() {
  const currentUser = useSelector<ApplicationState, CurrentUser | undefined>(
    ({ app }) => app.currentUser
  );
  return currentUser?.kiosk?.preferredSiteId;
}
