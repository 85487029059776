import { LoginPage } from '@energybox/react-ui-library/dist/components';

import React from 'react';
import { connect } from 'react-redux';
import {
  Actions as AppActions,
  login,
  updateLoginField,
} from '../../actions/app';
import { getCredential } from '../../middlewares/authMiddleware';
import { ApplicationState } from '../../reducers';
import { Routes } from '../../routes';
import { ApiError, renderAPIerror } from '../../utils/apiErrorFeedback';
import { trackLogin } from '../../utils/mixpanel';
import * as v from '../../validators';

interface Props {
  email: string;
  password: string;
  isPasswordReset: boolean;
  apiError: ApiError;
  login: () => void;
  onChange: (field: string, value: string) => void;
}

class LoginPageContainer extends React.Component<Props> {
  validateForm = () => {
    const { email, password } = this.props;
    return email !== '' && password !== '' && v.isEmail(email);
  };

  handleLogin = (e: any) => {
    if (e) e.preventDefault();
    const { email } = this.props;
    trackLogin(email, !!e);
    this.props.login();
  };

  componentDidMount(): void {
    const credential = getCredential();
    if (credential) {
      Object.keys(credential).forEach((k) =>
        this.props.onChange(k, credential[k])
      );
      this.handleLogin(null);
    }
  }

  render() {
    const { email, password, isPasswordReset, onChange, apiError } = this.props;

    return (
      <LoginPage
        email={email}
        password={password}
        isPasswordReset={isPasswordReset}
        apiError={renderAPIerror(apiError, AppActions.LOGIN_ERROR)}
        validateForm={this.validateForm}
        handleLogin={this.handleLogin}
        handleChange={onChange}
        forgotPasswordRoute={Routes.FORGOT_PASSWORD}
        backgroundImage={`${process.env.PUBLIC_URL}/login_background.jpg`}
      />
    );
  }
}

const mapStateToProps = ({ app }: ApplicationState) => ({
  email: app.loginForm.email,
  password: app.loginForm.password,
  isPasswordReset: app.isPasswordReset,
  apiError: app.apiError,
});

const mapDispatchToProps = {
  onChange: updateLoginField,
  login,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginPageContainer);
