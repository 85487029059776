import { classNames } from '@energybox/react-ui-library/dist/utils';
import React, { useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import styles from './SwipesWrapper.module.css';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  currentPage: number;
  setCurrentPage: (page: number) => void;
  totalPage: number;
  refreshData?: () => void;
  isScrollable?: boolean;
}

const componentID = 'swipes_wrapper';

const SwipesWrapper: React.FC<Props> = (props) => {
  const {
    children,
    currentPage,
    totalPage,
    refreshData,
    setCurrentPage,
    id = componentID,
    isScrollable,
    ...divProps
  } = props;

  const [isTop, setIsTop] = useState<boolean>(true);
  const [isBottom, setIsBottom] = useState<boolean>(false);

  const onPageChange = (action: 'PREVIOUS' | 'NEXT') => {
    if (action === 'PREVIOUS' && currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    } else if (action === 'NEXT' && currentPage !== totalPage) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedDown: (e) => {
      if (isScrollable && !isTop) {
        return;
      }

      if (currentPage === 1) {
        if (!!refreshData) refreshData();
      } else {
        onPageChange('PREVIOUS');
      }
    },
    onSwipedUp: (e) => {
      if (isScrollable && !isBottom) {
        return;
      }

      if (currentPage === totalPage) {
        if (!!refreshData) refreshData();
      } else {
        onPageChange('NEXT');
      }
    },
  });

  const onScroll = (event) => {
    const checkIsBottom =
      event.target.scrollHeight - Math.ceil(event.target.scrollTop) ===
      event.target.clientHeight;
    const checkIsTop = Math.ceil(event.target.scrollTop) === 0;

    setIsBottom(checkIsBottom);
    setIsTop(checkIsTop);
  };

  return (
    <div
      {...handlers}
      {...divProps}
      id={id}
      className={classNames(
        isScrollable ? styles.scrollable : '',
        divProps.className
      )}
      onScroll={onScroll}
    >
      {children}
    </div>
  );
};

export default SwipesWrapper;
