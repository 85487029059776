import {
  DateFormat,
  MeasurementSystem,
  TimeFormat,
  User
} from '@energybox/react-ui-library/dist/types';
import {
  mapArrayToObject,
  mapScopeToAccessResource,
  mapValues
} from '@energybox/react-ui-library/dist/utils';
import * as R from 'ramda';
import { Actions } from '../actions/users';

export interface EditableFields {
  firstName: string;
  lastName: string;
  email: string;

  groupIds?: number[];
  position?: string;
  organizationUnitId?: number;
  organizationUnitTitle?: string;
  timeZone?: string;
  measurementSystem?: MeasurementSystem;
  timeFormat?: TimeFormat;
  dateFormat?: DateFormat;

  password?: string;
}

export interface EditableApiKeyFields {
  title: string;
  description: string;
}

export type UsersById = {
  [id: string]: User;
};

export interface Users {
  usersById: UsersById;
  userPositions?: string[];
  loadingStatusByAction: ActionsLoadingStatus;
  didPasswordChangeFail: boolean;
}

export type ActionsLoadingStatus = {
  [Actions.GET_USERS_LOADING]?: boolean;
};

function userFromApiResponse(data: any): User {
  return {
    id: data.id,
    firstName: data.firstName,
    lastName: data.lastName,
    email: data.email,
    lastLogin: data.lastLoginAt,
    role: data.role,
    position: data.position,
    timeZone: data.timeZone,
    measurementSystem: data.measurementSystem,
    timeFormat: data.timeFormat,
    dateFormat: data.dateFormat,
    organizationUnitTitle: data.organizationUnit && data.organizationUnit.title,
    organizationUnitId: data.organizationUnit && data.organizationUnit.id,
    accessResources: data.scopes.map(mapScopeToAccessResource),
    groupIds: data.groups ? data.groups.map((g) => g.id) : [],
    kiosk: data.kiosk || null
  };
}

export const initialState = {
  usersById: {},
  loadingStatusByAction: {},
  didPasswordChangeFail: false
};

export const users = (state: Users = initialState, action: any) => {
  switch (action.type) {
    case Actions.GET_USERS_SUCCESS:
      return R.pipe(
        R.assoc(
          'usersById',
          mapArrayToObject(mapValues(action.data, userFromApiResponse))
        ),
        R.assocPath(['loadingStatusByAction', Actions.GET_USERS_LOADING], false)
      )(state);

    case Actions.GET_USERS_LOADING:
      return R.assocPath(
        ['loadingStatusByAction', Actions.GET_USERS_LOADING],
        true,
        state
      );

    case Actions.GET_USER_SUCCESS:
      let user = userFromApiResponse(action.data);

      return R.pipe(R.assocPath(['usersById', action.data.id], user))(state);

    case Actions.USER_CHANGE_PASSWORD_LOADING:
    case Actions.USER_CHANGE_PASSWORD_SUCCESS:
      return R.assoc('didPasswordChangeFail', false, state);

    case Actions.USER_CHANGE_PASSWORD_ERROR:
      return R.assoc('didPasswordChangeFail', true, state);

    default:
      return state;
  }
};

export default users;
