import {
  ResourcePath,
  ResourceType
} from '@energybox/react-ui-library/dist/types';
import * as R from 'ramda';
import { Actions } from '../actions/paths';

const initialState = {
  byId: {},
  isLoadingById: {}
};

type ResourceTypeWithEntity = {
  _entity: ResourceType;
} & ResourcePath;

function mapTargetTypes(
  resource: ResourceTypeWithEntity
): ResourcePath | undefined {
  switch (resource._entity) {
    case ResourceType.ORGANIZATION:
      return;

    default:
      return {
        title: resource.title,
        id: resource.id,
        type: resource._entity
      };
  }
}

export type ResourcePaths = {
  byId: PathsById;
  isLoadingById: IsPathLoadingById;
};

export type IsPathLoadingById = {
  [id: string]: boolean;
};

export type PathsById = {
  [id: string]: ResourcePath[];
};

const resourcePaths = (state: ResourcePaths = initialState, action: any) => {
  switch (action.type) {
    case Actions.PATH_SUCCESS:
      return R.pipe(
        R.assocPath(
          ['byId', action.id],
          (action.data || []).map((r) => mapTargetTypes(r)).filter((p) => p)
        ),
        R.assocPath(['isLoadingById', action.id], false)
      )(state);

    case Actions.PATH_LOADING:
      return R.assocPath(['isLoadingById', action.id], true, state);

    case Actions.PATH_ERROR:
      return R.assocPath(['isLoadingById', action.id], false, state);

    case Actions.CLEAR_RESOURCE_PATH:
      return R.dissocPath(['byId', action.id], state);
    default:
      return state;
  }
};

export default resourcePaths;
