import React, { HTMLAttributes } from 'react';
import classNames from '../../../utils/classNames';
import styles from '../Modal.module.css';

interface Props extends HTMLAttributes<HTMLDivElement> {
  className?: string;
  children?: React.ReactNode;
}

const ModalTitle: React.FC<Props> = (props) => {
  const { children, className, ...divAttr } = props;

  return (
    <div className={classNames(styles.modalTitle, className)} {...divAttr}>
      {children}
    </div>
  );
};

export default ModalTitle;
