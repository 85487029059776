import {
  Divider,
  Modal,
  ModalContent,
  Orientation,
} from '@energybox/react-ui-library/dist/components';
import { theme } from '@energybox/react-ui-library/dist/utils/withTheme';
import React from 'react';
import CommentHistoryContainer from '../../../containers/CommentHistoryContainer/CommentHistoryContainer';
import { useEquipment } from '../../../hooks/useEquipment';
import { useSpace } from '../../../hooks/useSpace';
import ModalCloseButton from '../ModalCloseButton/ModalCloseButton';
import styles from './CommentHistoryModal.module.css';
import { ModalTitle as KioskModalTitle } from '../index';
interface Props {
  equipmentId?: number;
  spaceId?: number;
  onClose: () => void;
}

const CommentHistoryModal: React.FC<Props> = (props) => {
  const { onClose, equipmentId, spaceId } = props;

  const sensor = useEquipment(equipmentId ?? 0);
  const space = useSpace(spaceId ?? 0);

  return (
    <Modal containerClassName={styles.modalRoot}>
      <KioskModalTitle
        className={styles.header}
        style={{ color: theme.typography.body1.color }}
      >
        <span className={styles.headerTitle}>Comment History</span>
        <div className={styles.flex}>
          <span className={styles.headerSubTitle}>{sensor?.title ?? ''}</span>
          <Divider
            orientation={Orientation.VERTICAL}
            className={styles.divider}
          />
          <span className={styles.headerSubTitle}>{space?.title ?? ''}</span>
        </div>
      </KioskModalTitle>

      <ModalCloseButton onClose={onClose} className={styles.button} />

      <ModalContent className={styles.modalContent}>
        <CommentHistoryContainer equipmentId={equipmentId} spaceId={spaceId} />
      </ModalContent>
    </Modal>
  );
};

export default CommentHistoryModal;
