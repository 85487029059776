export enum Actions {
  DISMISS_INCIDENT_ALERT = '@incidents/DISMISS_INCIDENT_ALERT',

  SET_FEATURE_RESOURCE_ID = '@incidents/SET_FEATURE_RESOURCE_ID',
  RESET_FEATURE_RESOURCE_ID = '@incidents/RESET_FEATURE_RESOURCE_ID',

  SET_INITIAL_PAGE_LOADING = '@incidents/SET_INITIAL_PAGE_LOADING'
}

export const dismissIncidentAlert = (
  resourceId: number | string,
  sentinelId: string
) => ({
  type: Actions.DISMISS_INCIDENT_ALERT,
  resourceId,
  sentinelId
});

export const setFeatureResourceId = (resourceId: number) => ({
  type: Actions.SET_FEATURE_RESOURCE_ID,
  resourceId
});

export const resetFeatureResourceId = () => ({
  type: Actions.RESET_FEATURE_RESOURCE_ID
});

export const setInitialPageLoading = (value: boolean) => ({
  type: Actions.SET_INITIAL_PAGE_LOADING,
  value
});
