import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getSpacesBySiteId } from '../actions/spaces';
import equals from 'ramda/src/equals';

import { ApplicationState } from '../reducers';
import { SpacesById } from '../reducers/spaces';

const useGetSpacesBySiteId = (siteId: number | undefined) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (siteId) {
      dispatch(getSpacesBySiteId(siteId));
    }
  }, [dispatch, siteId]);

  const spaceIds = useSelector<ApplicationState, number[] | undefined>(
    ({ spaces }) => {
      if (!siteId) return undefined;
      return spaces.spaceIdsBySiteId[siteId];
    },
    equals
  );

  const spacesById = useSelector<ApplicationState, SpacesById>(({ spaces }) => {
    return spaces.spacesById;
  }, equals);

  return { spaceIds, spacesById };
};

export default useGetSpacesBySiteId;
