import {
  Button,
  Checkbox,
  InputField,
  Loader,
  Modal,
  ModalContent,
  ModalTitle
} from '@energybox/react-ui-library/dist/components';
import { classNames } from '@energybox/react-ui-library/dist/utils';
import mixpanel from 'mixpanel-browser';

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { dismissNotification } from '../../actions/notifications';
import { DISMISS_NOTIFICATION } from '../../mixpanelEvents';
import { ApplicationState } from '../../reducers';
import styles from './DismissNotificationModal.module.css';

interface OwnProps {
  notificationId: string;
  onClose: () => void;
}

interface Props extends OwnProps {
  isDismissLoading: boolean;
  dismissNotification: (id: string, comment: string) => {};
}

type State = {
  selectedOption: string;
  comment: string;
};

const labels = [
  'Thanks but not relevant.',
  'Will solve this shortly.',
  'Might need some help.'
];

class DismissNotificationModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOption: '',
      comment: ''
    };
  }

  handleSubmit = () => {
    let { comment, selectedOption } = this.state;
    const { onClose, dismissNotification, notificationId } = this.props;

    if (selectedOption !== 'comment') comment = selectedOption;

    dismissNotification(notificationId, comment);
    // need to include incident priority
    mixpanel.track(DISMISS_NOTIFICATION, { notificationId, comment });
    onClose();
  };

  handleCommentChange = (e: any) => {
    const comment = e.currentTarget.value;
    this.setState({ comment });
  };

  selectOption = (value: string) => this.setState({ selectedOption: value });

  renderCheckBoxes = (): any =>
    labels.map((label) => (
      <Checkbox
        className={styles.borderBottom}
        onChange={() => this.selectOption(label)}
        checked={label === this.state.selectedOption}
        size={20}
        label={label}
        name={label}
        key={label}
      />
    ));

  render() {
    const commentSelected = this.state.selectedOption === 'comment';
    const { isDismissLoading } = this.props;
    const { comment, selectedOption } = this.state;

    const formIsValid =
      (selectedOption === 'comment' && comment !== '') ||
      labels.includes(selectedOption);

    const actions = (
      <>
        <Button onClick={this.props.onClose} variant="text">
          Cancel
        </Button>
        <Button onClick={this.handleSubmit} disabled={formIsValid === false}>
          Submit
        </Button>
      </>
    );

    return (
      <Modal className={styles.modal} actions={actions}>
        <ModalTitle className={styles.modalTitle}>
          Dismiss Notification
        </ModalTitle>
        <ModalContent className={styles.modalContent}>
          <div
            className={styles.radioGroup}
            style={{ opacity: isDismissLoading ? 0.4 : 1 }}
          >
            {this.renderCheckBoxes()}
            <Checkbox
              className={classNames(styles.checkboxWithComment)}
              onChange={() => this.selectOption('comment')}
              checked={commentSelected}
              size={20}
              name="comment"
              label={
                <InputField
                  className={styles.inputField}
                  placeholder="Enter a comment..."
                  value={this.state.comment}
                  onFocus={() => this.selectOption('comment')}
                  onChange={this.handleCommentChange}
                />
              }
            />
          </div>

          {isDismissLoading && (
            <div className={styles.loading}>
              <Loader variant="default" />
            </div>
          )}
        </ModalContent>
      </Modal>
    );
  }
}

const mapStateToProps = ({ notifications }: ApplicationState) => ({
  isDismissLoading: notifications.isDismissNotificationLoading
});

const mapDispatchToProps = {
  dismissNotification
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DismissNotificationModal);
