import {
  Modal,
  ModalContent,
  ModalTitle,
} from '@energybox/react-ui-library/dist/components';
import React, { Component } from 'react';
import styles from './PopupModal.module.css';

import Door from './icons/Door';
import HumidityIcon from './icons/Humidity';
import Temperature from './icons/Temperature';
import classNames from '../../utils/classNames';
import { ModalCloseButton } from '../Modal';

interface Props {
  className?: string;
  status?: 'normal' | 'medium' | 'high' | 'highest' | 'critical';
  primaryMeasurement?: 'temperature' | 'door' | 'humidity';
  titleText?: string;
  humidity?: React.ReactNode;
  door?: React.ReactNode;
  temperature?: React.ReactNode;
  incidentTimeText: string;
  incidentText?: string;
  incidentSubtext?: string;
  dismissed?: boolean;
  onClose: () => void;
}

type State = {};

class PopupModal extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      titleText,
      status,
      humidity,
      door,
      temperature,
      incidentTimeText,
      incidentSubtext,
      dismissed,
    } = this.props;

    return (
      <Modal className={styles.modal}>
        <ModalTitle className={styles.modalTitleRoot}>
          <ModalCloseButton onClose={this.props.onClose} />
        </ModalTitle>
        <ModalContent className={styles.modalContent}>
          <div className={styles.temperatureContainer}>
            <div className={styles.modalTitle}>{titleText}</div>
            <div className={styles.tempBox}>
              {temperature && (
                <div className={styles.icons}>
                  {Temperature}
                  <span className={styles.valueText}>{temperature}</span>
                </div>
              )}

              {door && (
                <div className={styles.icons}>
                  {Door}
                  <span className={styles.valueText}>{door}</span>
                </div>
              )}
            </div>
            <div className={styles.tempBox}>
              {humidity && (
                <div className={styles.icons}>
                  {HumidityIcon}
                  <span className={styles.valueText}>{humidity}</span>
                </div>
              )}
            </div>
          </div>

          <div
            className={classNames(
              styles[`contentDescription`],
              dismissed ? styles['dismissed-bottom-text-color'] : '',
              dismissed
                ? styles['dismissed-background-bottom']
                : styles[`${status}-background`]
            )}
          >
            <div className={styles.contentText}>
              {dismissed ? (
                <span
                  className={styles['dismissed-text']}
                >{`Dismissed: `}</span>
              ) : (
                ''
              )}{' '}
              <span>{incidentSubtext}</span>
            </div>
            <div className={styles.timeAgo}>
              <span>{incidentTimeText}</span>
            </div>
          </div>
        </ModalContent>
      </Modal>
    );
  }
}

export default PopupModal;
