import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Switch } from 'react-router-dom';
import history from '../history';
import { ApplicationState } from '../reducers';

import Route from './SecureRoute';
import LoginPageContainer from './LoginPageContainer';
import ForgotPasswordPageContainer from './Password/ForgotPasswordPageContainer';
import NewPasswordPageContainer from './Password/NewPasswordPageContainer';
import LogoutPageContainer from './Password/LogoutPageContainer';
import PrimaryLayout from '../components/PrimaryLayout';
import { Routes } from '../routes';
import { ConnectedRouter } from 'connected-react-router';
import { refreshToken } from '../actions/app';

const App: React.FC = () => {
  const dispatch = useDispatch();
  const isInitialized = useSelector<ApplicationState, boolean>(({ app }) => {
    return app.isInitialized;
  });

  // refresh the auth token every browser refreshed
  useEffect(() => {
    dispatch(refreshToken());
  }, [dispatch]);

  if (!isInitialized) {
    return <div>Loading...</div>;
  }

  return (
    <ConnectedRouter history={history}>
      <Switch>
        <Route path={Routes.LOGIN} component={LoginPageContainer} />
        <Route
          path={Routes.FORGOT_PASSWORD}
          component={ForgotPasswordPageContainer}
        />
        <Route
          path={Routes.NEW_PASSWORD}
          component={NewPasswordPageContainer}
        />
        <Route path={Routes.LOGOUT} component={LogoutPageContainer} />
        <Route path={Routes.BASE} component={PrimaryLayout} />
      </Switch>
    </ConnectedRouter>
  );
};

export default App;
