import { saveCredential } from '../middlewares/authMiddleware';
import { ApplicationState } from '../reducers';
import { TempCheckSignInForm } from '../reducers/app';

export enum Actions {
  INIT = '@app/INIT',
  INIT_WITH_TOKEN = '@app/INIT_WITH_TOKEN',
  ACCESS_TOKEN_UPDATED = '@app/ACCESS_TOKEN_UPDATED',
  TEMP_CHECK_TOKEN_UPDATED = '@app/TEMP_CHECK_TOKEN_UPDATED',
  REDIRECT = '@app/REDIRECT',
  RESET_SOCKET_CONNECTION = '@app/RESET_SOCKET_CONNECTION',

  LOGIN_SUCCESS = '@app/LOGIN_SUCCESS',
  LOGIN_LOADING = '@app/LOGIN_LOADING',
  LOGIN_ERROR = '@app/LOGIN_ERROR',
  UPDATE_LOGIN_FIELD = '@app/UPDATE_LOGIN_FIELD',

  REFRESH_TOKEN_SUCCESS = '@app/REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_LOADING = '@app/REFRESH_TOKEN_LOADING',
  REFRESH_TOKEN_ERROR = '@app/REFRESH_TOKEN_ERROR',

  LOGOUT = '@app/LOGOUT',
  LOGOUT_TEMP_CHECK = '@app/LOGOUT_TEMP_CHECK',

  FORGOT_PASSWORD_SUCCESS = '@app/FORGOT_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_LOADING = '@app/FORGOT_PASSWORD_LOADING',
  FORGOT_PASSWORD_ERROR = '@app/FORGOT_PASSWORD_ERROR',
  UPDATE_FORGOT_PASSWORD_FIELD = '@app/UPDATE_FORGOT_PASSWORD_FIELD',

  TEMP_CHECK_SIGN_IN_SUCCESS = '@app/TEMP_CHECK_SIGN_IN_SUCCESS',
  TEMP_CHECK_SIGN_IN_LOADING = '@app/TEMP_CHECK_SIGN_IN_LOADING',
  TEMP_CHECK_SIGN_IN_ERROR = '@app/TEMP_CHECK_SIGN_IN_ERROR',
  UPDATE_TEMP_CHECK_SIGN_IN_FIELD = '@app/UPDATE_TEMP_CHECK_SIGN_IN_FIELD',

  RESET_PASSWORD_SUCCESS = '@app/RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_LOADING = '@app/RESET_PASSWORD_LOADING',
  RESET_PASSWORD_ERROR = '@app/RESET_PASSWORD_ERROR',
  UPDATE_RESET_PASSWORD_FIELD = '@app/UPDATE_RESET_PASSWORD_FIELD',

  CURRENT_USER_SUCCESS = '@app/CURRENT_USER_SUCCESS',
  CURRENT_USER_ERROR = '@app/CURRENT_USER_ERROR',
  CURRENT_USER_LOADING = '@app/CURRENT_USER_LOADING',

  TEMP_CHECK_USER_SUCCESS = '@app/TEMP_CHECK_USER_SUCCESS',
  TEMP_CHECK_USER_ERROR = '@app/TEMP_CHECK_USER_ERROR',
  TEMP_CHECK_USER_LOADING = '@app/TEMP_CHECK_USER_LOADING',

  GET_EQUIPMENT_TYPES_SUCCESS = '@app/GET_EQUIPMENT_TYPES_SUCCESS',
  GET_EQUIPMENT_TYPES_ERROR = '@app/GET_EQUIPMENT_TYPES_ERROR',
  GET_EQUIPMENT_TYPES_LOADING = '@app/GET_EQUIPMENT_TYPES_LOADING',

  GET_EQUIPMENT_GROUPS_SUCCESS = '@app/GET_EQUIPMENT_GROUPS_SUCCESS',
  GET_EQUIPMENT_GROUPS_ERROR = '@app/GET_EQUIPMENT_GROUPS_ERROR',
  GET_EQUIPMENT_GROUPS_LOADING = '@app/GET_EQUIPMENT_GROUPS_LOADING',

  CHANGE_ORGANIZATION_SUCCESS = '@app/CHANGE_ORGANIZATION_SUCCESS',
  CHANGE_ORGANIZATION_ERROR = '@app/CHANGE_ORGANIZATION_ERROR',
  CHANGE_ORGANIZATION_LOADING = '@app/CHANGE_ORGANIZATION_LOADING',

  SWITCH_SITE = '@app/SWITCH_SITE',
}

export const initApp = () => ({
  type: Actions.INIT,
});

export const initAppWithToken = ({ accessToken }) => ({
  type: Actions.INIT_WITH_TOKEN,
  data: { accessToken },
});

export const logout = () => ({
  type: Actions.LOGOUT,
});

export const logoutTempCheck = () => ({
  type: Actions.LOGOUT_TEMP_CHECK,
});

export const login = () => (
  dispatch: any,
  getState: () => ApplicationState
) => {
  const { email, password } = getState().app.loginForm;
  saveCredential({ email, password });

  dispatch({
    type: 'API_POST',
    path: '/api/v1/auth/login',
    payload: { email, password },
    success: Actions.LOGIN_SUCCESS,
    error: Actions.LOGIN_ERROR,
    loading: Actions.LOGIN_LOADING,
  });
};

export const tempCheckSignIn = (
  email: string,
  password: string,
  callback?: (resp: any) => void
) => (dispatch: any, getState: () => ApplicationState) => {
  const siteId = getState().app.currentUser?.kiosk?.preferredSiteId;

  if (!siteId) return;

  dispatch({
    type: 'API_POST',
    path: '/api/v1/auth/login',
    payload: { email, password },
    success: Actions.TEMP_CHECK_SIGN_IN_SUCCESS,
    error: Actions.TEMP_CHECK_SIGN_IN_ERROR,
    loading: Actions.TEMP_CHECK_SIGN_IN_LOADING,
    callback,
  });
};

export const refreshToken = () => ({
  type: 'API_POST',
  path: '/api/v1/auth/refresh-token',
  success: Actions.REFRESH_TOKEN_SUCCESS,
  error: Actions.REFRESH_TOKEN_ERROR,
  loading: Actions.REFRESH_TOKEN_LOADING,
});

export const updateLoginField = (field: string, value: string) => ({
  type: Actions.UPDATE_LOGIN_FIELD,
  field: field,
  value: value,
});

export const updateTempCheckSignInField = <K extends keyof TempCheckSignInForm>(
  field: K,
  value: TempCheckSignInForm[K]
) => ({
  type: Actions.UPDATE_TEMP_CHECK_SIGN_IN_FIELD,
  field: field,
  value: value,
});

export const getCurrentUser = () => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  success: Actions.CURRENT_USER_SUCCESS,
  error: Actions.CURRENT_USER_ERROR,
  loading: Actions.CURRENT_USER_LOADING,
});

export const getTempCheckCurrentUser = (token: string) => ({
  type: 'API_GET',
  path: '/api/v1/auth/user',
  token,
  success: Actions.TEMP_CHECK_USER_SUCCESS,
  error: Actions.TEMP_CHECK_USER_ERROR,
  loading: Actions.TEMP_CHECK_USER_LOADING,
});

export const getEquipmentGroups = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-groups',
  success: Actions.GET_EQUIPMENT_GROUPS_SUCCESS,
  error: Actions.GET_EQUIPMENT_GROUPS_ERROR,
  loading: Actions.GET_EQUIPMENT_GROUPS_LOADING,
});

export const getEquipmentTypes = () => ({
  type: 'API_GET',
  path: '/api/v1/equipment-types',
  success: Actions.GET_EQUIPMENT_TYPES_SUCCESS,
  error: Actions.GET_EQUIPMENT_TYPES_ERROR,
  loading: Actions.GET_EQUIPMENT_TYPES_LOADING,
});

export const forgotPassword = (email: string) => ({
  type: 'API_POST',
  payload: { email, website: 'CONNECT' },
  path: '/api/v1/users/password-forget',
  success: Actions.FORGOT_PASSWORD_SUCCESS,
  error: Actions.FORGOT_PASSWORD_ERROR,
  loading: Actions.FORGOT_PASSWORD_LOADING,
});

export const resetPassword = (
  email: string,
  token: string,
  password: string,
  passwordVerify: string
) => ({
  type: 'API_POST',
  payload: { email, token, password, passwordVerify },
  path: '/api/v1/users/password-forget-confirm',
  success: Actions.RESET_PASSWORD_SUCCESS,
  error: Actions.RESET_PASSWORD_ERROR,
  loading: Actions.RESET_PASSWORD_LOADING,
});

export const updateForgotPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_FORGOT_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const updateResetPasswordField = (field: string, value: string) => ({
  type: Actions.UPDATE_RESET_PASSWORD_FIELD,
  field: field,
  value: value,
});

export const switchSite = (siteId: number) => ({
  type: Actions.SWITCH_SITE,
  value: siteId,
});
