import { SitesApiFilter } from '@energybox/react-ui-library/dist/types';

const apiBase = '/api/v1/sites';

export enum Actions {
  GET_SITE_SUCCESS = '@sites/GET_SITE_SUCCESS',
  GET_SITE_ERROR = '@sites/GET_SITE_ERROR',
  GET_SITE_LOADING = '@sites/GET_SITE_LOADING'
}

export const getSite = (id: number) => ({
  type: 'API_GET',
  path: `${apiBase}/${id}`,
  success: Actions.GET_SITE_SUCCESS,
  error: Actions.GET_SITE_ERROR,
  loading: Actions.GET_SITE_LOADING
});

export const setSitesFilter = (filter?: SitesApiFilter): string => {
  const queryParams = new URLSearchParams();

  if (filter) {
    if (filter.equipmentIds) {
      queryParams.set('equipmentIds', filter.equipmentIds.join(','));
    }

    if (filter.sensorIds) {
      queryParams.set('sensorIds', filter.sensorIds.join(','));
    }

    if (filter.spaceIds) {
      queryParams.set('spaceIds', filter.spaceIds.join(','));
    }

    if (filter.sensorTypes && filter.sensorTypes.length > 0) {
      queryParams.set('sensorTypes', filter.sensorTypes.join(','));
    }

    if (filter.sensorTarget) {
      queryParams.set('sensorTarget', filter.sensorTarget);
    }

    if (filter.sensorTypesAnd) {
      queryParams.set('sensorTypesAnd', true.toString());
    }
  }

  return `/api/v1/sites?${queryParams.toString()}`;
};
