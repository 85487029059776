import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  subscribeToIncident as subscribeToIncidentAction,
  unsubscribeFromIncident as unsubscribeFromIncidentAction
} from '../actions/streamApi';
import { ApplicationState } from '../reducers';
import { SubscribedIncidentsByResourceId } from '../reducers/subscribedIncidents';

const useSubscribeToIncidents = (
  equipmentIds: number[] | undefined,
  spaceIds: number[] | undefined
): SubscribedIncidentsByResourceId => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentIds) {
      equipmentIds.forEach((equipmentId) => {
        subscribeToIncident(dispatch, { equipmentId });
      });

      return () => {
        equipmentIds.forEach((equipmentId) => {
          unsubscribeFromIncident(dispatch, { equipmentId });
        });
      };
    }
  }, [dispatch, equipmentIds]);

  useEffect(() => {
    if (spaceIds) {
      spaceIds.forEach((spaceId) => {
        subscribeToIncident(dispatch, { spaceId });
      });

      return () => {
        spaceIds.forEach((spaceId) => {
          unsubscribeFromIncident(dispatch, { spaceId });
        });
      };
    }
  }, [dispatch, spaceIds]);

  return useSelector<ApplicationState, {}>(({ subscribedIncidents }) => {
    return subscribedIncidents.byResourceId;
  });
};

const subscribeToIncident = (
  dispatch,
  resourceIds: {
    equipmentId?: string | number;
    spaceId?: string | number;
  }
) => {
  dispatch(subscribeToIncidentAction(resourceIds));
};

const unsubscribeFromIncident = (
  dispatch,
  resourceIds: {
    equipmentId?: string | number;
    spaceId?: string | number;
  }
) => {
  dispatch(unsubscribeFromIncidentAction(resourceIds));
};

export default useSubscribeToIncidents;
