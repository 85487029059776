import assoc from 'ramda/src/assoc';
import { Actions } from '../actions/notifications';

export interface Notifications {
  isDismissNotificationLoading: boolean;
}

export const initialState = {
  isDismissNotificationLoading: false
};

const notifications = (state: Notifications = initialState, action: any) => {
  switch (action.type) {
    case Actions.DISMISS_NOTIFICATION_LOADING:
      return assoc('isDismissNotificationLoading', true, state);

    case Actions.DISMISS_NOTIFICATION_SUCCESS:
    case Actions.DISMISS_NOTIFICATION_ERROR:
      return assoc('isDismissNotificationLoading', false, state);

    default:
      return state;
  }
};

export default notifications;
