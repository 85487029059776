import { Actions } from '../actions/ui';
import { ApiError } from '@energybox/react-ui-library/dist/types';
import { assocPath, without, reject, omit } from 'ramda';
import { AlertType } from '@energybox/react-ui-library/dist/components';
import { LOCALSTORAGE_KEY } from '../constant';

export interface UIError {
  name: string;
  error: ApiError;
}

export interface Alert {
  type: keyof typeof AlertType;
  message: string;
  timeoutAt?: number;
  startAt?: number;
}

export interface UI {
  action: {
    loading: string[];
    errors: {
      [key in string]: ApiError;
    };
  };
  alert: Alert[];
}

const initialState = {
  action: {
    loading: [],
    errors: {},
  },
  alert: [],
};

const ui = (state: UI = initialState, action: any) => {
  switch (action.type) {
    case Actions.START_ACTION:
      if (state.action.loading.includes(action.name)) return state;
      return assocPath(
        ['action', 'loading'],
        [...state.action.loading, action.name],
        state
      );

    case Actions.STOP_ACTION:
      if (!state.action.loading.includes(action.name)) return state;
      return assocPath(
        ['action', 'loading'],
        without(action.name, state.action.loading),
        state
      );

    case Actions.ERROR_COME:
      return assocPath(['action', 'errors', action.name], action.error, state);
    case Actions.ERROR_CLEAN:
      if (!state.action.errors[action.payload.name]) return state;
      return assocPath(
        ['action', 'errors'],
        omit([action.payload.name], state.action.errors),
        state
      );

    case Actions.INSERT_ALERT: {
      if (
        state.alert.find(
          (a) =>
            a.message === action.payload.message &&
            a.type === action.payload.type
        )
      )
        return state;
      const newAlertList = [...state.alert, action.payload];
      window.localStorage.setItem(
        LOCALSTORAGE_KEY.alert,
        JSON.stringify(newAlertList)
      );
      return assocPath(['alert'], newAlertList, state);
    }

    case Actions.REMOVE_ALERT: {
      const newAlertList = reject(
        (alert: Alert) =>
          alert.message === action.payload?.message && action.payload?.type,
        state.alert
      );
      window.localStorage.setItem(
        LOCALSTORAGE_KEY.alert,
        JSON.stringify(newAlertList)
      );
      return assocPath(['alert'], newAlertList, state);
    }

    case Actions.UPDATE_ALERTS: {
      return assocPath(['alert'], action.alerts, state);
    }

    default:
      return state;
  }
};

export default ui;
