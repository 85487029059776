export const isPresent = (v: string | undefined | null) => !!v && v !== '';

export const isDropdownSelected = (v: number | null | undefined) =>
  !!v && v !== -1;

export const hasWhiteSpace = (v) => /\s/.test(v);

export const isNumber = (value: number) => {
  return typeof value === 'number' && !Number.isNaN(value);
};

export const isPositiveIntegerOrZero = (value: number) => {
  return isNumber(value) && Number.isInteger(value) && value >= 0;
};

export const isValidPassword = (p: string) =>
  isPresent(p) && !hasWhiteSpace(p) && p.length >= 6;

export const isEmail = (email: string) => {
  // eslint-disable-next-line no-useless-escape
  const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return reg.test((email || '').toLowerCase());
};
