import React from 'react';
// import { withVariants } from '../util';

const Humidity = (
  <svg
    width="25"
    height="21"
    viewBox="0 0 25 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_82_3787)">
      <path
        d="M22.0318 14.2273C22.1905 14.2273 22.3492 14.3299 22.4048 14.5036C22.5476 14.9221 22.6191 15.3563 22.6191 15.7985C22.6191 16.2406 22.5476 16.6748 22.4048 17.0933C22.3333 17.3144 22.1032 17.4249 21.8968 17.3459C21.6905 17.267 21.5873 17.0222 21.6587 16.8091C21.7698 16.4854 21.8254 16.1459 21.8254 15.8064C21.8254 15.4669 21.7698 15.1274 21.6587 14.8037C21.5873 14.5826 21.6905 14.3457 21.8968 14.2668C21.9445 14.251 21.9841 14.2431 22.0318 14.2431V14.2273Z"
        fill="#45555F"
      />
      <path
        d="M24.2699 13.4299C24.4286 13.4299 24.5873 13.5326 24.6429 13.6984C24.881 14.3774 25 15.0801 25 15.7985C25 16.517 24.881 17.2197 24.6429 17.8987C24.5714 18.1119 24.3413 18.2224 24.1349 18.1434C23.9286 18.0645 23.8254 17.8355 23.8968 17.6223C24.1032 17.0381 24.2064 16.4223 24.2064 15.8064C24.2064 15.1906 24.1032 14.5748 23.8968 13.9905C23.8254 13.7773 23.9286 13.5405 24.1349 13.4694C24.1825 13.4536 24.2222 13.4457 24.2699 13.4457V13.4299Z"
        fill="#45555F"
      />
      <path
        d="M8.99207 7.46115C8.23016 7.46115 7.51588 7.14534 6.98413 6.56109C6.21429 5.73208 4.89683 5.73208 4.12699 6.56109C3.05556 7.7138 1.17461 7.7138 0.103178 6.56109C-0.0476157 6.40318 -0.0317426 6.15053 0.126988 6.00052C0.285718 5.85051 0.539686 5.8663 0.69048 6.02421C1.46032 6.85321 2.77778 6.85321 3.54762 6.02421C4.61905 4.87149 6.5 4.87149 7.57143 6.02421C8.34127 6.85321 9.65873 6.85321 10.4286 6.02421C10.5794 5.8663 10.8254 5.85051 10.9921 6.00052C11.1508 6.15053 11.1667 6.39529 11.0159 6.56109C10.4841 7.13745 9.76985 7.46115 9.00794 7.46115H8.99207Z"
        fill="#45555F"
      />
      <path
        d="M2.11905 10.6665C1.35714 10.6665 0.642859 10.3507 0.111113 9.76641C-0.0396811 9.6085 -0.0238081 9.35585 0.134922 9.20584C0.293652 9.05583 0.547621 9.07162 0.698414 9.22953C1.46826 10.0585 2.78572 10.0585 3.55556 9.22953C4.62699 8.07681 6.50794 8.07681 7.57937 9.22953C8.17461 9.87694 9.05556 10.0506 9.8254 9.68746C10.0238 9.59271 10.2619 9.67167 10.3571 9.86905C10.4524 10.0664 10.373 10.3033 10.1746 10.398C9.10318 10.9112 7.8254 10.6586 7 9.76641C6.23016 8.9374 4.9127 8.9374 4.14286 9.76641C3.61111 10.3428 2.89683 10.6665 2.13492 10.6665H2.11905Z"
        fill="#45555F"
      />
      <path
        d="M8.99207 13.8247C8.23016 13.8247 7.51588 13.5089 6.98413 12.9246C6.21429 12.0956 4.89683 12.0956 4.12699 12.9246C3.05556 14.0773 1.17461 14.0773 0.103178 12.9246C-0.0476157 12.7667 -0.0317426 12.5141 0.126988 12.364C0.285718 12.214 0.539686 12.2298 0.69048 12.3877C1.46032 13.2167 2.77778 13.2167 3.54762 12.3877C4.61905 11.235 6.5 11.235 7.57143 12.3877C7.95238 12.7983 8.46032 13.0273 9 13.0273C9.22223 13.0273 9.39683 13.2009 9.39683 13.422C9.39683 13.6431 9.22223 13.8168 9 13.8168L8.99207 13.8247Z"
        fill="#45555F"
      />
      <path
        d="M15.0794 1.53959C17.254 7.36633 19.4444 13.8168 19.4444 15.4748C19.4444 17.8276 17.4841 19.7383 15.0794 19.7383C12.6746 19.7383 10.7143 17.8276 10.7143 15.4748C10.7143 13.8089 12.9048 7.36633 15.0794 1.53959ZM15.0794 0C14.9286 0 14.7778 0.0868485 14.7143 0.25265C13.5397 3.3634 9.92062 13.1378 9.92062 15.4827C9.92062 18.2777 12.2301 20.5357 15.0794 20.5357C17.9286 20.5357 20.2381 18.2698 20.2381 15.4827C20.2381 13.1457 16.619 3.3634 15.4444 0.25265C15.3809 0.0868485 15.2301 0 15.0794 0V0Z"
        fill="#45555F"
      />
      <path
        d="M17.0635 17.7408C16.9603 17.7408 16.8651 17.7013 16.7857 17.6224C16.627 17.4724 16.627 17.2197 16.7857 17.0618C17.2222 16.6276 17.4603 16.0433 17.4603 15.4354C17.4603 15.2933 17.4206 14.9775 17.254 14.3064C17.1984 14.0932 17.3254 13.88 17.5397 13.8247C17.754 13.7695 17.9682 13.8958 18.0238 14.109C18.1825 14.7169 18.254 15.1512 18.254 15.4275C18.254 16.2486 17.9286 17.0223 17.3413 17.6145C17.2619 17.6934 17.1587 17.7329 17.0635 17.7329V17.7408Z"
        fill="#45555F"
      />
    </g>
    <defs>
      <clipPath id="clip0_82_3787">
        <rect width="25" height="20.5357" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

// export const variants = {
//   medium: Medium,
//   default: Medium,
// };

export default Humidity;
