import { UsersApiFilter } from '@energybox/react-ui-library/dist/types';
import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../actions/users';
import { ApplicationState } from '../reducers';
import { UsersById } from '../reducers/users';
import { useCurrentOrgId } from './useCurrentUser';

export function usersById(): UsersById {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const usersById = useSelector<ApplicationState, UsersById>(
    ({ users }) => users.usersById
  );

  return usersById;
}

export const useGetUsers = (
  filter?: UsersApiFilter
): { usersById: UsersById; fetchUsers: () => void } => {
  const dispatch = useDispatch();
  const orgId = useCurrentOrgId();

  const fetchUsers = useCallback(() => {
    dispatch(
      getUsers({
        siteIds: orgId ? [orgId] : undefined,
        ...filter,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, orgId]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const usersById = useSelector<ApplicationState, UsersById>(
    ({ users }) => users.usersById
  );

  return {
    usersById,
    fetchUsers,
  };
};
